<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div v-if="Object.keys(currImages).length > 0" class="image-item">
        <div class="image-upload-item" v-for="imgId in Object.keys(currImages)" :key="imgId">
          <img class="image-show" alt="household image" :src="getImageUrlById(imgId)">
          <div class="image-panel">
            <div class="image-panel-desc">
              <v-text-field
                v-model="currImages[imgId].description"
                @update:modelValue="storeImages(imgId)"
                @update:focused="(val) => {if (!val) storeImages(imgId)}"
                label="คำอธิบาย"
                color="primary"
                density="compact"
                variant="outlined"
              />
            </div>
            <div class="image-panel-del">
              <v-btn variant="text" icon="mdi-delete" @click="deleteImage(imgId)"></v-btn>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="image-item">
        <v-icon size="4rem">mdi-image</v-icon>
        <p>ไม่มีรูปภาพ</p>
      </div>
      <div class="image-item">
        <input id="fileUpload" type="file" hidden accept="image/*" @change="uploadImage" ref="chooseImageFile">
        <v-btn variant="elevated" color="primary" prepend-icon="mdi-upload" @click="chooseImage">อัพโหลดรูปภาพ{{ Object.keys(currImages).length > 0 ? 'เพิ่มเติม' : '' }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { ImageRecord, getDefaultDataState, getLocalObjectStateWithDataState, randHex } from '@/models/household'
import { toBase64, getImageUrl, downsizeImage } from '@/utils/file-utils'

export default defineComponent({
  name: 'ChooseImageForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'images'],
  components: {
  },
  setup () {
    const store = useStore()
    return {
      getImageUrl,
      store
    }
  },
  data () {
    const currImages: { [key: string]: ImageRecord } = this.images ?? {}
    console.log('currImages', currImages)
    return {
      currImages
    }
  },
  watch: {
    images: {
      immediate: true,
      handler (d) {
        this.currImages = d ?? {}
        this.storeImages()
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.storeImages })
    this.storeImages()
  },
  methods: {
    storeImages (descId?: string) {
      if (descId) {
        this.currImages[descId].objectState.dataState = getDefaultDataState()
      }
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'images',
        value: this.currImages
      })
    },
    async uploadImage (event: any) {
      const file = event.target.files[0]
      if (file) {
        this.currImages[randHex(32)] = {
          image: await downsizeImage(file),
          objectState: getLocalObjectStateWithDataState(),
          filename: file.name,
          extension: file.name.split('.').pop()
        }
        this.storeImages()
      }
    },
    deleteImage (imgId: string) {
      console.log(imgId)
      delete this.currImages[imgId]
      this.storeImages()
    },
    getImageUrlById (imgId: string) {
      return getImageUrl(this.currImages[imgId].image)
    },
    chooseImage () {
      document.getElementById('fileUpload')?.click()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

.new-household-form {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-item {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem
}

.new-household-form-wrapper {
  width: 48rem;
  @media only screen and (max-width: variables.$screen-md) {
    width: 32rem;
  }
  @media only screen and (max-width: variables.$screen-xs) {
    width: 24rem;
  }
}

.image-upload-item {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: variables.$screen-md) {
    flex-direction: column;
  }
}

.image-show {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  aspect-ratio: 4 / 3;
  object-fit: contain;
  width: 16rem;
  @media only screen and (max-width: variables.$screen-md) {
    width: 12rem;
  }
  @media only screen and (max-width: variables.$screen-xs) {
    width: 8rem;
  }
}

.image-panel {
  flex: 1;
  padding: 1rem;
  display: flex;
  width: 100%;
}

.image-panel-desc {
  flex: 1;
  padding-top: 1rem;
}

.image-panel-del {
  padding-top: 0.9rem;
}

</style>
