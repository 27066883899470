<template>
  <div class="new-household-form-container">
    <div class="popup-head">
      <h3>เปลี่ยนความสัมพันธ์ในครัวเรือน</h3>
      <div class="popup-head-close-btn-box">
        <v-btn variant="text" icon="mdi-close" size="1.5rem" @click="closeWindow(false)"></v-btn>
      </div>
    </div>

    <v-divider />
    <div :key="loading ? 1 : 0" class="new-household-form-wrapper">
      <div class="flex-replace new-household-form">
        <div v-for="(member, i) in members" :key="member.personalInfo.id" class="new-household-form-item-full">
          <div class="member-name">
            <p>
              {{ member.personalInfo.prefix }}{{ member.personalInfo.name }} {{ member.personalInfo.surname }}
            </p>
          </div>
          <div class="member-role-select">
            <v-select
              :key="selectKeys[i]"
              :model-value="roles[i]"
              :error-messages="rolesErr[i]"
              @update:modelValue="(val) => updateRole(val, i)"
              @update:focused="(val) => {if (!val) roleVal(i)}"
              color="primary"
              density="compact"
              variant="outlined"
              :placeholder="`เลือกความสัมพันธ์`"
              :no-data-text="`ไม่พบความสัมพันธ์`"
              :items="roleList"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="new-household-form-buttons">
      <v-btn variant="outlined" color="success" @click="submitOffline">
        บันทึกแบบร่าง offline
      </v-btn>
    </div>
    <v-dialog
      v-model="valErrorsPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้
        </v-card-title>
        <div class="errors-report">
          <div v-for="(err, i) in rolesErr" :key="i">
            <v-card-text v-if="err">
              <p>
                <span>
                  {{ members![i].personalInfo.prefix }}{{ members![i].personalInfo.name }} {{ members![i].personalInfo.surname }}
                </span>
                : <span class="err-msg">{{ err }}</span>
              </p>
            </v-card-text>
          </div>
        </div>
        <v-card-actions>
          <v-btn color="primary" block @click="valErrorsPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noHeadErrorsPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          จำเป็นต้องมีหัวหน้าครัวเรือน
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" block @click="noHeadErrorsPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { HouseholdMember, HouseholdMemberRole, getHousehold, Household, saveHousehold } from '@/models/household'
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChangeMembersRole',
  props: ['closeWindow', '_id'],
  components: {
  },
  setup () {
    return {
    }
  },
  data () {
    return {
      household: undefined as Household | undefined,
      roleList: Object.values(HouseholdMemberRole) as string[],
      loading: true,
      membersIds: undefined as string[] | undefined,
      members: undefined as HouseholdMember[] | undefined,
      roles: [] as (string | undefined)[],
      rolesErr: [] as (string | undefined)[],
      selectKeys: [] as number[],
      valErrorsPopup: false,
      noHeadErrorsPopup: false
    }
  },
  mounted () {
    this.fetchMembers()
  },
  methods: {
    async fetchMembers () {
      this.loading = true
      const household = await getHousehold(this._id)
      if (household) {
        this.household = household
        const members = []
        const memberIds = []
        for (const [k, m] of Object.entries(household.members)) {
          memberIds.push(k)
          members.push(m)
        }
        this.members = members
        this.membersIds = memberIds
        this.roles = this.members.map(e => e.personalInfo.role)
        this.rolesErr = this.members.map(() => undefined)
        this.selectKeys = this.members.map(() => Math.random())
        console.log('this.members', this.members)
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async submitOffline () {
      for (const err of this.rolesErr) {
        if (err) {
          this.valErrorsPopup = true
          return
        }
      }
      if (!this.roles.reduce((cum, e) => (cum || e === HouseholdMemberRole.HEAD), false)) {
        this.noHeadErrorsPopup = true
        return
      }
      if (this.household?.members) {
        this.roles.forEach((role, i) => {
          if (role && this.household && this.membersIds) {
            this.household.members[this.membersIds[i]].personalInfo.role = role as HouseholdMemberRole
          }
        })
        if (await saveHousehold(this.household)) {
          this.closeWindow(true)
        }
      } else {
        this.closeWindow(true)
      }
    },
    roleVal (idx: number) {
      this.rolesErr[idx] = validateEmpty(this.roles[idx], undefined)
    },
    updateRole (val: HouseholdMemberRole, idx: number) {
      if (val === HouseholdMemberRole.HEAD) {
        this.roles.forEach((r, i) => {
          if (r === HouseholdMemberRole.HEAD) {
            this.roles[i] = undefined
            this.roleVal(i)
          }
        })
      }
      this.roles[idx] = val
      this.roleVal(idx)
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/popupForms';
@use '@/styles/Form/normalForm';

.flex-replace {
  display: flex !important;
  flex-direction: column;
  gap: 0.25rem;
  @media only screen and (max-width: variables.$screen-sm) {
    gap: 0.5rem;
  }
}

.new-household-form-item-full {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  @media only screen and (max-width: variables.$screen-sm) {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
}

.member-name {
  flex: 1;
  text-align: end;
  height: 100%;
  padding-bottom: 1.28rem;
}

.member-role-select {
  flex: 1;
  max-width: 16rem;
  @media only screen and (max-width: variables.$screen-md) {
    max-width: 12rem;
  }
  @media only screen and (max-width: variables.$screen-sm) {
    max-width: unset;
    width: 100%;
  }
}

</style>
