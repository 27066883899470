<template>
  <div class="location-form-wrapper">
    <div class="location-form">
      <div class="panel-container">
        <p class="input-title">พิกัดบ้าน</p>
        <div class="input-group">
          <v-text-field
            ref="latRef"
            :key="latKey"
            :model-value="lat"
            @update:model-value="latInputChanged"
            density="compact"
            variant="outlined"
            color="primary"
            label="ละติจูด"
          />
        </div>
        <div class="input-group">
          <v-text-field
            ref="lngRef"
            :key="lngKey"
            :model-value="lng"
            @update:model-value="lngInputChanged"
            density="compact"
            variant="outlined"
            color="primary"
            label="ลองจิจูด"
          />
        </div>
        <div class="input-button">
          <v-btn variant="elevated" :disabled="!hasChanged || isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))" color="primary" @click="updatePosition">
            ปักหมุด
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LocationForm',
  components: {

  },
  props: ['closeWindow', 'tab', 'tabName', '_id', 'initPosition'],
  setup () {
    const store = useStore()
    return {
      store
    }
  },
  mounted () {
    console.log('initPosition', this.$props.initPosition)
    this.storePosition(this.initPosition?.lat, this.initPosition?.lng)
  },
  data () {
    return {
      hasChanged: false,
      latKey: Math.random(),
      lngKey: Math.random(),
      lat: this.$props.initPosition ? this.$props.initPosition[0] : '',
      lng: this.$props.initPosition ? this.$props.initPosition[1] : '',
      zoom: 5,
      mapLayers: [
        {
          url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          name: 'Google Map (Street)',
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        },
        {
          url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          name: 'Google Map (Satellite)',
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        },
        {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          name: 'Carto',
          subdomains: undefined
        },
        {
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          name: 'World Imagery',
          subdomains: undefined
        }
      ]
    }
  },
  watch: {
    initPosition: {
      immediate: true,
      handler (p) {
        console.log('p', p)
        if (p) {
          this.lat = p[0]
          this.lng = p[1]
          this.updatePosition()
          this.latKey = Math.random()
          this.lngKey = Math.random()
        }
      }
    }
  },
  methods: {
    valNum (input: string | number) {
      return /^-{0,1}\d*\.{0,1}\d*$/.test(input.toString())
    },
    latInputChanged (val: string) {
      if (!this.valNum(val)) {
        this.latKey = Math.random()
        this.$nextTick(() => {
          const ref = this.$refs.latRef as any
          ref.focus()
        })
        return
      }
      this.lat = val
      this.hasChanged = true
    },
    lngInputChanged (val: string) {
      if (!this.valNum(val)) {
        this.lngKey = Math.random()
        this.$nextTick(() => {
          const ref = this.$refs.lngRef as any
          ref.focus()
        })
        return
      }
      this.lng = val
      this.hasChanged = true
    },
    storePosition (lat: number | undefined = undefined, lng: number | undefined = undefined) {
      console.log('storePosition', lat, lng)
      if (lat !== undefined && lng !== undefined) {
        if (lat < -90) {
          lat = -90.0
        } else if (lat > 90) {
          lat = 90.0
        }
        lng = ((lng + 180) % 360) - 180
        if (lng < -180) {
          lng = 360 + lng
        }

        if (lat < 5.5) {
          lat = 5.5
        } else if (lat > 20.5) {
          lat = 20.5
        }
        if (lng < 97.3) {
          lng = 97.3
        } else if (lng > 105.7) {
          lng = 105.7
        }

        this.store.dispatch('setFormValue', {
          id: this._id,
          mode: 'value',
          tab: this.tab,
          key: 'position',
          value: [lat, lng]
        })
        this.lat = lat
        this.lng = lng
        this.latKey = Math.random()
        this.lngKey = Math.random()
      } else {
        this.store.dispatch('setFormValue', {
          id: this._id,
          mode: 'value',
          tab: this.tab,
          key: 'position',
          value: undefined
        })
      }
    },
    updatePosition () {
      const [lat, lng] = [parseFloat(this.lat), parseFloat(this.lng)]
      this.storePosition(lat, lng)
      this.hasChanged = false
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.input-title, .input-button {
  font-weight: bold;
  margin-bottom: 22px;
}

.input-group {
  width: 12rem;
}

.location-form-wrapper {
  padding: 1rem;
}

.panel-container {
  grid: 1;
  grid-column: span 12;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  @media only screen and (max-width: variables.$screen-sm) {
    gap: 0.5rem;
    .input-title {
      display: none;
    }
  }
}

.location-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 1rem;
  border: 1px solid rgb(224, 224, 224);
  --v-medium-emphasis-opacity: 1 !important;
  @media only screen and (max-width: variables.$screen-md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

</style>
