<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.houseOwnStatus }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-radio-group
          v-model="houseOwnStatus"
          :error-messages="houseOwnStatusErr"
          @update:modelValue="houseOwnStatusVal()"
          color="primary"
          inline
        >
          <v-radio
            v-for="item in houseOwnStatusList"
            :key="item"
            :label="item"
            :value="item"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.residentType }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-radio-group
          color="primary"
          v-model="residentType"
          :error-messages="residentTypeErr"
          @update:modelValue="residentTypeVal()"
        >
          <v-radio
            v-for="item in residentTypeList"
            :key="item"
            :label="item"
            :value="item"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.houseNum }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          v-model="houseNum"
          :error-messages="houseNumErr"
          @update:modelValue="houseNumVal()"
          @update:focused="(val) => {if (!val) houseNumVal()}"
          :placeholder="titleMap.houseNum"
          color="primary"
          density="compact"
          variant="outlined"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.province }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-autocomplete
          v-model="province"
          :error-messages="provinceErr"
          @update:modelValue="provinceChanged()"
          @update:focused="(val) => {if (!val) provinceVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.province}`"
          :no-data-text="`ไม่พบ${titleMap.province}`"
          item-title="name"
          item-value="id"
          :items="provinceList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.district }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-autocomplete
          v-model="district"
          :error-messages="districtErr"
          @update:modelValue="districtChanged()"
          @update:focused="(val) => {if (!val) districtVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.district}`"
          :no-data-text="`ไม่พบ${titleMap.district}`"
          item-title="name"
          item-value="id"
          :items="districtList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.subdistrict }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-autocomplete
          v-model="subdistrict"
          :error-messages="subdistrictErr"
          @update:modelValue="subdistrictChanged()"
          @update:focused="(val) => {if (!val) subdistrictVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.subdistrict}`"
          :no-data-text="`ไม่พบ${titleMap.subdistrict}`"
          item-title="name"
          item-value="id"
          :items="subdistrictList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.village }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-autocomplete
          v-model="village"
          :error-messages="villageErr"
          @update:modelValue="villageVal()"
          @update:focused="(val) => {if (!val) villageVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.village}`"
          :no-data-text="`ไม่พบ${titleMap.village}`"
          item-title="name"
          item-value="id"
          :items="villageList"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getAddressVillage, getDistrictList, getProvinceList, getSubdistrictList, getVillageList } from '@/models/address'
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'HouseholdForm',
  props: ['closeWindow', 'selectedPosition', 'tab', 'tabName', '_id', 'initialValue'],
  setup () {
    const store = useStore()
    return {
      store,
      houseOwnStatusList: ['มี', 'ไม่มี'],
      residentTypeList: ['ที่ดินของตนเอง', 'เช่า', 'ที่สาธารณะ', 'อื่นๆ']
    }
  },
  data () {
    const provinceList = getProvinceList()
    console.log('provinceList', provinceList)
    const province0: number | string | undefined = provinceList[0].id
    let district0: number | string | undefined, subdistrict0: number | string | undefined, village0: number | string | undefined
    if (this.initialValue?.villageId) {
      const villageAddress = getAddressVillage(parseInt(this.initialValue.villageId))
      district0 = villageAddress.id.district
      subdistrict0 = villageAddress.id.subdistrict
      village0 = villageAddress.id.village
    }

    let districtList = [] as {id: string | number, name: string}[]
    let subdistrictList = [] as {id: string | number, name: string}[]
    let villageList = [] as {id: string | number, name: string}[]
    if (province0) {
      districtList = getDistrictList()
      if (district0) {
        subdistrictList = getSubdistrictList(parseInt(district0.toString()))
        if (subdistrict0) {
          villageList = getVillageList(parseInt(district0.toString()), parseInt(subdistrict0.toString()))
          if (this.village) {
            this.villageVal()
          }
        }
      }
    }

    return {
      houseOwnStatus: ref<string | undefined>(this.initialValue ? this.initialValue.houseOwnStatus : undefined),
      houseOwnStatusErr: ref<string | undefined>(),
      residentType: ref<string | undefined>(this.initialValue ? this.initialValue.residentType : undefined),
      residentTypeErr: ref<string | undefined>(),
      houseNum: ref<string | undefined>(this.initialValue ? this.initialValue.houseNum : undefined),
      houseNumErr: ref<string | undefined>(),
      province: ref<number | string>(province0 ?? provinceList[0].id),
      provinceErr: ref<string | undefined>(),
      district: ref<number | string | undefined>(district0),
      districtErr: ref<string | undefined>(),
      subdistrict: ref<number | string | undefined>(subdistrict0),
      subdistrictErr: ref<string | undefined>(),
      village: ref<number | string | undefined>(village0),
      villageErr: ref<string | undefined>(),
      titleMap: {
        houseOwnStatus: 'สถานะการมีบ้านเลขที่',
        residentType: 'ข้อมูลที่พักอาศัย',
        houseNum: 'บ้านเลขที่',
        province: 'จังหวัด',
        district: 'อำเภอ',
        subdistrict: 'ตำบล',
        village: 'หมู่บ้าน'
      },
      provinceList,
      districtList,
      subdistrictList,
      villageList
    }
  },
  mounted () {
    if (this.province) {
      this.provinceVal()
      if (this.district) {
        this.districtVal()
        if (this.subdistrict) {
          this.subdistrictVal()
          if (this.village) {
            this.villageVal()
          }
        }
      }
    }
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
  },
  methods: {
    houseOwnStatusVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'houseOwnStatus',
        value: this.houseOwnStatus
      })
      this.houseOwnStatusErr = validateEmpty(this.houseOwnStatus, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'houseOwnStatus',
        value: { tab: this.tabName, title: this.titleMap.houseOwnStatus, error: this.houseOwnStatusErr }
      })
    },
    residentTypeVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'residentType',
        value: this.residentType
      })
      this.residentTypeErr = validateEmpty(this.residentType, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'residentType',
        value: { tab: this.tabName, title: this.titleMap.residentType, error: this.residentTypeErr }
      })
    },
    houseNumVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'houseNum',
        value: this.houseNum
      })
      this.houseNumErr = validateEmpty(this.houseNum, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'houseNum',
        value: { tab: this.tabName, title: this.titleMap.houseNum, error: this.houseNumErr }
      })
    },
    provinceVal () {
      this.provinceErr = validateEmpty(this.province, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'province',
        value: { tab: this.tabName, title: this.titleMap.province, error: this.provinceErr }
      })
    },
    districtVal (err = true) {
      if (err) {
        this.districtErr = validateEmpty(this.district, undefined)
      } else {
        this.districtErr = undefined
      }
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'district',
        value: { tab: this.tabName, title: this.titleMap.district, error: this.districtErr }
      })
    },
    subdistrictVal (err = true) {
      if (err) {
        this.subdistrictErr = validateEmpty(this.subdistrict, undefined)
      } else {
        this.subdistrictErr = undefined
      }
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'subdistrict',
        value: { tab: this.tabName, title: this.titleMap.subdistrict, error: this.subdistrictErr }
      })
    },
    villageVal (err = true) {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'villageId',
        value: this.village
      })
      if (err) {
        this.villageErr = validateEmpty(this.village, undefined)
      } else {
        this.villageErr = undefined
      }
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'village',
        value: { tab: this.tabName, title: this.titleMap.village, error: this.villageErr }
      })
    },
    validate () {
      this.houseOwnStatusVal()
      this.residentTypeVal()
      this.houseNumVal()
      this.provinceVal()
      this.districtVal()
      this.subdistrictVal()
      this.villageVal()
    },
    provinceChanged () {
      this.provinceVal()
      this.districtList = getDistrictList()
      this.subdistrictList = []
      this.villageList = []
      this.districtVal(false)
      this.district = undefined
      this.subdistrictVal(false)
      this.subdistrict = undefined
      this.villageVal(false)
      this.village = undefined
    },
    districtChanged () {
      this.districtVal()
      if (this.district) {
        this.subdistrictList = getSubdistrictList(parseInt(this.district.toString()))
      } else {
        this.subdistrictList = []
      }
      this.villageList = []
      this.subdistrictVal(false)
      this.subdistrict = undefined
      this.villageVal(false)
      this.village = undefined
    },
    subdistrictChanged () {
      this.subdistrictVal()
      if (this.district && this.subdistrict) {
        this.villageList = getVillageList(parseInt(this.district.toString()), parseInt(this.subdistrict.toString()))
      } else {
        this.villageList = []
      }
      this.villageVal(false)
      this.village = undefined
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/normalForm';

</style>
