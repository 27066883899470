<template>
  <v-dialog
    v-model="storageFullErrorPopup"
    width="auto"
  >
    <v-card>
      <v-card-title>
        ไม่สามารถบันทึกได้
      </v-card-title>
      <v-card-text>
        ไม่สามารถบันทึกได้เนื่องจาก storage เต็ม
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="storageFullErrorPopup = false">ปิด</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setRaiseStorageFullError } from '@/models/household'

export default defineComponent({
  name: 'ErrorPopup',
  props: ['closeWindow', 'id'],
  data () {
    return {
      storageFullErrorPopup: false
    }
  },
  mounted () {
    setRaiseStorageFullError(this.raiseStorageFullError)
  },
  methods: {
    raiseStorageFullError () {
      this.storageFullErrorPopup = true
    }
  }
})
</script>
