import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-284547d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-panel panel-desktop" }
const _hoisted_2 = { class: "member-panel panel-mobile" }
const _hoisted_3 = {
  key: 0,
  class: "member-list"
}
const _hoisted_4 = {
  key: 0,
  class: "list-no-card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_MemberCard = _resolveComponent("MemberCard")!
  const _component_MemberDetail = _resolveComponent("MemberDetail")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_ChangeMembersRole = _resolveComponent("ChangeMembersRole")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("div", {
      key: _ctx.loading ? 1 : 0,
      class: "card-body"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, "มีสมาชิกทั้งหมด " + _toDisplayString(_ctx.household?.members ? Object.keys(_ctx.household.members).length : 0) + " คน  ", 1),
        _createVNode(_component_v_btn, {
          variant: "elevated",
          color: "primary",
          "prepend-icon": "mdi-account-edit",
          onClick: _ctx.openEditMembersRole
        }, {
          default: _withCtx(() => [
            _createTextVNode("เปลี่ยนความสัมพันธ์ในครัวเรือน")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_v_btn, {
          variant: "elevated",
          color: "primary",
          "prepend-icon": "mdi-account-plus",
          onClick: _ctx.openNewMember
        }, {
          default: _withCtx(() => [
            _createTextVNode("เพิ่มสมาชิก")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, "มีสมาชิกทั้งหมด " + _toDisplayString(_ctx.household?.members ? Object.keys(_ctx.household.members).length : 0) + " คน  ", 1),
        _createVNode(_component_v_btn, {
          variant: "text",
          color: "primary",
          icon: "mdi-account-edit",
          density: "comfortable",
          onClick: _ctx.openEditMembersRole
        }, null, 8, ["onClick"]),
        _createVNode(_component_v_btn, {
          variant: "text",
          color: "primary",
          icon: "mdi-account-plus",
          density: "comfortable",
          onClick: _ctx.openNewMember
        }, null, 8, ["onClick"])
      ]),
      (_ctx.household)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.household?.members).sort(([mid, member]) => (member.personalInfo.role === _ctx.HouseholdMemberRole.HEAD ? -1 : 0)), ([mid, member]) => {
              return (_openBlock(), _createBlock(_component_MemberCard, {
                key: mid,
                _id: _ctx.id,
                mid: mid,
                member: member,
                "update-callback": () => _ctx.getHousehold(true)
              }, null, 8, ["_id", "mid", "member", "update-callback"]))
            }), 128)),
            (Object.keys(_ctx.household?.members).length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, " ไม่มีสมาชิกครัวเรือน "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])),
    _createVNode(_component_v_overlay, {
      class: "add-form",
      modelValue: _ctx.newMember,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMember) = $event)),
      persistent: true,
      "no-click-animation": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MemberDetail, {
          _id: _ctx.id,
          closeWindow: _ctx.closeNewMember
        }, null, 8, ["_id", "closeWindow"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_overlay, {
      class: "add-form",
      modelValue: _ctx.editMembersRole,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editMembersRole) = $event)),
      persistent: true,
      "no-click-animation": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ChangeMembersRole, {
          _id: _ctx.id,
          closeWindow: _ctx.closeEditMembersRole
        }, null, 8, ["_id", "closeWindow"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}