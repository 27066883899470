import { ApiUtils } from '@/utils/api-utils'
import { Household } from '@/models/household'
import { getAddHouseholdBodyAPI, HouseholdRaw } from './household'
import { getAddMemberBodyAPI } from './member'
import { getAddProblemBodyApi } from './problem'
import { getAddActivityBodyAPI } from './activity'
import { dataURItoBlob } from '@/utils/file-utils'

function json2formData (json: { [key: string]: Blob | string | number | undefined | string[] | number[] | { [key: string]: string | number | undefined }[] }) {
  const formData = new FormData()
  for (const [k, v] of Object.entries(json)) {
    if (Array.isArray(v)) {
      formData.append(k, JSON.stringify(v))
    } else if (v === undefined) {
      formData.append(k, '')
    } else if (v instanceof Blob) {
      formData.append(k, v)
    } else {
      formData.append(k, v.toString())
    }
  }
  return formData
}

interface MemberRaw {
  _id: string
}

const householdApi = new ApiUtils(process.env.VUE_APP_HOUSEHOLD_API_URL)
const addProblemApi = new ApiUtils(process.env.VUE_APP_ADD_PROBLEM_API_URL)
const addActivityApi = new ApiUtils(process.env.VUE_APP_ADD_ACTIVITY_API_URL)
const minioApi = new ApiUtils(process.env.VUE_APP_MINIO_API_URL)

async function isHouseholdExists (household: Household): Promise<boolean> {
  const householdIds: { _id: string }[] = await (
    await householdApi.fetch(
      '/housesurvey/checkhouseduplicate',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          village_ID: household.address.villageId,
          address_num: household.address.houseNum
        })
      }
    )
  ).json()
  return householdIds.length > 0
}

async function getMemberById (memberId: string): Promise<MemberRaw | undefined> {
  const memberRaws: MemberRaw[] = await (
    await householdApi.fetch(
      `/housemember/member/${memberId}`
    )
  ).json()
  return memberRaws.length > 0 ? memberRaws[0] : undefined
}

export async function getHouseholdById (householdId: string) {
  const householdRaws: HouseholdRaw[] = await (
    await householdApi.fetch(
      `/housesurvey/house/${householdId}`
    )
  ).json()
  return householdRaws.length > 0 ? householdRaws[0] : undefined
}

export async function validateNewHousehold (household: Household) {
  const err = []
  if (await isHouseholdExists(household)) {
    err.push('มีครัวเรือนนี้อยู่ในระบบแล้ว')
  }
  for (const member of Object.values(household.members)) {
    if (await getMemberById(member.personalInfo.id)) {
      err.push(`มีสมาชิกหมายเลขบัตรประชาชน ${member.personalInfo.id} อยู่ในระบบแล้ว`)
    }
  }
  if (err.length > 0) {
    return err
  }
}

export async function submitHousehold (household: Household) {
  const body = getAddHouseholdBodyAPI(household)
  console.log('body', body)

  return householdApi.fetch(
    '/housesurvey/addhouse',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  )
}

export async function submitMembers (_id: string, household: Household) {
  const members = Object.values(household.members).map((member) => getAddMemberBodyAPI(_id, household, member, false))
  console.log('members', members)

  return Promise.all(members.map(async (member) => {
    return householdApi.fetch(
      '/housemember/addhousemember',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(member)
      }
    )
  }))
}

export async function submitProblems (_id: string, household: Household, imgFolder: string): Promise<[(Response[] | undefined)[], Response[], { [_id: string]: string }] | undefined> {
  if (!household.problems) return
  const tempProblems = Object.entries(household.problems)
  const problems = tempProblems.map(([key, problem]) => getAddProblemBodyApi(_id, household, problem, imgFolder))
  console.log('problems', problems)

  const imgRes = await Promise.all(tempProblems.map(async ([key, problem]) => {
    if (problem.images) {
      return Promise.all(
        Object.entries(problem.images).map(async ([key, img]) => {
          const filename = `${key}.${img.extension}`
          const imgBlob = dataURItoBlob(img.image)
          console.log('filename', filename, imgFolder)
          return submitImage(imgBlob, `problems/${imgFolder}/`, filename)
        })
      )
    }
  }))

  const problemsRes: Response[] = []
  const idMap: { [_id: string]: string } = {}
  let i = 0
  for (const problem of problems) {
    const tempProblem: { [key: string]: string | number | undefined | string[] | number[] } = {}
    for (const [k, v] of Object.entries(problem)) {
      tempProblem[k] = v
    }
    const formData = json2formData(tempProblem)
    console.log('tempProblem formData', formData)
    const res = await addProblemApi.fetch(
      '',
      {
        method: 'POST',
        body: formData
      }
    )
    problemsRes.push(res)

    if (res.status >= 200 && res.status < 300) {
      const problemsRaw = (await getHouseholdById(_id))?.problems
      if (problemsRaw) {
        const newId = problemsRaw.find(pr => (!idMap[pr._id]))?._id
        if (newId) {
          idMap[newId] = tempProblems[i][0]
          console.log(i, 'newId', newId, tempProblems[i][0])
        }
      }
    }
    i++
  }

  return [imgRes, problemsRes, idMap]
}

export async function submitActivities (_id: string, household: Household, imgFolder: string) {
  if (!household.activities) return
  const tempActivities = Object.values(household.activities)
  const activities = tempActivities.map((activity) => getAddActivityBodyAPI(_id, household, activity, imgFolder))
  console.log('activities', activities)

  return Promise.all([
    Promise.all(tempActivities.map(async (activity) => {
      if (activity.images) {
        return Promise.all(
          Object.entries(activity.images).map(async ([key, img]) => {
            const filename = `${key}.${img.extension}`
            const imgBlob = dataURItoBlob(img.image)
            console.log('filename', filename, imgFolder)
            return submitImage(imgBlob, `problems/${imgFolder}/`, filename)
          })
        )
      }
    })),
    Promise.all(activities.map(async (actviity) => {
      const tempActivity: { [key: string]: string | number | undefined | string[] | number[] } = {}
      for (const [k, v] of Object.entries(actviity)) {
        tempActivity[k] = v
      }
      const formData = json2formData(tempActivity)
      console.log('tempActivity formData', formData)
      return addActivityApi.fetch(
        '',
        {
          method: 'POST',
          body: formData
        }
      )
    }))
  ])
}

export async function submitImage (img: Blob, folder: string, filename: string) {
  const formData = new FormData()
  formData.append('files', img, filename)
  return minioApi.fetch(
    `/buckets/${folder}`,
    {
      method: 'POST',
      body: formData
    }
  )
}

export async function submitHouseholdImages (household: Household, _id: string, imgFolder: string) {
  if (!household.images) return
  const houseImagesBody = Object.entries(household.images).map(([key, img]) => ({
    img_house_url: `/houses/${imgFolder}/${key}.${img.extension}`,
    img_housethumbnail_url: `/houses/${imgFolder}/${key}.${img.extension}`,
    img_house_description: img.description ?? '',
    img_date: (img.objectState.createDate ?? new Date()).toISOString(),
    img_user: household.recordStaff.username
  }))
  return householdApi.fetch(
    '/housesurvey/updatehouseimages',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: _id,
        house_images: houseImagesBody
      })
    }
  )
}
