<template>
  <div class="new-household-form-container popup-width">
    <div class="popup-head">
      <h3>{{ title }}</h3>
      <div class="popup-head-close-btn-box">
        <v-btn variant="text" icon="mdi-close" size="1.5rem" @click="noCallback"></v-btn>
      </div>
    </div>

    <v-divider />

    <div class="paragraph-text">
      {{ paragraph }}
    </div>
    <div class="new-household-form-buttons">
      <v-btn variant="outlined" :color="noColor ?? undefined" @click="noCallback" :disabled="loading">
        {{ noText ?? 'ไม่ใช่' }}
      </v-btn>
      <v-btn variant="elevated" :color="yesColor ?? 'success'" @click="yesCallbackFn" :disabled="loading">
        <div v-if="loading" class="loading">
          <v-progress-circular indeterminate size="20" width="2" />
        </div>
        {{ yesText ?? 'ใช่' }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'YesNoPopup',
  props: ['title', 'paragraph', 'noText', 'yesText', 'noCallback', 'yesCallback', 'noColor', 'yesColor'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async yesCallbackFn () {
      this.loading = true
      await this.yesCallback()
      this.loading = false
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/popupForms';

.loading {
  margin-right: 0.5rem;
}

.popup-width {
  min-width: 24rem !important;
}

.paragraph-text {
  padding: 1rem;
}
</style>
