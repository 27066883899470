<template>
  <div class="user-menu">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn variant="outlined" v-bind="props">
          <template v-if="isUserFetching">
            <v-progress-circular
              size="28"
              width="3"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>
          <template v-else>
            <template v-if="onLine">
              <v-icon icon="mdi-account-circle" size="x-large"></v-icon>
            </template>
            <template v-else>
              <v-icon icon="mdi-wifi-strength-off-outline" size="x-large"></v-icon>
            </template>
            <div class="menu-button-text-container">
              <p v-if="userInfo">
                ตำแหน่ง: {{ userInfo ? userInfo['position'] : null }}
              </p>
              <p v-else>เข้าสู่ระบบ</p>
            </div>
          </template>
          <v-icon icon="mdi-menu-down" size="x-large"></v-icon>
        </v-btn>
      </template>
      <v-list>
        <div v-if="userInfo">
          <div class="list-item">
            <v-list-item-subtitle>
              สถานะ:&nbsp;
              <span v-if="onLine" class="font-weight-bold" color="success">
                ออนไลน์
              </span>
              <span v-else class="font-weight-bold" color="error">
                ออฟไลน์
              </span>
            </v-list-item-subtitle>
          </div>
          <div class="list-item">
            <v-list-item-subtitle>
              ชื่อผู้ใช้: <span class="font-weight-bold">
                {{ userInfo ? userInfo['preferred_username'] : null }}
              </span>
            </v-list-item-subtitle>
          </div>
          <div class="list-item">
            <v-list-item-subtitle>
              ตำแหน่ง: <span class="font-weight-bold">
                {{ userInfo ? userInfo['position'] : null }}
              </span>
            </v-list-item-subtitle>
          </div>
          <v-divider></v-divider>
          <div class="menu-button" @click="logout">
            <div class="list-item">
              <v-icon icon="mdi-logout" size="medium"></v-icon>
              <v-list-item-subtitle class="logout-text">
                <span class="font-weight-bold">
                  ออกจากระบบ
                </span>
              </v-list-item-subtitle>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="menu-button" @click="login">
            <div class="list-item">
              <v-icon icon="mdi-logout" size="medium"></v-icon>
              <v-list-item-subtitle class="logout-text">
                <span class="font-weight-bold">
                  เข้าสู่ระบบ
                </span>
              </v-list-item-subtitle>
            </div>
          </div>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import { _keycloak, getUserInfo, logout, initKeycloak, login } from '@/plugins/keycloak'

export default defineComponent({
  name: 'UserMenu',
  methods: {
    logout () {
      logout()
    },
    login () {
      login()
    }
  },
  setup () {
    const userInfo = ref(_keycloak.tokenParsed)
    const isUserFetching = ref(true)
    const onLine = ref(navigator.onLine)

    async function setUserInfo () {
      const _userInfo = await getUserInfo()
      userInfo.value = _userInfo
      isUserFetching.value = false
      console.log('tokenParsed', _userInfo)
    }

    async function online () {
      console.log('online')
      if (!_keycloak.authenticated) {
        await initKeycloak()
      }
      onLine.value = true
    }

    function offline () {
      console.log('offline')
      onLine.value = false
    }

    onMounted(() => {
      window.addEventListener('online', online)
      window.addEventListener('offline', offline)
    })
    onUnmounted(() => {
      window.removeEventListener('online', online)
      window.removeEventListener('offline', offline)
    })

    setUserInfo()

    return {
      userInfo,
      isUserFetching,
      onLine
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

p {
  font-size: variables.$font-sm;
}

p, .v-icon {
  color: var(--text-color);
}

.menu-button-text-container {
  max-width: 9rem;
  margin-left: 0.36rem;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: variables.$screen-sm) {
    display: none;
  }
}

.mdi-menu-down {
  @media only screen and (max-width: variables.$screen-sm) {
    display: none;
  }
}

.list-item {
  padding: 1rem;
  display: flex;
}
.logout-text {
  margin-left: 0.5rem;
}

.menu-button {
  width: 100%;
}

.menu-button:hover {
  cursor: pointer;
  background-color: #0000000f;
}

.user-menu {
  .v-btn {
    border: 2px solid variables.$main-color;
    border-radius: 10px;
    height: 2.5rem;
  }

}
</style>
