<template>
  <div class="new-household-form-container">
    <div class="popup-head">
      <h3 v-if="mid">แก้ไขสมาชิก</h3>
      <h3 v-else>เพิ่มสมาชิก</h3>
      <div class="popup-head-close-btn-box">
        <v-btn variant="text" icon="mdi-close" size="1.5rem" @click="closeWindow(false)"></v-btn>
      </div>
    </div>

    <v-divider />
    <div class="tab-container">
      <v-tabs
        class="tab-md"
        v-model="tab"
        height="6rem"
        color="primary"
        centered
        stacked
      >
        <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="10rem">
          <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
          <p class="new-household-form-tab-title">
            {{ tabsMap[tab] }}
          </p>
        </v-tab>
      </v-tabs>
      <div class="tab-sm">
        <v-tabs
          v-model="tab"
          height="4rem"
          color="primary"
          centered
          stacked
        >
          <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="5rem">
            <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
          </v-tab>
        </v-tabs>
        <p class="new-household-form-tab-title">
          {{ tabsMap[tab] }}
        </p>
      </div>
    </div>
    <div :key="loading ? 1 : 0">
      <v-window v-model="tab" :touch="{left: () => {}, right: () => {}}">
        <v-window-item :value="tabs[0]">
          <HouseholdMemberForm :_id="tempId" :tab="tabs[0]" :tab-name="tabsMap[tabs[0]]" :full="true" :personal-info="member?.personalInfo" />
        </v-window-item>
        <v-window-item :value="tabs[1]">
          <HouseholdMemberFinancialForm :_id="tempId" :tab="tabs[1]" :tab-name="tabsMap[tabs[1]]" :full="true" :financial="member?.financial" />
        </v-window-item>
        <v-window-item :value="tabs[2]">
          <HouseholdMemberWelfareForm :_id="tempId" :tab="tabs[2]" :tab-name="tabsMap[tabs[2]]" :full="true" :welfare="member?.welfare" />
        </v-window-item>
      </v-window>
    </div>
    <div class="new-household-form-buttons">
      <v-btn v-if="mid" variant="outlined" color="error" @click="deletePopup = true">
        ลบ
      </v-btn>
      <v-btn v-if="tab !== tabs.at(0)" variant="outlined" color="secondary" @click="prevTab">
        กลับ
      </v-btn>
      <v-btn v-if="tab === tabs.at(-1)" variant="outlined" color="success" @click="submitOffline" :loading="loading">
        บันทึกแบบร่าง offline
      </v-btn>
      <v-btn v-else variant="outlined" color="success" @click="nextTab">
        ถัดไป
      </v-btn>
    </div>
    <v-dialog
      v-model="valErrorsPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้
        </v-card-title>
        <div class="errors-report">
          <v-card-text v-for="err in valErrors" :key="err.title">
            <p v-if="err.error">"{{ err.tab }}"/"{{ err.title }}": "<span class="err-msg">{{ err.error }}</span>"</p>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn color="primary" block @click="valErrorsPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="apiErrorPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ apiError?.title }}
        </v-card-title>
        <v-card-text>
          <p>{{ apiError?.message }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="if (apiErrorCallback) {apiErrorCallback()} ; {apiErrorPopup = false}">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay class="add-form"  v-model="deletePopup" :no-click-animation="true">
      <YesNoPopup title="ลบสมาชิก" paragraph="ต้องการที่จะลบสมาชิกนี้หรือไม่" yes-color="error" :no-callback="() => {deletePopup = false}" :yes-callback="deleteOffline"/>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HouseholdMemberFinancialForm from '@/components/Form/HouseholdMemberFinancialForm.vue'
import HouseholdMemberForm from '@/components/Form/HouseholdMemberForm.vue'
import HouseholdMemberWelfareForm from '@/components/Form/HouseholdMemberWelfareForm.vue'
import { useStore } from 'vuex'
import { randHex, saveHousehold, PersonalInfo, HouseholdMember, PersonalFinancial, PersonalWelfare, getHousehold, HouseholdMemberRole, getLocalObjectStateWithDataState, getDefaultDataState, PersonalDisability } from '@/models/household'
import { Form } from '@/store'
import YesNoPopup from '@/components/Popup/YesNoPopup.vue'

export default defineComponent({
  name: 'NewHouseholdForm',
  props: ['closeWindow', '_id', 'mid'],
  components: {
    HouseholdMemberForm,
    HouseholdMemberFinancialForm,
    HouseholdMemberWelfareForm,
    YesNoPopup
  },
  setup () {
    const store = useStore()
    return {
      tempId: randHex(32),
      store
    }
  },
  data () {
    return {
      tab: 'personalInfo',
      valErrorsPopup: false,
      deletePopup: false,
      valErrors: [] as { tab: string, title: string, error: string }[],
      apiErrorPopup: false,
      apiErrorCallback: undefined as undefined | (() => void),
      apiError: undefined as { title: string, message: string } | undefined,
      tabs: ['personalInfo', 'financial', 'welfare'],
      tabsMap: { personalInfo: 'ข้อมูลบุคคล', financial: 'รายได้-หนี้สิน', welfare: 'สวัสดิการ' } as { [key: string]: string },
      tabsIconMap: { personalInfo: 'mdi-book-account-outline', financial: 'mdi-cash', welfare: 'mdi-handshake-outline' } as { [key: string]: string },
      loading: true,
      member: undefined as HouseholdMember | undefined
    }
  },
  beforeMount () {
    this.store.dispatch('resetForm', this.tempId)
  },
  mounted () {
    if (this._id && this.mid) {
      this.fetchMember()
    } else {
      this.loading = false
    }
  },
  unmounted () {
    this.store.dispatch('deleteForm', this.tempId)
  },
  methods: {
    async fetchMember () {
      this.loading = true
      const household = await getHousehold(this._id)
      if (household?.members[this.mid]) {
        this.member = household.members[this.mid]
        console.log('this.member', this.member)
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async submitOffline () {
      if (!this.validate()) return
      let member: HouseholdMember
      const form: Form = this.store.getters.form(this.tempId)
      if (this.member) {
        member = this.member
        member.objectState.dataState = getDefaultDataState()
      } else {
        member = {
          personalInfo: form.value.personalInfo as PersonalInfo,
          objectState: getLocalObjectStateWithDataState()
        }
      }
      if (form.value.personalInfo) {
        const tempPersonalInfo: { [key: string]: any } = form.value.personalInfo
        const { isDisabled, disabilities, disabilityCard, disabilityAllowance } = tempPersonalInfo
        delete tempPersonalInfo.isDisabled
        delete tempPersonalInfo.disabilities
        delete tempPersonalInfo.disabilityCard
        delete tempPersonalInfo.disabilityAllowance
        const disability: PersonalDisability | undefined = isDisabled
          ? {
              disabilities,
              disabilityCard,
              disabilityAllowance: disabilityCard ? parseFloat(disabilityAllowance) : undefined
            }
          : undefined

        member.personalInfo = {
          ...tempPersonalInfo,
          disability
        } as PersonalInfo
      }
      if (form.value.financial) {
        member.financial = form.value.financial as PersonalFinancial
      }
      if (form.value.welfare) {
        member.welfare = form.value.welfare as PersonalWelfare
      }
      const household = await getHousehold(this._id)
      console.log('this.member', this.member)
      if (household) {
        household.members[this.mid ?? this.tempId] = member
        if (await saveHousehold(household)) {
          this.closeWindow(true)
        }
      } else {
        console.log('cannot save', this._id)
        this.closeWindow(true)
      }
    },
    async deleteOffline () {
      this.deletePopup = false
      if (this.member?.personalInfo.role === HouseholdMemberRole.HEAD) {
        this.apiError = { title: 'ไม่สามารถลบได้', message: 'ไม่สามารถลบหัวหน้าครัวเรือนได้ กรุณาเปลี่ยนหัวหน้าครัวเรือนก่อนลบ' }
        this.apiErrorCallback = () => {
          console.log('close!!')
          this.closeWindow(false)
        }
        this.apiErrorPopup = true
      } else {
        const household = await getHousehold(this._id)
        if (household && this.tempId && household.members[this.mid]) {
          delete household.members[this.mid]
          await saveHousehold(household)
        } else {
          console.log('cannot delete', this._id, household, this.mid)
          console.log(household?.members[this.mid])
        }
        this.closeWindow(true)
      }
    },
    validate () {
      const { notCalled } = this.store.getters.formCallback(this.tempId, ['personalInfo', 'financial', 'welfare'])
      const errors = this.store.getters.formErrors(this.tempId)
      if (!this.member) {
        for (const t of notCalled) {
          errors.push({ tab: this.tabsMap[t], title: '*', error: 'จำเป็นต้องกรอก' })
        }
      }
      if (errors.length > 0) {
        this.valErrors = errors
        this.valErrorsPopup = true
        return false
      }
      return true
    },
    nextTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx + 1]
    },
    prevTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx - 1]
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/popupForms';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
