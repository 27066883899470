import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-721fd130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "problem-panel panel-desktop" }
const _hoisted_2 = { class: "problem-panel panel-mobile" }
const _hoisted_3 = {
  key: 0,
  class: "problem-list"
}
const _hoisted_4 = {
  key: 0,
  class: "list-no-card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ActivityCard = _resolveComponent("ActivityCard")!
  const _component_ActivityDetail = _resolveComponent("ActivityDetail")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("div", {
      key: _ctx.loading ? 1 : 0,
      class: "card-body"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          variant: "elevated",
          color: "primary",
          "prepend-icon": "mdi-book-check",
          onClick: _ctx.openNewActivity
        }, {
          default: _withCtx(() => [
            _createTextVNode("กิจกรรมให้การช่วยเหลือ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_btn, {
          variant: "text",
          color: "primary",
          icon: "mdi-book-check",
          density: "comfortable",
          onClick: _ctx.openNewActivity
        }, null, 8, ["onClick"])
      ]),
      (_ctx.household)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.activities), ([aid, activity]) => {
              return (_openBlock(), _createBlock(_component_ActivityCard, {
                key: aid,
                _id: _ctx.id,
                aid: aid,
                activity: activity,
                updateCallback: () => _ctx.getHousehold(true)
              }, null, 8, ["_id", "aid", "activity", "updateCallback"]))
            }), 128)),
            (Object.keys(_ctx.activities).length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, " ไม่มีกิจกรรมให้การช่วยเหลือ "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])),
    _createVNode(_component_v_overlay, {
      class: "add-form",
      modelValue: _ctx.newActivity,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newActivity) = $event)),
      persistent: true,
      "no-click-animation": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ActivityDetail, {
          _id: _ctx.id,
          closeWindow: _ctx.closeNewActivity
        }, null, 8, ["_id", "closeWindow"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}