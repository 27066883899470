import { getUserInfo } from '@/plugins/keycloak'

export enum ObjectStateLifecycle {
  LOCAL = 0,
  ONLINE = 1,
  DELETE = 2
}

export interface DataState {
  change: boolean
  updateDate?: Date
}

export interface ObjectState {
  lifecycle: ObjectStateLifecycle
  createDate?: Date
}

export interface ObjectStateWithDataState extends ObjectState {
  dataState: DataState
}

export enum HouseholdMemberRole {
  HEAD = 'หัวหน้าครัวเรือน',
  FATHER = 'พ่อ',
  MOTHER = 'แม่',
  FATHER_GRANDFATHER = 'ปู่',
  FATHER_GRANDMOTHER = 'ย่า',
  MOTHER_GRANDFATHER = 'ตา',
  MOTHER_GRANDMOTHER = 'ยาย',
  PARENT_ELDER_UNCLE = 'ลุง',
  PARENT_ELDER_AUNT = 'ป้า',
  PARENT_YOUNGER_AUNT = 'น้า',
  PARENT_YOUNGER_UNCLE = 'อา',
  HUSBAND = 'สามี',
  WIFE = 'ภรรยา',
  ELDER_BROTHER = 'พี่ชาย',
  ELDER_SISTER = 'พี่สาว',
  YOUNGER_BROTHER = 'น้องชาย',
  YOUNGER_SISTER = 'น้องสาว',
  SON_IN_LAW = 'ลูกเขย',
  DAUGHTER_IN_LAW = 'ลูกสะใภ้',
  SON = 'ลูก',
  DAUGHTER = 'หลาน',
  OTHER = 'อื่นๆ'
}

export enum Disability {
  VisualDisability = 'มีความพิการทางการเห็น',
  IntelligenceDisability = 'มีความพิการทางสติปัญญา',
  AutismDisability = 'มีความพิการทางออทิสติก',
  LearningDisability = 'มีความพิการทางการเรียนรู้',
  InterpretationDisability = 'มีความพิการทางการได้ยินหรือสื่อความหมาย',
  PhysicalDisability = 'มีความพิการทางการเคลื่อนไหวหรือทางร่างกาย',
  MentalDisability = 'มีความพิการทางจิตใจหรือพฤติกรรม'
}

export enum BeingStatus {
  LIVING = 'มีชีวิต',
  DEAD = 'เสียชีวิต'
}

export enum MainProblemType {
  HEALTH = 'ด้านสุขภาพ',
  LIVING = 'ด้านความเป็นอยู่',
  EDUCATION = 'ด้านการศึกษา',
  INCOME = 'ด้านรายได้',
  STATE_SERVICE = 'ด้านการเข้าถึงบริการรัฐ',
  OTHER = 'อื่นๆ'
}

export enum ProblemDetailHtypeMain {
  CAN_DEVELOP = 'มีความพร้อมสามารถพัฒนาได้',
  AID_REQUIRED = 'ต้องการให้สงเคราะห์ /ช่วยเหลือเพียงอย่างเดียว (สูงอายุ/พิการ)',
  AID_NOT_REQUIRED = 'ไม่ขอรับความช่วยเหลือ'
}

export enum ProblemDetailHtypeReason {
  SOLVED = 'ปัญหาคลี่คลายไปแล้ว',
  AID_NOT_REQUIRED = 'ไม่ขอรับความช่วยเหลือ',
  NO_PROBLEM = 'ไม่พบปัญหาจากการสำรวจล่าสุด'
}

export enum MainActivityType {
  TRACKING = 'ติดตามการแก้ปัญหา',
  TRAINING = 'ฝึกอาชีพ/สร้างรายได้เพิ่ม',
  VISIT = 'กิจกรรมการเยี่ยมบ้าน',
  AID = 'กิจกรรมสงเคราะห์',
  DEVELOP = 'กิจกรรมพัฒนาความสามารถ',
  OTHER = 'อื่นๆ'
}

export enum ActivityAidType {
  THINGS = 'สิ่งของ',
  SUBVENTION = 'เงินสงเคราะห์',
  UTILITIES = 'สาธารณูปโภค',
  TRAINING = 'อบรม/ศึกษาดูงาน/พัฒนาความสามารถ',
  COORDINATE = 'ประสานส่งต่อ',
  OTHER = 'อื่นๆ'
}

export enum ActivityResult {
  TRACKING = 'รอติดตามผล',
  SOLVED = 'กิจกรรมสามารถแก้ไขปัญหาได้แล้ว',
  NOT_SOLVED = 'ยังไม่สามารถแก้ไขปัญหาได้'
}

export interface ProblemType {
  type: MainProblemType,
  problemMpiId: number,
  description: string,
}

export interface ProblemHtype {
  main: ProblemDetailHtypeMain,
  description?: string,
  reason?: ProblemDetailHtypeReason
}

export interface TemplateProblemAidDetail {
  type?: ActivityAidType,
  detail?: string | string[]
}

export interface ProblemAidDetail extends TemplateProblemAidDetail {
  type: ActivityAidType,
  detail?: string | string[]
}

export interface ProblemDetail {
  title: string,
  detail: string,
  htype: ProblemHtype,
  aidDetail: ProblemAidDetail
}

export interface ImageRecord {
  objectState: ObjectStateWithDataState,
  image: string,
  filename: string,
  extension: string,
  description?: string
}

export interface RecordStaff {
  dataState: DataState,
  username: string,
  staff: string,
  organization: string,
}

export interface ProblemRecord {
  date: Date,
  recordStaff: RecordStaff,
  coordinator: string,
  phoneNumber?: string
}

export interface Problem {
  realId?: string,
  objectState: ObjectStateWithDataState,
  problemType: ProblemType,
  problemDetail: ProblemDetail,
  images?: { [key: string]: ImageRecord }
  record: ProblemRecord
  activitiesId?: { [aid: string]: boolean },
}

export interface TemplateActivityTypeTrainingDetail {
  topic?: string,
  memberId?: string,
  newIncome?: number
}

export interface ActivityTypeTrainingDetail extends TemplateActivityTypeTrainingDetail {
  topic: string,
  memberId: string,
  newIncome: number
}

export interface TemplateActivityTypeAidDetail {
  type?: ActivityAidType,
  detail?: string | string[]
  memberId?: string,
}

export interface ActivityTypeAidDetail extends TemplateActivityTypeAidDetail {
  type: ActivityAidType,
  detail?: string | string[]
  memberId: string,
}

export interface ActivityType {
  main: MainActivityType
  detail?: ActivityTypeTrainingDetail | ActivityTypeAidDetail
}

export interface Activity {
  objectState: ObjectStateWithDataState,
  problemsId: { [pid: string]: boolean }
  type: ActivityType
  result: ActivityResult,
  name: string
  povertySolvingMenu: string
  detail: string
  duration: {
    start: Date,
    end: Date
  }
  images?: { [key: string]: ImageRecord },
  recordStaff: RecordStaff
}

export interface Mpi {
  dataState: DataState,
  index: { [group: string]: boolean[] }
}

export interface JsonDate {
  year: number
  month: number
  date: number
}

export interface HouseholdAddress {
  dataState: DataState
  houseNum: string
  villageId: string | number
  houseOwnStatus: string
  residentType: string
  position?: [number, number]
}

export interface PersonalDisability {
  disabilities: Disability[],
  disabilityCard: boolean,
  disabilityAllowance?: number
}

export interface PersonalInfo {
  role: HouseholdMemberRole
  id: string
  prefix: string
  name: string
  surname: string
  gender: string
  birthDate: JsonDate
  job: string
  education: string
  religion: string
  phoneNumber?: string
  disability?: PersonalDisability
  isChronicallyIll?: boolean
  canSelfReliant?: boolean
  beingStatus?: BeingStatus
  primaryMedicalPrivilege?: string
  primaryHospital?: string
}
export interface PersonalFinancial {
  annualIncome: number
  legalDept: number
  illegalDept: number
  saving: number
}
export interface PersonalWelfare {
  hasStateWelfareCard?: boolean
  otherRelief?: { name: string, amount: number }[]
  stateWelfares?: { group: string, item: string, total?: number, detail?: string, date: Date }[]
}
export interface HouseholdMember {
  realId?: string,
  objectState: ObjectStateWithDataState,
  personalInfo: PersonalInfo,
  financial?: PersonalFinancial,
  welfare?: PersonalWelfare,
}

export interface Household {
  objectState: ObjectState,
  id: string
  recordStaff: RecordStaff,
  members: { [key: string]: HouseholdMember }
  address: HouseholdAddress,
  mpi?: Mpi,
  images?: { [key: string]: ImageRecord },
  problems?: { [key: string]: Problem }
  activities?: { [key: string]: Activity }
}

export const randHex = (size: number) => {
  const result = []
  const hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']

  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)])
  }
  return result.join('')
}

export function formatEmptyString (obj: any): any {
  if (typeof obj === 'string' && obj === '') {
    return undefined
  } else if (Array.isArray(obj)) {
    return obj.map(o => formatEmptyString(o))
  } else if (typeof obj === 'object' && obj !== null && !(obj instanceof Date)) {
    return Object.entries(obj).reduce((cum, [k, v]: [string, any]) => {
      cum[k] = formatEmptyString(v)
      return cum
    }, {} as { [key: string]: any })
  }
  return obj
}

export function formatDateString (obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(o => formatDateString(o))
  } else if (typeof obj === 'object' && obj !== null && !(obj instanceof Date)) {
    return Object.entries(obj).reduce((cum, [k, v]: [string, any]) => {
      cum[k] = formatDateString(v)
      return cum
    }, {} as { [key: string]: any })
  } else if (
    (typeof obj === 'string') &&
    /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|(Z|(\+\d\d:\d\d)|(-\d\d:\d\d)))$/.test(obj)
  ) {
    return new Date(obj)
  }
  return obj
}

async function getHouseholdStorageKey () {
  const user = await getUserInfo()
  if (user) {
    return `households_${user.sub}`
  }
}

let households: { [id: string]: Household } | undefined

async function initHouseholds () {
  const sKey = await getHouseholdStorageKey()
  console.log('sKey', sKey)
  if (!sKey) return
  const _households = localStorage.getItem(sKey)
  if (!_households) {
    households = {}
  } else {
    households = JSON.parse(_households)
  }
}

let raiseStorageFullError: () => void | undefined

export function setRaiseStorageFullError (fn: () => void) {
  raiseStorageFullError = fn
}

export async function saveHousehold (household: Household) {
  if (!households) {
    await initHouseholds()
  }
  if (!households) return
  const tempHousehold: Household = formatEmptyString(household)
  const oldHousehold = households[household.id]
  households[household.id] = tempHousehold
  try {
    const sKey = await getHouseholdStorageKey()
    if (!sKey) return
    localStorage.setItem(sKey, JSON.stringify(households))
    return true
  } catch (error) {
    console.log('error', error)
    if (error instanceof DOMException && error.name === 'QuotaExceededError') {
      console.log('storage full')
      if (raiseStorageFullError) {
        raiseStorageFullError()
      }
    }
    if (oldHousehold) {
      households[household.id] = oldHousehold
    }
    return false
  }
}

export async function getHouseholds (): Promise<Household[] | undefined> {
  if (!households) {
    await initHouseholds()
  }
  return households
    ? formatDateString(JSON.parse(JSON.stringify(Object.values(households))))
    : undefined
}

export async function getHousehold (id: string): Promise<Household | undefined> {
  if (!households) {
    await initHouseholds()
  }
  if (!households) return
  const household = households[id]
  const res: Household | undefined = household ? formatDateString(JSON.parse(JSON.stringify(household))) : undefined
  return res
}

export async function deleteHousehold (id: string) {
  if (!households) {
    await initHouseholds()
  }
  if (!households) return
  const sKey = await getHouseholdStorageKey()
  if (!sKey) return
  delete households[id]
  localStorage.setItem(sKey, JSON.stringify(households))
}

export function getLocalObjectState (): ObjectState {
  return {
    lifecycle: ObjectStateLifecycle.LOCAL,
    createDate: new Date()
  }
}

export function getDefaultDataState (): DataState {
  return {
    change: true,
    updateDate: new Date()
  }
}

export function getLocalObjectStateWithDataState (): ObjectStateWithDataState {
  return {
    lifecycle: ObjectStateLifecycle.LOCAL,
    createDate: new Date(),
    dataState: getDefaultDataState()
  }
}

// ----- init -----
initHouseholds()
