import { _keycloak } from '@/plugins/keycloak'

export class ApiUtils {
  url: string
  defaultHeaders?: HeadersInit
  getTokenFn: () => string | undefined
  constructor (url: string, defaultHeaders?: HeadersInit, getTokenFn?: () => string | undefined) {
    this.url = url
    this.defaultHeaders = defaultHeaders
    this.getTokenFn = getTokenFn ?? (() => _keycloak.token)
  }

  getToken (): string | undefined {
    if (!this.getTokenFn) return
    const token = this.getTokenFn()
    if (!token) return
    if (!this.defaultHeaders) {
      this.defaultHeaders = {}
    }
    this.defaultHeaders = { ...this.defaultHeaders, Authorization: `Bearer ${token}` }
    return token
  }

  async fetch (input: string, init?: RequestInit | undefined, getToken = true) {
    if (getToken) {
      this.getToken()
    }
    if (this.defaultHeaders) {
      if (!init) {
        init = {}
      }
      init.headers = { ...this.defaultHeaders, ...init.headers }
    }
    return fetch(this.url + input, init)
  }
}
