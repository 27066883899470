import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import keycloak from './plugins/keycloak'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import store from './store'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme: {
        colors: {
          primary: '#455396',
          secondary: '#777',
          background: '#FCFCFC'
        }
      }
    }
  }
})

const app = createApp(App).use(store)
app.use(router)
app.use(keycloak, {
  onLoad: 'login-required',
  checkLoginIframe: false
})
app.use(vuetify)

app.mount('#app')

export default app
