<template>
  <div class="mpi-chart">
    <div :class="'mpi-icon' + (mpiHighlight && mpiHighlight[i] ? ' mpi-highlight' : '')" v-for="(icon, i) in icons" :key="icon">
      <v-icon class="mpi-icon-icon">{{ icon }}</v-icon>
      <v-tooltip
        activator="parent"
        location="top"
        :open-delay="200"
        :transition="false"
      >{{ mpiNames[i] }}</v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MpiChart',
  props: ['mpiHighlight'],
  data () {
    return {
      icons: [
        'mdi-heart-pulse',
        'mdi-home',
        'mdi-school',
        'mdi-cash-multiple',
        'mdi-domain'
      ],
      mpiNames: [
        'สุขภาพ',
        'ความเป็นอยู่',
        'การศึกษา',
        'รายได้',
        'การเข้าถึงบริการภาครัฐ'
      ]
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

.mpi-chart {
  display: flex;
  gap: 0.25em;
  flex-wrap: wrap;
}

.mpi-icon {
  color: variables.$bg-color;
  background-color: variables.$bg-color-gray;
  padding: 0.5em;
  border-radius:50%;
}

.mpi-highlight {
  background-color: #D93B48;
}

</style>
