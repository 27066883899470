<template>
  <div class="card-body">
    <div class="data-title">
      ค่าดัชนี 5 มิติ
    </div>
    <div class="data-value">
      <MpiChart v-if="household?.mpi" class="mpi-chart-outter" :mpi-highlight="mpiTemplate.map((group, i) => {
        return (household?.mpi) ? household.mpi.index[group.name].reduce((cum, e, j) => (cum || ((mpiTemplate[i].score[j] > 0) && e)), false) : false
      })" />
    </div>
    <div class="data-title">
      พิกัดบ้าน
    </div>
    <div class="data-value">
      {{
        household?.address?.position ? `${
        formatNumber(household?.address?.position[0], { maximumFractionDigits: 4 })
        }, ${
          formatNumber(household?.address?.position[1], { maximumFractionDigits: 4 })
        }` : 'ยังไม่มีพิกัดบ้าน'
      }}
      <span class="edit-btn" @click="openEditLocationForm">
        <v-icon icon="mdi-pencil" color="primary"></v-icon>
      </span>
    </div>
    <div class="data-title">
      สถานะการมีบ้านเลขที่
    </div>
    <div class="data-value">
      {{ household?.address.houseOwnStatus }}
      &nbsp;
      <span class="edit-btn" @click="openEditHouseholdForm">
        <v-icon icon="mdi-pencil" color="primary"></v-icon>
      </span>
    </div>
    <div class="data-title">
      ที่พักอาศัยตั้งอยู่บน
    </div>
    <div class="data-value">
      {{ household?.address.residentType }}
      &nbsp;
      <span class="edit-btn" @click="openEditHouseholdForm">
        <v-icon icon="mdi-pencil" color="primary"></v-icon>
      </span>
    </div>
    <div class="data-title">
      ที่อยู่ปัจจุบัน
    </div>
    <div class="data-value">
      {{ household ? getAddressString(household.address) : undefined }}
      &nbsp;
      <span class="edit-btn" @click="openEditHouseholdForm">
        <v-icon icon="mdi-pencil" color="primary"></v-icon>
      </span>
    </div>
    <div class="data-title">
      รายได้ครัวเรือน/ปี
    </div>
    <div class="data-value">
      {{ formatNumber(annualIncome) ?? '-' }} บาท
      (~เฉลี่ยต่อคน {{ formatNumber(avgAnnualIncome) ?? '-' }} บาท)
    </div>
    <div class="data-title">
      หนี้สินครัวเรือน/ปี
    </div>
    <div class="data-value">
      {{ annualLegalDept && annualIllegalDept ? formatNumber(annualLegalDept + annualIllegalDept) : '-' }} บาท
    </div>
    <div class="data-title">
      หนี้ในระบบ/ปี
    </div>
    <div class="data-value">
      {{ formatNumber(annualLegalDept) ?? '-' }} บาท
    </div>
    <div class="data-title">
      หนี้นอกระบบ/ปี
    </div>
    <div class="data-value">
      {{ formatNumber(annualIllegalDept) ?? '-' }} บาท
    </div>
    <div class="data-title">
      เงินออม/ปี
    </div>
    <div class="data-value">
      {{ formatNumber(annualSaving) ?? '-' }} บาท
    </div>
    <div class="data-title">
      รูปภาพบ้าน
    </div>
    <div class="data-value">
      <ImageList :images="household?.images" :id="id" :choose-image-callback="openChooseImageForm" />
    </div>
    <v-overlay class="household-edit-form" v-model="editHouseholdForm" :persistent="true" :no-click-animation="true">
      <EditHouseholdForm :closeWindow="closeEditHouseholdForm" :id="id" :onSubmit="() => getHousehold(true)"/>
    </v-overlay>
    <v-overlay class="household-edit-form" v-model="editLocationForm" :persistent="true" :no-click-animation="true">
      <EditLocationForm :closeWindow="closeEditLocationForm" :id="id" />
    </v-overlay>
    <v-overlay class="household-edit-form" v-model="chooseImageForm" :persistent="true" :no-click-animation="true">
      <ChooseImage :close-window="closeChooseImageForm" :id="id" />
    </v-overlay>
    <v-dialog
      v-model="openEditLocationFormErrorPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          ไม่สามารถแก้ไขพิกัดบ้านได้
        </v-card-title>
        <div class="errors-report">
          <v-card-text>
            ไม่สามารถแก้ไขพิกัดบ้านได้เนื่องจากไม่มีอินเตอร์เน็ต
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn color="primary" block @click="openEditLocationFormErrorPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Household, HouseholdAddress, getHousehold } from '@/models/household'
import { defineComponent } from 'vue'
import EditHouseholdForm from '@/components/HouseholdInfo/EditHouseholdForm.vue'
import EditLocationForm from '@/components/HouseholdInfo/EditLocationForm.vue'
import ChooseImage from '@/components/ChooseImage/index.vue'
import { formatNumber } from '@/utils/number-utils'
import mpiTemplate from '@/assets/template/mpi-template.json'
import MpiChart from '@/components/Chart/MpiChart.vue'
import ImageList from '@/components/Chart/ImageList.vue'
import { dataURItoBlob } from '@/utils/file-utils'
import { getAddressVillage } from '@/models/address'

export default defineComponent({
  name: 'HouseholdInfo',
  props: ['id', 'updateCallback'],
  components: {
    EditHouseholdForm,
    MpiChart,
    EditLocationForm,
    ImageList,
    ChooseImage
  },
  data () {
    return {
      dataURItoBlob,
      mpiTemplate,
      formatNumber,
      editHouseholdForm: false,
      editLocationForm: false,
      chooseImageForm: false,
      openEditLocationFormErrorPopup: false,
      household: undefined as Household | undefined,
      annualIncome: undefined as number | undefined,
      annualLegalDept: undefined as number | undefined,
      annualIllegalDept: undefined as number | undefined,
      annualSaving: undefined as number | undefined,
      avgAnnualIncome: undefined as number | undefined
    }
  },
  mounted () {
    this.getHousehold()
  },
  methods: {
    async getHousehold (isUpdate = false) {
      const household = await getHousehold(this.id)
      if (!household) {
        this.$router.push('/notfound')
      } else {
        this.annualIncome = Object.values(household.members).reduce((cum, curr) => (
          (curr.financial?.annualIncome !== undefined) ? (curr.financial.annualIncome + (cum ?? 0)) : cum
        ), undefined as number | undefined)
        this.annualLegalDept = Object.values(household.members).reduce((cum, curr) => (
          (curr.financial?.legalDept !== undefined) ? (curr.financial.legalDept + (cum ?? 0)) : cum
        ), undefined as number | undefined)
        this.annualIllegalDept = Object.values(household.members).reduce((cum, curr) => (
          (curr.financial?.illegalDept !== undefined) ? (curr.financial.illegalDept + (cum ?? 0)) : cum
        ), undefined as number | undefined)
        this.annualSaving = Object.values(household.members).reduce((cum, curr) => (
          (curr.financial?.saving !== undefined) ? (curr.financial.saving + (cum ?? 0)) : cum
        ), undefined as number | undefined)
        this.avgAnnualIncome = this.annualIncome ? (this.annualIncome / Object.keys(household.members).length) : undefined
        this.household = household
        if (isUpdate && this.updateCallback) {
          this.updateCallback()
        }
      }
    },
    closeEditHouseholdForm () {
      this.editHouseholdForm = false
    },
    openEditHouseholdForm () {
      this.editHouseholdForm = true
    },
    closeEditLocationForm (isUpdate = false) {
      this.editLocationForm = false
      if (isUpdate) {
        this.getHousehold()
      }
    },
    openEditLocationForm () {
      this.editLocationForm = true
    },
    openChooseImageForm () {
      this.chooseImageForm = true
    },
    closeChooseImageForm (isUpdate = false) {
      this.chooseImageForm = false
      if (isUpdate) {
        this.getHousehold()
      }
    },
    getAddressString (address: HouseholdAddress) {
      const villageAddress = getAddressVillage(parseInt(address.villageId.toString()))
      return `${address.houseNum} ${villageAddress.village} ${villageAddress.subdistrict} ${villageAddress.district} ${villageAddress.province}`
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.household-edit-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-btn {
  :hover {
    cursor: pointer;
  }
}

.card-body {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @media only screen and (max-width: variables.$screen-xs) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.data-title {
  grid: 1;
  grid-column: span 5;
  text-align: end;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: end;
  @media only screen and (max-width: variables.$screen-xs) {
    text-align: start;
    justify-content: start;
  }
}

.data-value {
  font-weight: bold;
  grid: 1;
  grid-column: span 7;
  text-align: start;
  padding: 0.75rem;
  @media only screen and (max-width: variables.$screen-xs) {
    margin-bottom: 1rem;
  }
}

.mpi-chart-outter {
  font-size: 1rem;
  @media only screen and (max-width: variables.$screen-xs) {
    display: flex;
    justify-content: center;
  }
}

</style>
