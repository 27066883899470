<template>
  <div class="card" @click="openViewMember">
    <div class="card-header">
      <template v-for="(ageBin, i) in ageBins">
        <div class="member-avatar" v-if="age >= ageBin.rng[0] && age < ageBin.rng[1]" :key="ageBin.title">
          <img :src="require(`@/assets/images/${i+1}.png`)" height="50">
          <v-tooltip
            activator="parent"
            location="top"
            :open-delay="200"
            :transition="false"
          >{{ ageBin.title }} (อายุ {{ age }} ปี)</v-tooltip>
        </div>
      </template>
      <div>
        <div class="member-name member-text">
          {{ personalInfo.prefix }}{{ personalInfo.name }} {{ personalInfo.surname }}
        </div>
        <div class="member-role">
          {{ personalInfo.role }}
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="member-short-info">
      <div class="member-info-title">สถานะ :</div>
      <div class="member-info-value">
        <template v-for="(ageBin, i) in ageBins">
          <div class="member-avatar" v-if="age >= ageBin.rng[0] && age < ageBin.rng[1]" :key="ageBin.title">
            <img :src="require(`@/assets/images/${i+1}.png`)" height="24">
            <v-tooltip
              activator="parent"
              location="top"
              :open-delay="200"
              :transition="false"
            >{{ ageBin.title }} (อายุ {{ age }} ปี)</v-tooltip>
          </div>
        </template>
        <div v-if="personalInfo.disability" class="member-role-status">
          <img :src="require(`@/assets/images/6.png`)" height="24">
          <v-tooltip
              activator="parent"
              location="top"
              :open-delay="200"
              :transition="false"
            >ผู้พิการ</v-tooltip>
        </div>
        <div v-if="personalInfo.isChronicallyIll" class="member-role-status">
          <img :src="require(`@/assets/images/7.png`)" height="24">
          <v-tooltip
              activator="parent"
              location="top"
              :open-delay="200"
              :transition="false"
            >ผู้ป่วยโรคเรื้อรัง</v-tooltip>
        </div>
      </div>
      <div class="member-info-title">รายได้ :</div>
      <div class="member-info-value">&nbsp;{{ financial && (financial.annualIncome || financial.annualIncome === 0) ? formatNumber(financial.annualIncome) : '-' }}</div>
      <div class="member-info-title">เงินออม :</div>
      <div class="member-info-value">&nbsp;{{ financial && (financial.saving || financial.saving === 0) ? formatNumber(financial.saving) : '-' }}</div>
      <div class="member-info-title">หนี้สิน :</div>
      <div class="member-info-value">&nbsp;{{
        financial && (
          (financial.legalDept || financial.legalDept === 0) ||
          (financial.illegalDept || financial.illegalDept === 0)
        ) ? ((financial.legalDept ?? 0) + (financial.illegalDept ?? 0)).toLocaleString() : '-'
      }}</div>
    </div>
  </div>
  <v-overlay class="add-form"  v-model="viewMember" :persistent="true" :no-click-animation="true">
    <MemberDetail :_id="_id" :mid="mid" :closeWindow="closeViewMember" />
  </v-overlay>
</template>

<script lang="ts">
import { JsonDate, PersonalFinancial, PersonalInfo, PersonalWelfare } from '@/models/household'
import { defineComponent } from 'vue'
import MemberDetail from './MemberDetail.vue'
import { formatNumber } from '@/utils/number-utils'

export default defineComponent({
  name: 'MemberCard',
  props: ['_id', 'mid', 'member', 'updateCallback'],
  components: {
    MemberDetail
  },
  data () {
    return {
      formatNumber,
      viewMember: false,
      personalInfo: this.member.personalInfo as PersonalInfo,
      financial: this.member.financial as PersonalFinancial | undefined,
      welfare: this.member.welfare as PersonalWelfare | undefined,
      ageBins: [
        { title: 'เด็กแรกเกิด/ปฐมวัย', rng: [0, 4] },
        { title: 'วัยเรียน', rng: [5, 14] },
        { title: 'วัยรุ่น/นักศึกษา', rng: [15, 21] },
        { title: 'วัยผู้ใหญ่/วัยแรงงาน', rng: [21, 59] },
        { title: 'วัยผู้สูงอายุ', rng: [60, Infinity] }
      ],
      age: this.calcAge(this.member.personalInfo.birthDate)
    }
  },
  methods: {
    openViewMember () {
      this.viewMember = true
    },
    closeViewMember (isUpdate = false) {
      this.viewMember = false
      if (isUpdate && this.updateCallback) {
        this.updateCallback()
      }
    },
    calcAge (birthDate: JsonDate) {
      let totalMonth = 12 * ((new Date()).getFullYear() - birthDate.year)
      totalMonth += (new Date()).getMonth() - birthDate.month
      return Math.floor(totalMonth / 12)
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-info-title {
  grid: 1;
  grid-column: span 5;
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
  font-weight: bold;
  font-size: variables.$font-sm;
}

.member-info-value {
  grid: 1;
  grid-column: span 7;
  font-size: variables.$font-sm;
  display: flex;
  gap: 0.1rem;
  padding-left: 0.1rem;
}

.member-short-info {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.25rem
}

.member-text {
  width: 12rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media only screen and (max-width: variables.$screen-xs) {
    width: 7rem;
  }
}

.card-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.member-name {
  font-size: variables.$font-lg;
}

.member-role {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: variables.$font-md;
}

.member-avatar {
  display: flex;
  align-items: center;
}

.member-role-status {
  height: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

</style>
