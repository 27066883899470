<template>
  <div :key="loading ? 1 : 0" class="card-body">
    <div class="member-panel panel-desktop">
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-pencil" @click="openEditMpi">แก้ไข</v-btn>
    </div>
    <div class="member-panel panel-mobile">
      <v-btn variant="text" color="primary" icon="mdi-pencil" density="comfortable" @click="openEditMpi"></v-btn>
    </div>
    <div v-if="mpiValue" class="member-list">
      <v-expansion-panels multiple>
        <v-expansion-panel
          bg-color="background"
          v-for="group in mpiTemplate"
          :key="group.name"
        >
          <v-expansion-panel-title expand-icon="mdi-menu-down">
            <div class="mpi-view-group-item-value">
              <v-icon v-if="mpiValue[group.name].reduce((cum, e, j) => (cum || e), false)" color="error">mdi-close-box</v-icon>
              <v-icon v-else color="success">mdi-checkbox-marked</v-icon>
            </div>
            <h4 class="inner-text">{{ group.name }}</h4>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div
              class="mpi-view-group inner-text"
            >
              <div
                class="mpi-view-group-item-wrap"
                v-for="(item, j) in group.items"
                :key="item"
              >
                <div class="mpi-view-group-item">
                  <div class="mpi-view-group-item-value">
                    <v-icon v-if="mpiValue[group.name][j]" color="error">mdi-close-box</v-icon>
                    <v-icon v-else color="success">mdi-checkbox-marked</v-icon>
                  </div>
                  <div class="mpi-view-group-item-title">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
  <v-overlay class="add-form"  v-model="editMpi" :persistent="true" :no-click-animation="true">
    <EditMpiForm :id="id" :closeWindow="closeEditMpi" />
  </v-overlay>
</template>

<script lang="ts">
import { Mpi, HouseholdMemberRole, getHousehold } from '@/models/household'
import { defineComponent } from 'vue'
import mpiTemplate from '@/assets/template/mpi-template.json'
import EditMpiForm from './EditMpiForm.vue'

export default defineComponent({
  name: 'MpiInfo',
  props: ['id', 'updateCallback'],
  components: {
    EditMpiForm
  },
  data () {
    return {
      mpiTemplate,
      HouseholdMemberRole,
      editMpi: false,
      mpiValue: undefined as { [group: string]: boolean[] } | undefined,
      loading: true
    }
  },
  mounted () {
    this.getHousehold()
  },
  methods: {
    async getHousehold (isUpdate = false) {
      this.loading = true
      const household = await getHousehold(this.id)
      if (!household) {
        this.$router.push('/notfound')
      } else {
        if (isUpdate && this.updateCallback) {
          this.updateCallback()
        }
        this.mpiValue = household.mpi?.index
        this.loading = false
      }
    },
    openEditMpi () {
      this.editMpi = true
    },
    closeEditMpi (isUpdate = false) {
      this.editMpi = false
      if (isUpdate) {
        this.getHousehold(true)
      }
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-list {
  flex: 1;
  // display: flex;
}

.member-panel {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}

.panel-desktop {
  @media only screen and (max-width: variables.$screen-xs) {
    display: none;
  }
}

.panel-mobile {
  display: none;
  @media only screen and (max-width: variables.$screen-xs) {
    display: flex;
    gap: 0.25rem;
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
  min-height: 340px;
}

.mpi-view-group {
  display: flex;
  flex-direction: column;
  // margin-top: 1.5rem;
}

.mpi-view-group-item {
  display: flex;
  padding: 0.75rem;
}

.mpi-view-group-item-title {
  flex: 1;
  // padding-top: 8px;
  // margin-bottom: 1rem;
}

.mpi-view-group-item-value {
  padding-right: 1rem;
}

.mpi-view-group-item-wrap {
  &:hover {
    background-color: variables.$text-color-main-contrast;
  }
}

</style>
