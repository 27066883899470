<template>
  <div class="home">
    <HouseholdTable v-if="user" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HouseholdTable from '@/components/HouseholdTable/index.vue'
import { KeycloakTokenParsed } from 'keycloak-js'
import { getUserInfo } from '@/plugins/keycloak'

export default defineComponent({
  name: 'HomeView',
  components: {
    HouseholdTable
  },
  data () {
    return {
      user: undefined as KeycloakTokenParsed | undefined
    }
  },
  mounted () {
    this.getUser()
  },
  methods: {
    async getUser () {
      this.user = await getUserInfo()
    }
  }
})
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

</style>
