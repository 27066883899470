<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.hasStateWelfareCard }}</p>&nbsp;
        &nbsp;
      </div>
      <div class="new-household-form-item">
        <v-radio-group v-model="hasStateWelfareCard" :error-messages="hasStateWelfareCardErr"
          @update:modelValue="updateHasStateWelfareCardStore" color="primary" inline>
          <v-radio label="ถือบัตร" :value="true"></v-radio>
          <v-radio label="ไม่ถือบัตร" :value="false"></v-radio>
        </v-radio-group>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.otherRelief }}</p>&nbsp;
        &nbsp;
      </div>
      <div class="new-household-form-item">
        <div class="auto-width list-input">
          <div class="list-input-item list-input-item-add">
            <div class="list-input-item-other-relief-title">
              <v-text-field :key="otherReliefTitleKey" v-model="otherReliefTitle" :error-messages="otherReliefTitleErr"
                @keydown.enter="addOtherRelief" color="primary" density="compact" variant="outlined"
                label="การสงเคระห์" />
            </div>
            <div class="list-input-item-other-relief-amount">
              <v-text-field ref="savingRef" :key="otherReliefAmountKey" :model-value="otherReliefAmount"
                @update:model-value="otherReliefAmountChanged" @keydown.enter="addOtherRelief"
                :error-messages="otherReliefAmountErr" color="primary" density="compact" variant="outlined"
                label="จำนวนเงิน" type="number" />
            </div>
            <div class="list-input-item-other-relief-btn">
              <v-btn variant="text" icon="mdi-plus-box" @click="addOtherRelief" size="1.5rem"/>
            </div>
          </div>
          <div class="list-input-item" v-if="!otherRelief || otherRelief.length === 0">
            <p class="list-no-item-text">ไม่มีรายการการสงเคราะห์อื่นๆ</p>
          </div>
          <div class="list-input-item-other-relief-items">
            <v-chip-group>
              <v-chip v-for="(item, i) in otherRelief" :key="item.name" closable @click:close="deleteOtherRelief(i)" >
                <p class="list-chip">{{ item.name }}</p>
                <p>&nbsp;({{ item.amount }} บาท)</p>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.stateWelfares }}</p>&nbsp;
        &nbsp;
      </div>
      <div class="new-household-form-item">
        <div class="auto-width welfare-form-wrapper">
          <v-select
            v-model="stateWelfares"
            :items="stateWelfaresItems"
            @update:model-value="updateStateWelfaresStore"
            density="compact"
            variant="outlined"
            color="primary"
            multiple
            placeholder="เลือกสวัสดิการ"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <p class="custom-select-chip">
                  {{ item.props._idx }}.&nbsp;{{ item.title }}
                </p>
              </v-chip>
              <span
                v-if="index === 3"
                class="text-grey text-caption align-self-center"
              >
                (อื่นๆ +{{ (stateWelfares ? stateWelfares.length : 3) - 3 }})
              </span>
            </template>
            <template #item="data">
              <template v-if="data.props.header">
                <div class="auto-width">
                  <v-divider />
                  <v-list-subheader> {{ data.props.header }} </v-list-subheader>
                  <v-divider />
                </div>
              </template>
              <v-list-item class="auto-width" v-else v-bind="data.props" :title="false">
                <v-list-item-title>
                  <div class="custom-select-item-text">
                    <div>
                      <v-icon v-if="stateWelfares.includes(data.props.value as number)">mdi-checkbox-marked</v-icon>
                      <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                      &nbsp;
                    </div>
                    <div>{{ data.props._idx }}.&nbsp;</div>
                    <div class="custom-select-item-text-title">{{ data.props.title }}</div>
                    <v-tooltip
                      activator="parent"
                      location="top"
                      :open-delay="200"
                      :transition="false"
                    >{{ data.props.title  }}</v-tooltip>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <div class="welfare-detail-container">
            <p class="more-btn" @click="moreBtnClicked">
              แสดง{{moreWelfareDetail ? 'น้อยลง' : 'เพิ่มขึ้น'}}
            </p>
            <div v-if="moreWelfareDetail" class="welfare-detail-list">
              <p v-if="stateWelfares.length === 0" class="list-no-item-text">
                ไม่มีสวัสดิการ
              </p>
              <div v-for="idx in lodash.sortBy(stateWelfares, (item) => item)" :key="idx" class="welfare-detail-item">
                <p class="welfare-detail-item-title">
                  {{ stateWelfaresItems[idx].props._idx }}.
                  {{ stateWelfaresItems[idx].title }}
                </p>
                <div class="welfare-detail-item-fill">
                  <div class="welfare-detail-item-date">
                    <VueDatePicker
                      v-model="stateWelfaresDetail[idx].date"
                      @update:model-value="() => updateStateWelfaresStore()"
                      :enable-time-picker="false"
                      locale="th"
                      :format-locale="th"
                      format="dd/MM/y"
                      select-text="เลือก"
                      cancel-text="ยกเลิก"
                    ></VueDatePicker>
                  </div>
                  <div class="welfare-detail-item-detail">
                    <div v-if="stateWelfaresItems[idx]._detailMode === 1">
                      <v-text-field
                        :ref="`welfareDetailAmountRef_${idx}`"
                        :key="stateWelfaresKey[idx]"
                        :model-value="stateWelfaresDetail[idx].total"
                        @update:modelValue="(val: string) => welfareDetailAmountRefChanged(idx, val)"
                        @update:focused="(val) => {if (!val) updateStateWelfaresStore()}"
                        color="primary"
                        density="compact"
                        variant="outlined"
                        type="number"
                        label="จำนวนเงิน"
                        suffix="บาท"
                      />
                    </div>
                    <div v-if="stateWelfaresItems[idx]._detailMode === 2">
                      <v-text-field
                        v-model="stateWelfaresDetail[idx].detail"
                        @update:model-value="() => updateStateWelfaresStore()"
                        @update:focused="(val) => {if (!val) updateStateWelfaresStore()}"
                        color="primary"
                        density="compact"
                        variant="outlined"
                        label="ปัจจัยอื่นๆ ที่ได้รับ"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p  v-if="moreWelfareDetail" class="more-btn" @click="moreBtnClicked">
              แสดง{{moreWelfareDetail ? 'น้อยลง' : 'เพิ่มขึ้น'}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { BeingStatus, PersonalWelfare } from '@/models/household'
import stateWelfaresTemplate from '@/assets/template/state-welfares-template.json'
import lodash from 'lodash'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { th } from 'date-fns/locale'

export default defineComponent({
  name: 'HouseholdMemberWelfareForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'welfare'],
  components: {
    VueDatePicker
  },
  setup () {
    const store = useStore()
    return {
      BeingStatus,
      store,
      lodash,
      th
    }
  },
  data () {
    const stateWelfaresItems: {
      props: { header?: string, _idx?: number },
      title?: string,
      value?: number,
      _value?: { group: string, item: string },
      _detailMode?: number
    }[] = []
    let i = 0
    for (const { group, items, detailMode } of stateWelfaresTemplate) {
      stateWelfaresItems.push({ props: { header: group } })
      items.forEach((item, it) => {
        i++
        const temp = { title: item, value: stateWelfaresItems.length, _value: { group, item }, props: { _idx: i }, _detailMode: detailMode[it] }
        stateWelfaresItems.push(temp)
      })
    }

    const welfare: PersonalWelfare | undefined = this.welfare
    const initialStateWelfares: number[] = []
    if (welfare?.stateWelfares) {
      const stateWelfaresMap: { [key: string]: { [key: string]: boolean } } = {}
      for (const { group, item } of welfare.stateWelfares) {
        if (!stateWelfaresMap[group]) {
          stateWelfaresMap[group] = {}
        }
        stateWelfaresMap[group][item] = true
      }
      stateWelfaresItems.forEach(({ _value }, i) => {
        if (_value && stateWelfaresMap[_value.group] && stateWelfaresMap[_value.group][_value.item]) {
          initialStateWelfares.push(i)
        }
      })
    }

    return {
      moreWelfareDetail: false,
      otherReliefTitleKey: Math.random(),
      otherReliefAmountKey: Math.random(),
      hasStateWelfareCard: ref<boolean>(welfare?.hasStateWelfareCard !== undefined ? welfare.hasStateWelfareCard : false),
      hasStateWelfareCardErr: ref<string | undefined>(),
      otherRelief: ref<{ name: string, amount: number }[]>(welfare?.otherRelief ?? []),
      otherReliefTitle: ref<string | undefined>(),
      otherReliefAmount: ref<string | undefined>(),
      otherReliefTitleErr: ref<string | undefined>(),
      otherReliefAmountErr: ref<string | undefined>(),
      stateWelfares: ref<number[]>(initialStateWelfares),
      titleMap: {
        hasStateWelfareCard: 'ถือบัตรสวัสดิการแห่งรัฐ',
        otherRelief: 'การสงเคราะห์อื่นๆ',
        stateWelfares: 'สวัสดิการแห่งรัฐที่สัมพันธ์กับมิติความยากจนของ TPMAP'
      },
      stateWelfaresItems,
      stateWelfaresDetail: stateWelfaresItems.map((item) => {
        let initItem
        if (welfare?.stateWelfares) {
          initItem = welfare.stateWelfares.find((e) => (e.group === item._value?.group && e.item === item._value.item))
        }
        return {
          total: initItem?.total ? initItem.total.toString() : undefined as string | undefined,
          detail: initItem ? initItem.detail : undefined as string | undefined,
          date: initItem ? initItem.date : new Date()
        }
      }),
      stateWelfaresKey: stateWelfaresItems.map(() => Math.random())
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    this.updateHasStateWelfareCardStore()
    this.updateOtherReliefStore()
    this.updateStateWelfaresStore()
  },
  methods: {
    validate () {
      this.updateHasStateWelfareCardStore()
      this.updateOtherReliefStore()
      this.updateStateWelfaresStore()
    },
    updateHasStateWelfareCardStore () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'hasStateWelfareCard',
        value: this.hasStateWelfareCard
      })
    },
    updateOtherReliefStore () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'otherRelief',
        value: this.otherRelief
      })
    },
    updateStateWelfaresStore () {
      const result = []
      if (this.stateWelfares) {
        const stateWelfares = [...this.stateWelfares]
        stateWelfares.sort((a, b) => a - b)
        for (const idx of stateWelfares) {
          result.push({
            ...this.stateWelfaresItems[idx]._value,
            total: ((v: string | undefined) => (v ? parseFloat(v) : undefined))(this.stateWelfaresDetail[idx].total),
            detail: this.stateWelfaresDetail[idx].detail,
            date: this.stateWelfaresDetail[idx].date
          })
        }
      }
      console.log('result', result)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'stateWelfares',
        value: result
      })
    },
    otherReliefAmountChanged (value: string) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.otherReliefAmountKey = Math.random()
        this.$nextTick(() => {
          const savingRef = this.$refs.savingRef as any
          savingRef.focus()
        })
      } else {
        this.otherReliefAmount = value
      }
    },
    addOtherRelief () {
      this.otherReliefTitleErr = validateEmpty(this.otherReliefTitle, undefined)
      this.otherReliefAmountErr = validateEmpty(this.otherReliefAmount, undefined)
      if (this.otherReliefTitleErr || this.otherReliefAmountErr) {
        return
      }
      const otherRelief = this.otherRelief
      if (this.otherReliefTitle && (this.otherReliefAmount)) {
        otherRelief.push({ name: this.otherReliefTitle, amount: parseFloat(this.otherReliefAmount) })
      }
      this.otherRelief = otherRelief
      this.updateOtherReliefStore()
      this.$nextTick(() => {
        this.otherReliefTitle = undefined
        this.otherReliefAmount = undefined
        this.otherReliefTitleKey = Math.random()
        this.otherReliefAmountKey = Math.random()
      })
    },
    deleteOtherRelief (i: number) {
      const otherRelief = [...this.otherRelief]
      otherRelief.splice(i, 1)
      this.otherRelief = otherRelief
      this.updateOtherReliefStore()
    },
    moreBtnClicked () {
      this.moreWelfareDetail = !this.moreWelfareDetail
    },
    welfareDetailAmountRefChanged (idx: number, value: string | undefined) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.stateWelfaresKey[idx] = Math.random()
        this.$nextTick(() => {
          const ref = (this.$refs[`welfareDetailAmountRef_${idx}`] as any[])[0]
          ref.focus()
        })
        return
      }
      this.stateWelfaresDetail[idx].total = value
      this.updateStateWelfaresStore()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

.custom-select-item-text-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-select-item-text {
  display: flex;
  color: variables.$text-color;
}

.custom-select-chip {
  max-width: 6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.welfare-detail-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.welfare-form-wrapper {
  padding: 0.5rem;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
}

.more-btn {
  font-size: variables.$font-sm;
  color: variables.$main-color;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.welfare-detail-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.welfare-detail-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.welfare-detail-item-fill {
  width: 100%;
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
}

.welfare-detail-item-title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.welfare-detail-item-detail {
  width: 100%;
}

.welfare-detail-item-date {
  width: 100%;
  // padding-bottom: calc(18px + 0.28rem);
}

</style>
