import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0934815d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-list-container" }
const _hoisted_2 = { class: "image-list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currImages, (image, i) => {
          return (_openBlock(), _createElementBlock("div", { key: i }, [
            _createElementVNode("img", {
              class: "image-show",
              alt: "household image",
              src: _ctx.getImageUrl(image.image)
            }, null, 8, _hoisted_3),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "top",
              transition: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(image.description ?? 'ไม่มีคำอธิบาย'), 1)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      (_ctx.chooseImageCallback)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_v_btn, {
              variant: "elevated",
              color: "primary",
              "prepend-icon": "mdi-upload",
              onClick: _ctx.chooseImageCallback
            }, {
              default: _withCtx(() => [
                _createTextVNode("อัพโหลดรูปภาพ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.chooseImageCallback && _ctx.currImages.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " ไม่มีรูปภาพ "))
      : _createCommentVNode("", true)
  ], 64))
}