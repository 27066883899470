<template>
  <div class="new-household-form-container">
    <div class="popup-head">
      <h3 v-if="!editMode">กิจกรรมให้การช่วยเหลือ</h3>
      <h3 v-else-if="currActivity">แก้ไขกิจกรรมให้การช่วยเหลือ</h3>
      <h3 v-else>เพิ่มกิจกรรมให้การช่วยเหลือ</h3>
      <div class="popup-head-close-btn-box">
        <v-btn v-if="currActivity && editMode" variant="text" icon="mdi-undo-variant" size="1.5rem" @click="editMode = false"></v-btn>
        <v-btn v-else variant="text" icon="mdi-close" size="1.5rem" @click="closeWindow(false);"></v-btn>
      </div>
    </div>
    <v-divider />
    <template v-if="editMode">
      <div class="tab-container">
        <v-tabs
          class="tab-md"
          v-model="tab"
          height="6rem"
          color="primary"
          centered
          stacked
        >
          <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="10rem">
            <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
            <p class="new-household-form-tab-title">
              {{ tabsMap[tab] }}
            </p>
          </v-tab>
        </v-tabs>
        <div class="tab-sm">
          <v-tabs
            v-model="tab"
            height="4rem"
            color="primary"
            centered
            stacked
          >
            <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="5rem">
              <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
            </v-tab>
          </v-tabs>
          <p class="new-household-form-tab-title">
            {{ tabsMap[tab] }}
          </p>
        </div>
      </div>
      <div :key="loading ? 1 : 0">
        <v-window v-model="tab" :touch="{left: () => {}, right: () => {}}">
          <v-window-item :value="tabs[0]">
            <ActivityForm
              :_id="tempId"
              :tab="tabs[0]"
              :tab-name="tabsMap[tabs[0]]"
              :activityInfo="currActivity ? {
                type: currActivity.type,
                result: currActivity.result,
                name: currActivity.name,
                detail: currActivity.detail,
                duration: currActivity.duration,
                problemsId: currActivity.problemsId
              }: undefined"
              :members="household?.members"
              :problems="household?.problems"
          />
          </v-window-item>
          <v-window-item :value="tabs[1]">
            <ChooseImageForm :_id="tempId" :tab="tabs[1]" :tab-name="tabsMap[tabs[1]]" :images="currActivity?.images" />
          </v-window-item>
          <v-window-item :value="tabs[2]">
            <HouseholdRecordStaff :_id="tempId" :tab="tabs[2]" :tab-name="tabsMap[tabs[2]]" :record-staff="currActivity?.recordStaff" />
          </v-window-item>
        </v-window>
      </div>
      <div class="new-household-form-buttons">
        <v-btn v-if="currActivity" variant="outlined" color="error" @click="deletePopup = true">
          ลบ
        </v-btn>
        <v-btn v-if="tab !== tabs.at(0)" variant="outlined" color="secondary" @click="prevTab">
          กลับ
        </v-btn>
        <v-btn v-if="tab === tabs.at(-1)" variant="outlined" color="success" @click="submitOffline" :loading="loading">
          บันทึกแบบร่าง offline
        </v-btn>
        <v-btn v-else variant="outlined" color="success" @click="nextTab">
          ถัดไป
        </v-btn>
      </div>
      <v-dialog
        v-model="valErrorsPopup"
        width="auto"
      >
        <v-card>
          <v-card-title>
            ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้
          </v-card-title>
          <div class="errors-report">
            <v-card-text v-for="err in valErrors" :key="err.title">
              <p v-if="err.error">"{{ err.tab }}"/"{{ err.title }}": "<span class="err-msg">{{ err.error }}</span>"</p>
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn color="primary" block @click="valErrorsPopup = false">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="apiErrorPopup"
        width="auto"
      >
        <v-card>
          <v-card-title>
            {{ apiError?.title }}
          </v-card-title>
          <v-card-text>
            <p>{{ apiError?.message }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="if (apiErrorCallback) {apiErrorCallback()} ; {apiErrorPopup = false}">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay class="add-form"  v-model="deletePopup" :no-click-animation="true">
        <YesNoPopup title="ลบสมาชิก" paragraph="ต้องการที่จะลบสมาชิกนี้หรือไม่" yes-color="error" :no-callback="() => {deletePopup = false}" :yes-callback="deleteOffline"/>
      </v-overlay>
    </template>
    <template v-else>
      <div class="new-household-form-wrapper">
        <div class="new-household-form">
          <div class="new-household-form-item-title">
            <p>วันเริ่มต้น</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.duration.start ? currActivity.duration.start.toLocaleString('th') : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>วันสิ้นสุด</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.duration.end ? currActivity.duration.end.toLocaleString('th') : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ชื่อกิจกรรม</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.name ? currActivity?.name : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>รายละเอียดกิจกรรม</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.detail ? currActivity?.detail : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ประเภทกิจกรรมให้การช่วยเหลือ</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.type.main ? currActivity?.type.main : '-' }}
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.TRAINING" class="new-household-form-item-title">
            <p>หัวข้อที่ได้รับการฝึกอบรม</p>&nbsp;&nbsp;
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.TRAINING" class="new-household-form-item">
            {{
              !!(currActivity?.type.detail as ActivityTypeTrainingDetail)
               ? (currActivity?.type.detail as ActivityTypeTrainingDetail).topic
               : '-'
            }}
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.TRAINING" class="new-household-form-item-title">
            <p>ผู้เข้ารับการฝึกอบรม</p>&nbsp;&nbsp;
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.TRAINING" class="new-household-form-item">
            {{
              !!(currActivity?.type.detail as ActivityTypeTrainingDetail)
                ? getFullName((currActivity?.type.detail as ActivityTypeTrainingDetail).memberId)
                : '-'
            }}
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.TRAINING" class="new-household-form-item-title">
            <p>รายได้ครัวเรือน/ปี (หลังจากฝึกอาชีพ)</p>&nbsp;&nbsp;
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.TRAINING" class="new-household-form-item">
            {{
              !!(currActivity?.type.detail as ActivityTypeTrainingDetail)
                ? (currActivity?.type.detail as ActivityTypeTrainingDetail).newIncome
                : '-'
            }} บาท
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.AID" class="new-household-form-item-title">
            <p>ประเภทการสงเคราะห์</p>&nbsp;&nbsp;
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.AID" class="new-household-form-item">
            {{
              !!(currActivity?.type.detail as ActivityTypeAidDetail)
                ? (() => {
                  const detail = currActivity.type.detail as ActivityTypeAidDetail
                  let detailDetail = ''
                  if (Array.isArray(detail.detail)) {
                    detailDetail = detail.detail.join(', ')
                  } else if (detail.detail) {
                    detailDetail = detail.detail
                  }
                return `${detail.type} ได้แก่ ${detailDetail}`
                })()
                : '-'
            }}
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.AID" class="new-household-form-item-title">
            <p>ผู้ได้รับการสงเคราะห์</p>&nbsp;&nbsp;
          </div>
          <div v-if="currActivity?.type.main === MainActivityType.AID" class="new-household-form-item">
            {{
              !!(currActivity?.type.detail as ActivityTypeAidDetail).memberId
                ? getFullName((currActivity?.type.detail as ActivityTypeAidDetail).memberId)
                : '-'
            }}
          </div>
          <div class="new-household-form-item-title">
            <p>ผลสัมฤทธิ์ของกิจกรรม</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.result ? currActivity?.result : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ปัญหาที่ได้รับการแก้ไข</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            <div v-if="!!currActivity?.problemsId">
              <div v-for="pid in Object.keys(currActivity?.problemsId)" :key="pid">
                ปัญหา: {{ getProblemTitle(pid) }}
              </div>
            </div>
          </div>
          <div class="new-household-form-item-title">
            <p>ผู้บันทึก</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.recordStaff ? currActivity?.recordStaff.staff : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>หน่วยงาน</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currActivity?.recordStaff ? currActivity?.recordStaff.organization : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>รูปภาพ</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            <ImageList v-if="!!currActivity?.images" :images="currActivity?.images" />
            <p v-else>-</p>
          </div>
        </div>
      </div>
      <div class="new-household-form-buttons">
        <v-btn variant="outlined" color="warning" @click="editMode = true">
          แก้ไข
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ActivityForm from '@/components/Form/ActivityForm.vue'
import ChooseImageForm from '@/components/Form/ChooseImageForm.vue'
import HouseholdRecordStaff from '@/components/Form/HouseholdRecordStaff.vue'
import ImageList from '@/components/Chart/ImageList.vue'
import YesNoPopup from '@/components/Popup/YesNoPopup.vue'
import { useStore } from 'vuex'
import { randHex, getHousehold, Household, Activity, saveHousehold, ActivityResult, ImageRecord, RecordStaff, ActivityTypeTrainingDetail, ActivityTypeAidDetail, MainActivityType, getDefaultDataState, getLocalObjectStateWithDataState } from '@/models/household'
import { Form } from '@/store'
import { getUserInfo } from '@/plugins/keycloak'

export default defineComponent({
  name: 'ActivityDetail',
  props: ['closeWindow', '_id', 'aid'],
  components: {
    YesNoPopup,
    ActivityForm,
    ChooseImageForm,
    HouseholdRecordStaff,
    ImageList
  },
  setup () {
    const store = useStore()
    return {
      MainActivityType,
      tempId: randHex(32),
      store
    }
  },
  data () {
    return {
      editMode: !this.aid,
      tab: 'personalInfo',
      valErrorsPopup: false,
      deletePopup: false,
      valErrors: [] as { tab: string, title: string, error: string }[],
      apiErrorPopup: false,
      apiErrorCallback: undefined as undefined | (() => void),
      apiError: undefined as { title: string, message: string } | undefined,
      tabs: ['activityInfo', 'images', 'recordStaff'],
      tabsMap: { activityInfo: 'กิจกรรม', images: 'อัพโหลดรูปภาพ', recordStaff: 'ผู้บันทึก' } as { [key: string]: string },
      tabsIconMap: { activityInfo: 'mdi-book-check', images: 'mdi-image', recordStaff: 'mdi-pencil' } as { [key: string]: string },
      loading: true,
      household: undefined as Household | undefined,
      currActivity: undefined as Activity | undefined
    }
  },
  beforeMount () {
    this.store.dispatch('resetForm', this.tempId)
  },
  mounted () {
    if (this._id) {
      this.fetchMember()
    } else {
      this.loading = false
    }
  },
  unmounted () {
    this.store.dispatch('deleteForm', this.tempId)
  },
  methods: {
    async fetchMember () {
      this.loading = true
      const household = await getHousehold(this._id)
      if (household) {
        this.household = household
        if (this.aid && this.household.activities) {
          if (this.household.activities[this.aid]) {
            this.currActivity = this.household.activities[this.aid]
          }
        }
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async submitOffline () {
      if (!this.validate()) return
      const user = await getUserInfo()
      if (!user) return

      const household = await getHousehold(this._id)
      if (!household) {
        console.log('cannot save', this._id, household, this.aid)
        return
      }

      const form: Form = this.store.getters.form(this.tempId)

      const images = form.value.images?.images as { [key: string]: ImageRecord } | undefined

      const typeMain = form.value.activityInfo.typeMain as MainActivityType
      const problemsId = form.value.activityInfo.problemsId as { [pid: string]: boolean }
      const result = form.value.activityInfo.result as ActivityResult
      const name = form.value.activityInfo.name as string
      const povertySolvingMenu = form.value.activityInfo.povertySolvingMenu as string
      const detail = form.value.activityInfo.detail as string
      const duration = form.value.activityInfo.duration as { start: Date, end: Date }
      const recordStaff = { ...form.value.recordStaff, username: user.preferred_username } as RecordStaff

      for (const [k, v] of Object.entries(problemsId)) {
        if (!v || !household.problems || !household.problems[k]) {
          console.log('cannot save, no problem', this._id, household, this.aid, problemsId, k)
          return
        }
      }

      let typeDetail: ActivityTypeTrainingDetail | ActivityTypeAidDetail | undefined
      if (typeMain === MainActivityType.TRAINING) {
        typeDetail = {
          topic: form.value.activityInfo.typeDetailTrainingTopic,
          memberId: form.value.activityInfo.typeDetailTrainingMemberId,
          newIncome: parseFloat(form.value.activityInfo.typeDetailTrainingNewIncome)
        } as ActivityTypeTrainingDetail
        if (!household.members[typeDetail.memberId]) {
          console.log('cannot save, no member', this._id, household, this.aid, typeDetail.memberId)
          return
        }
      } else if (typeMain === MainActivityType.AID) {
        typeDetail = {
          type: form.value.activityInfo.typeDetailAidType,
          memberId: form.value.activityInfo.typeDetailAidMemberId,
          detail: form.value.activityInfo.typeDetailAidDetail
        } as ActivityTypeAidDetail
        if (!household.members[typeDetail.memberId]) {
          console.log('cannot save, no member', this._id, household, this.aid, typeDetail.memberId)
          return
        }
      }

      let activity: Activity
      if (this.currActivity) {
        activity = this.currActivity
        activity.objectState.dataState = getDefaultDataState()
      } else {
        activity = {
          problemsId,
          type: { main: typeMain, detail: typeDetail },
          result,
          name,
          detail,
          duration,
          images,
          recordStaff,
          objectState: getLocalObjectStateWithDataState()
        } as Activity
      }
      if (problemsId) {
        activity.problemsId = problemsId
      }
      if (typeMain && typeDetail) {
        activity.type = { main: typeMain, detail: typeDetail }
      }
      if (result) {
        activity.result = result
      }
      if (name) {
        activity.name = name
      }
      if (povertySolvingMenu) {
        activity.povertySolvingMenu = povertySolvingMenu
      }
      if (detail) {
        activity.detail = detail
      }
      if (duration) {
        activity.duration = duration
      }
      if (images) {
        activity.images = images
      }
      if (recordStaff) {
        activity.recordStaff = recordStaff
      }

      console.log('activity', activity)
      if (!household.activities) {
        household.activities = {}
      }
      household.activities[this.aid ?? this.tempId] = activity
      if (household.problems) {
        for (const problemId of Object.keys(problemsId)) {
          let activitiesId = household.problems[problemId].activitiesId
          if (!activitiesId) {
            activitiesId = {}
          }
          activitiesId[this.aid ?? this.tempId] = true
          household.problems[problemId].activitiesId = activitiesId
        }
      }
      if (await saveHousehold(household)) {
        this.closeWindow(true)
      }
    },
    async deleteOffline () {
      this.deletePopup = false
      const household = await getHousehold(this._id)
      if (household && this.tempId && household.activities && household.activities[this.aid]) {
        if (household.problems) {
          for (const problemId of Object.keys(household.activities[this.aid].problemsId)) {
            const activitiesId = household.problems[problemId].activitiesId
            if (activitiesId) {
              delete activitiesId[this.aid]
            }
            household.problems[problemId].activitiesId = activitiesId
          }
          delete household.activities[this.aid]
          await saveHousehold(household)
        } else {
          console.log('cannot delete', this._id, household, this.aid)
        }
      }
      this.closeWindow(true)
    },
    validate () {
      const { notCalled } = this.store.getters.formCallback(this.tempId, ['activityInfo', 'recordStaff'])
      const errors = this.store.getters.formErrors(this.tempId)
      console.log('errors', errors, notCalled)
      if (!this.currActivity) {
        for (const t of notCalled) {
          errors.push({ tab: this.tabsMap[t], title: '*', error: 'จำเป็นต้องกรอก' })
        }
      }
      if (errors.length > 0) {
        this.valErrors = errors
        this.valErrorsPopup = true
        return false
      }
      return true
    },
    nextTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx + 1]
    },
    prevTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx - 1]
    },
    getFullName (memberId: string) {
      const member = this.household?.members[memberId]
      if (!member) return
      return `${member.personalInfo.prefix}${member.personalInfo.name} ${member.personalInfo.surname}`
    },
    getProblemTitle (problemId: string) {
      if (!this.household?.problems) return
      const problem = this.household?.problems[problemId]
      if (!problem) return
      return problem.problemDetail.title
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/popupForms';
@use '@/styles/Form/normalForm';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-buttons {
  flex: 1;
}

.new-household-form-item-title {
  margin-bottom: unset !important;
}

.new-household-form-item {
  margin-bottom: unset !important;
}

.new-household-form {
  gap: 1rem;
}

</style>
