<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.annualIncome }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          ref="annualIncomeRef"
          :key="annualIncomeKey"
          :model-value="annualIncome"
          :error-messages="annualIncomeErr"
          @update:modelValue="annualIncomeChanged"
          @update:focused="(val) => {if (!val) annualIncomeVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          type="number"
          :placeholder="titleMap.annualIncome"
          suffix="บาท"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.legalDept }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          ref="legalDeptRef"
          :key="legalDeptKey"
          :model-value="legalDept"
          :error-messages="legalDeptErr"
          @update:modelValue="legalDeptChanged"
          @update:focused="(val) => {if (!val) legalDeptVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          type="number"
          :placeholder="titleMap.legalDept"
          suffix="บาท"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.illegalDept }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          ref="illegalDeptRef"
          :key="illegalDeptKey"
          :model-value="illegalDept"
          :error-messages="illegalDeptErr"
          @update:modelValue="illegalDeptChanged"
          @update:focused="(val) => {if (!val) illegalDeptVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          type="number"
          :placeholder="titleMap.illegalDept"
          suffix="บาท"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.saving }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          ref="savingRef"
          :key="savingKey"
          :model-value="saving"
          :error-messages="savingErr"
          @update:modelValue="savingChanged"
          @update:focused="(val) => {if (!val) savingVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          type="number"
          :placeholder="titleMap.saving"
          suffix="บาท"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { BeingStatus, PersonalFinancial } from '@/models/household'

export default defineComponent({
  name: 'HouseholdMemberFinancialForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'full', 'financial'],
  components: {
  },
  setup () {
    const store = useStore()
    return {
      BeingStatus,
      store
    }
  },
  data () {
    const financial: PersonalFinancial | undefined = this.financial
    return {
      annualIncomeKey: Math.random(),
      legalDeptKey: Math.random(),
      illegalDeptKey: Math.random(),
      savingKey: Math.random(),
      annualIncome: ref<string | undefined>(financial?.annualIncome?.toString()),
      annualIncomeErr: ref<string | undefined>(),
      legalDept: ref<string | undefined>(financial?.legalDept?.toString()),
      legalDeptErr: ref<string | undefined>(),
      illegalDept: ref<string | undefined>(financial?.illegalDept?.toString()),
      illegalDeptErr: ref<string | undefined>(),
      saving: ref<string | undefined>(financial?.saving?.toString()),
      savingErr: ref<string | undefined>(),
      titleMap: {
        annualIncome: 'รายได้ต่อปี',
        legalDept: 'หนี้สินในระบบ',
        illegalDept: 'หนี้สินนอกระบบ',
        saving: 'เงินออม'
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    if (this.financial) {
      this.validate()
    }
  },
  methods: {
    annualIncomeVal () {
      const value = this.annualIncome ? (this.annualIncome === '.' ? 0 : parseFloat(this.annualIncome)) : undefined
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'annualIncome',
        value
      })
      const annualIncomeErr = validateEmpty(this.annualIncome, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'annualIncome',
        value: { tab: this.tabName, title: this.titleMap.annualIncome, error: annualIncomeErr }
      })
      this.annualIncomeErr = annualIncomeErr
    },
    legalDeptVal () {
      const value = this.legalDept ? (this.legalDept === '.' ? 0 : parseFloat(this.legalDept)) : undefined
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'legalDept',
        value
      })
      const legalDeptErr = validateEmpty(this.legalDept, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'legalDept',
        value: { tab: this.tabName, title: this.titleMap.legalDept, error: legalDeptErr }
      })
      this.legalDeptErr = legalDeptErr
    },
    illegalDeptVal () {
      const value = this.illegalDept ? (this.illegalDept === '.' ? 0 : parseFloat(this.illegalDept)) : undefined
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'illegalDept',
        value
      })
      const illegalDeptErr = validateEmpty(this.illegalDept, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'illegalDept',
        value: { tab: this.tabName, title: this.titleMap.illegalDept, error: illegalDeptErr }
      })
      this.illegalDeptErr = illegalDeptErr
    },
    savingVal () {
      const value = this.saving ? (this.saving === '.' ? 0 : parseFloat(this.saving)) : undefined
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'saving',
        value
      })
      const savingErr = validateEmpty(this.saving, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'saving',
        value: { tab: this.tabName, title: this.titleMap.saving, error: savingErr }
      })
      this.savingErr = savingErr
    },
    validate () {
      this.annualIncomeVal()
      this.legalDeptVal()
      this.illegalDeptVal()
      this.savingVal()
    },
    annualIncomeChanged (value: string) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.annualIncomeKey = Math.random()
        this.$nextTick(() => {
          const annualIncomeRef = this.$refs.annualIncomeRef as any
          annualIncomeRef.focus()
        })
        return
      }
      this.annualIncome = value
      this.annualIncomeVal()
    },
    legalDeptChanged (value: string) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.legalDeptKey = Math.random()
        this.$nextTick(() => {
          const legalDeptRef = this.$refs.legalDeptRef as any
          legalDeptRef.focus()
        })
        return
      }
      this.legalDept = value
      this.legalDeptVal()
    },
    illegalDeptChanged (value: string) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.illegalDeptKey = Math.random()
        this.$nextTick(() => {
          const illegalDeptRef = this.$refs.illegalDeptRef as any
          illegalDeptRef.focus()
        })
        return
      }
      this.illegalDept = value
      this.illegalDeptVal()
    },
    savingChanged (value: string) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.savingKey = Math.random()
        this.$nextTick(() => {
          const savingRef = this.$refs.savingRef as any
          savingRef.focus()
        })
        return
      }
      this.saving = value
      this.savingVal()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/normalForm';

</style>
