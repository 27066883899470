import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9a77cecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "new-household-form-container" }
const _hoisted_2 = { class: "popup-head" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "popup-head-close-btn-box" }
const _hoisted_6 = { class: "tab-container" }
const _hoisted_7 = { class: "new-household-form-tab-title" }
const _hoisted_8 = { class: "tab-sm" }
const _hoisted_9 = { class: "new-household-form-tab-title" }
const _hoisted_10 = { class: "new-household-form-buttons" }
const _hoisted_11 = { class: "errors-report" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "err-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_HouseholdMemberForm = _resolveComponent("HouseholdMemberForm")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_HouseholdMemberFinancialForm = _resolveComponent("HouseholdMemberFinancialForm")!
  const _component_HouseholdMemberWelfareForm = _resolveComponent("HouseholdMemberWelfareForm")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_YesNoPopup = _resolveComponent("YesNoPopup")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mid)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, "แก้ไขสมาชิก"))
        : (_openBlock(), _createElementBlock("h3", _hoisted_4, "เพิ่มสมาชิก")),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_btn, {
          variant: "text",
          icon: "mdi-close",
          size: "1.5rem",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeWindow(false)))
        })
      ])
    ]),
    _createVNode(_component_v_divider),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_v_tabs, {
        class: "tab-md",
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
        height: "6rem",
        color: "primary",
        centered: "",
        stacked: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: tab,
              value: tab,
              width: "10rem"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { size: "3rem" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.tabsIconMap[tab]), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.tabsMap[tab]), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
          height: "4rem",
          color: "primary",
          centered: "",
          stacked: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
              return (_openBlock(), _createBlock(_component_v_tab, {
                key: tab,
                value: tab,
                width: "5rem"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { size: "3rem" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.tabsIconMap[tab]), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.tabsMap[_ctx.tab]), 1)
      ])
    ]),
    (_openBlock(), _createElementBlock("div", {
      key: _ctx.loading ? 1 : 0
    }, [
      _createVNode(_component_v_window, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tab) = $event)),
        touch: {left: () => {}, right: () => {}}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_window_item, {
            value: _ctx.tabs[0]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HouseholdMemberForm, {
                _id: _ctx.tempId,
                tab: _ctx.tabs[0],
                "tab-name": _ctx.tabsMap[_ctx.tabs[0]],
                full: true,
                "personal-info": _ctx.member?.personalInfo
              }, null, 8, ["_id", "tab", "tab-name", "personal-info"])
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_v_window_item, {
            value: _ctx.tabs[1]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HouseholdMemberFinancialForm, {
                _id: _ctx.tempId,
                tab: _ctx.tabs[1],
                "tab-name": _ctx.tabsMap[_ctx.tabs[1]],
                full: true,
                financial: _ctx.member?.financial
              }, null, 8, ["_id", "tab", "tab-name", "financial"])
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_v_window_item, {
            value: _ctx.tabs[2]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HouseholdMemberWelfareForm, {
                _id: _ctx.tempId,
                tab: _ctx.tabs[2],
                "tab-name": _ctx.tabsMap[_ctx.tabs[2]],
                full: true,
                welfare: _ctx.member?.welfare
              }, null, 8, ["_id", "tab", "tab-name", "welfare"])
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.mid)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            variant: "outlined",
            color: "error",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deletePopup = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" ลบ ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.tab !== _ctx.tabs.at(0))
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 1,
            variant: "outlined",
            color: "secondary",
            onClick: _ctx.prevTab
          }, {
            default: _withCtx(() => [
              _createTextVNode(" กลับ ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.tab === _ctx.tabs.at(-1))
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 2,
            variant: "outlined",
            color: "success",
            onClick: _ctx.submitOffline,
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(" บันทึกแบบร่าง offline ")
            ]),
            _: 1
          }, 8, ["onClick", "loading"]))
        : (_openBlock(), _createBlock(_component_v_btn, {
            key: 3,
            variant: "outlined",
            color: "success",
            onClick: _ctx.nextTab
          }, {
            default: _withCtx(() => [
              _createTextVNode(" ถัดไป ")
            ]),
            _: 1
          }, 8, ["onClick"]))
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.valErrorsPopup,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.valErrorsPopup) = $event)),
      width: "auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(" ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้ ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valErrors, (err) => {
                return (_openBlock(), _createBlock(_component_v_card_text, {
                  key: err.title
                }, {
                  default: _withCtx(() => [
                    (err.error)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                          _createTextVNode("\"" + _toDisplayString(err.tab) + "\"/\"" + _toDisplayString(err.title) + "\": \"", 1),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(err.error), 1),
                          _createTextVNode("\"")
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "primary",
                  block: "",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.valErrorsPopup = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("ปิด")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.apiErrorPopup,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.apiErrorPopup) = $event)),
      width: "auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.apiError?.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.apiError?.message), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "primary",
                  block: "",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => {if (_ctx.apiErrorCallback) {_ctx.apiErrorCallback()} ; {_ctx.apiErrorPopup = false}})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("ปิด")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_overlay, {
      class: "add-form",
      modelValue: _ctx.deletePopup,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.deletePopup) = $event)),
      "no-click-animation": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_YesNoPopup, {
          title: "ลบสมาชิก",
          paragraph: "ต้องการที่จะลบสมาชิกนี้หรือไม่",
          "yes-color": "error",
          "no-callback": () => {_ctx.deletePopup = false},
          "yes-callback": _ctx.deleteOffline
        }, null, 8, ["no-callback", "yes-callback"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}