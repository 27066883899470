import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { _keycloak } from '@/plugins/keycloak'
import HouseholdView from '@/views/HouseholdView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      isAuthenticated: true,
      allowRoles: ['offline_access', 'uma_authorization']
    },
    component: HomeView
  },
  {
    path: '/household/:id',
    name: 'household',
    meta: {
      isAuthenticated: true,
      allowRoles: ['offline_access', 'uma_authorization']
    },
    component: HouseholdView
  },
  {
    path: '/about',
    name: 'about',
    // meta: {
    //   isAuthenticated: false
    // },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: () => import('../views/NotFoundView.vue')
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    // meta: {
    //   isAuthenticated: false
    // },
    component: () => import('../views/UnauthorizedView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.meta.isAuthenticated) {
//     // Get the actual url of the app, it's needed for Keycloak
//     const basePath = window.location.toString()
//     const allowRoles: string[] = Array.isArray(to.meta.allowRoles) ? to.meta.allowRoles : []
//     console.log('authenticated', _keycloak.authenticated)

//     if (!_keycloak.authenticated) {
//       // The page is protected and the user is not authenticated. Force a login.
//       console.log('relogin')
//       // _keycloak.login() // ({ redirectUri: basePath.slice(0, -1) + to.path })
//     } else if (allowRoles.reduce((prev, curr) => prev || _keycloak.hasResourceRole(curr), false)) {
//       // The user was authenticated, and has the app role
//       _keycloak.updateToken(70)
//         .then(() => {
//           console.log('update token')
//           // next()
//         })
//         .catch(err => {
//           console.error(err)
//         })
//     } else {
//       // The user was authenticated, but did not have the correct role
//       // Redirect to an error page
//       // next({ name: 'Unauthorized' })
//       console.log('unauthorized')
//     }
//   } else {
//     console.log('no authentication')
//     // This page did not require authentication
//     // next()
//   }
//   next()
// })

export default router
