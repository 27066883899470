import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-58624a62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mpi-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass('mpi-icon' + (_ctx.mpiHighlight && _ctx.mpiHighlight[i] ? ' mpi-highlight' : '')),
        key: icon
      }, [
        _createVNode(_component_v_icon, { class: "mpi-icon-icon" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(icon), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "top",
          "open-delay": 200,
          transition: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.mpiNames[i]), 1)
          ]),
          _: 2
        }, 1024)
      ], 2))
    }), 128))
  ]))
}