<template>
  <div class="image-list-container">
    <div class="image-list">
      <div v-for="(image, i) in currImages" :key="i">
        <img class="image-show" alt="household image" :src="getImageUrl(image.image)">
        <v-tooltip
          activator="parent"
          location="top"
          :transition="false"
        >{{ image.description ?? 'ไม่มีคำอธิบาย' }}</v-tooltip>
      </div>
    </div>
    <div v-if="chooseImageCallback">
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-upload" @click="chooseImageCallback">อัพโหลดรูปภาพ</v-btn>
    </div>
  </div>
  <div v-if="!chooseImageCallback && currImages.length === 0">
    ไม่มีรูปภาพ
  </div>
</template>

<script lang="ts">
import { ImageRecord } from '@/models/household'
import { getImageUrl } from '@/utils/file-utils'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImageList',
  props: ['images', 'id', 'chooseImageCallback'],
  components: {
  },
  data () {
    let currImages = [] as ImageRecord[]
    if (this.images) {
      currImages = this.images
    }
    return {
      getImageUrl,
      currImages
    }
  },
  watch: {
    images: {
      immediate: true,
      handler (imgs) {
        if (imgs) {
          this.currImages = imgs
        }
      }
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.image-list-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.image-show {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 4rem;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

</style>
