<template>
  <div class="household-view" v-if="user">
    <div class="household-control-panel">
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-upload" @click="submitPopup = true" :disabled="submitLoading">บันทึกเข้าสู่ระบบ</v-btn>
      <v-btn variant="outlined" color="error" prepend-icon="mdi-delete" @click="deletePopup = true">ลบ</v-btn>
      <div class="back-btn">
        <v-btn variant="text" @click="$router.push('/')">กลับ</v-btn>
      </div>
    </div>
    <v-expansion-panels variant="default" multiple :model-value="[1, 2, 3, 4, 5]">
      <v-expansion-panel :value="1" bg-color="background">
        <v-expansion-panel-title expand-icon="mdi-menu-down" color="primary">
          <h3>ข้อมูลครัวเรือน</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div :key="householdInfoKey" class="inner-text">
            <HouseholdInfo :id="id" :update-callback="householdUpdate"/>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :value="2" bg-color="background">
        <v-expansion-panel-title expand-icon="mdi-menu-down" color="primary">
          <h3>สมาชิกครัวเรือน</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="inner-text">
            <MemberInfo :id="id" :update-callback="memberUpdate"/>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :value="3" bg-color="background">
        <v-expansion-panel-title expand-icon="mdi-menu-down" color="primary">
          <h3>สรุปคุณภาพชีวิตของครัวเรือน</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="inner-text">
            <MpiInfo :id="id" :update-callback="mpiUpdate"/>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :value="4" bg-color="background">
        <v-expansion-panel-title expand-icon="mdi-menu-down" color="primary">
          <h3>ปัญหา/ความต้องการ</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div :key="problemInfoKey" class="inner-text">
            <ProblemInfo :id="id" :update-callback="problemUpdate" />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel :value="5" bg-color="background">
        <v-expansion-panel-title expand-icon="mdi-menu-down" color="primary">
          <h3>กิจกรรมให้การช่วยเหลือ</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div :key="activityInfoKey" class="inner-text">
            <ActivityInfo :id="id" :update-callback="activityUpdate" />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="submitErrorsPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          เกิดข้อผิดพลาดต่อไปนี้
        </v-card-title>
        <div class="errors-report">
          <v-card-text v-for="err in submitErrors" :key="err">
            <span class="err-msg">{{ err }}</span>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn color="primary" block @click="submitErrorsPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="submitSuccessPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          บันทึกครัวเรือนเข้าสู่ระบบสำเร็จ
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" block @click="submitSuccessPopup = false; backToMenu();">กลับสู่หน้าหลัก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-overlay class="add-form"  v-model="deletePopup" :no-click-animation="true">
    <YesNoPopup title="ลบครัวเรือน" paragraph="ต้องการที่จะลบครัวเรือนนี้หรือไม่" yes-color="error" :no-callback="() => {deletePopup = false}" :yes-callback="deleteHousehold"/>
  </v-overlay>
  <v-overlay class="add-form"  v-model="submitPopup" :no-click-animation="true">
    <YesNoPopup title="บันทึกครัวเรือน" paragraph="ต้องการที่จะบันทึกครัวเรือนนี้เข้าสู่ระบบหรือไม่" yes-color="success" :no-callback="() => {submitPopup = false}" :yes-callback="submit"/>
  </v-overlay>
</template>

<script lang="ts">
import { Household, deleteHousehold, getHousehold } from '@/models/household'
import { defineComponent } from 'vue'
import HouseholdInfo from '@/components/HouseholdInfo/index.vue'
import MemberInfo from '@/components/MemberInfo/index.vue'
import MpiInfo from '@/components/MpiInfo/index.vue'
import ProblemInfo from '@/components/ProblemInfo/index.vue'
import ActivityInfo from '@/components/ActivityInfo/index.vue'
import { KeycloakTokenParsed } from 'keycloak-js'
import { getUserInfo } from '@/plugins/keycloak'
import { submitActivities, submitHousehold, submitHouseholdImages, submitImage, submitMembers, submitProblems, validateNewHousehold } from '@/models/household-online/index'
import YesNoPopup from '@/components/Popup/YesNoPopup.vue'
import { dataURItoBlob } from '@/utils/file-utils'

export default defineComponent({
  name: 'HouseholdView',
  components: {
    HouseholdInfo,
    MemberInfo,
    MpiInfo,
    ProblemInfo,
    ActivityInfo,
    YesNoPopup
  },
  data () {
    const id = (this.$route.params.id ?? '') as string
    return {
      submitLoading: false,
      submitPopup: false,
      deletePopup: false,
      submitSuccessPopup: false,
      user: undefined as KeycloakTokenParsed | undefined,
      id,
      household: undefined as Household | undefined,
      householdInfoKey: Math.random(),
      problemInfoKey: Math.random(),
      activityInfoKey: Math.random(),
      submitErrors: [] as string[],
      submitErrorsPopup: false
    }
  },
  mounted () {
    this.getUser()
    this.getHousehold()
  },
  methods: {
    async getUser () {
      this.user = await getUserInfo()
    },
    async getHousehold () {
      const household = await getHousehold(this.id)
      if (!household) {
        this.$router.push('/notfound')
      }
      this.household = household
    },
    async submit () {
      this.submitLoading = true
      if (!navigator.onLine) {
        this.submitErrors = ['ไม่มีการเชื่อมต่ออินเตอร์เน็ต']
        this.submitErrorsPopup = true
        this.submitLoading = false
        this.submitPopup = false
        return
      }
      this.getHousehold()
      if (!this.household) return
      const res = await validateNewHousehold(this.household)
      console.log('res', res)
      if (res) {
        this.submitErrors = res
        this.submitErrorsPopup = true
        this.submitPopup = false
      } else {
        const res = await submitHousehold(this.household)
        const resJson = await res.json()
        console.log(res.status)
        console.log(resJson)

        if (res.status < 200 || res.status >= 300) {
          this.submitPopup = false
          return
        }

        const _id: string = resJson.lastErrorObject.upserted
        const imgFolder = `${this.household.address.villageId}${_id}`

        if (this.household.images) {
          await Promise.all(
            Object.entries(this.household.images).map(async ([key, img]) => {
              const filename = `${key}.${img.extension}`
              const imgBlob = dataURItoBlob(img.image)
              console.log('filename', filename, imgFolder)
              return submitImage(imgBlob, `houses/${imgFolder}/`, filename)
            })
          )
          await submitHouseholdImages(this.household, _id, imgFolder)
        }

        const memberResList = await submitMembers(_id, this.household)
        const memberResListTemp = await Promise.all(memberResList.map(async res => ({ status: res.status, json: (await res.json()) as { n: number, updatedExisting: boolean, upserted: string } })))
        console.log('memberResListTemp', memberResListTemp)
        const memberKeys = Object.keys(this.household.members)
        memberResListTemp.forEach(({ json: { upserted } }, i) => {
          if (this.household) {
            this.household.members[memberKeys[i]].realId = upserted
          }
        })

        console.log('this.household.members', this.household.members)

        if (this.household.problems && Object.keys(this.household.problems).length > 0) {
          const problemResList = await submitProblems(_id, this.household, imgFolder)
          console.log('problemResList', problemResList)
          if (!problemResList) {
            this.submitPopup = false
            return
          }
          console.log('problemResList', problemResList ? await Promise.all(problemResList[1].map(async res => ({ status: res.status, json: await res.text() }))) : undefined)

          const idMapInv = Object.entries(problemResList[2]).reduce((cum, [k, v]) => { cum[v] = k; return cum }, {} as { [key: string]: string })
          const problemKeys = this.household.problems ? Object.keys(this.household.problems) : undefined
          console.log('problemKeys', problemKeys)
          if (!problemKeys) {
            this.submitPopup = false
            return
          }

          problemKeys.forEach((k) => {
            if (this.household?.problems && problemResList[2]) {
              this.household.problems[k].realId = idMapInv[k]
            }
          })

          console.log('this.household.problems', this.household.problems)

          if (this.household.activities && Object.keys(this.household.activities).length > 0) {
            const activityResList = await submitActivities(_id, this.household, imgFolder)
            console.log('activityResList', activityResList)
            if (!activityResList) {
              this.submitPopup = false
              return
            }
            console.log('activityResList', activityResList ? await Promise.all(activityResList[1].map(async res => ({ status: res.status, json: await res.text() }))) : undefined)
          }
        }

        this.submitPopup = false
        this.deleteHousehold(false)
        this.submitSuccessPopup = true
      }
      this.submitLoading = false
    },
    memberUpdate () {
      this.getHousehold()
      this.householdInfoKey = Math.random()
    },
    mpiUpdate () {
      this.getHousehold()
      this.householdInfoKey = Math.random()
    },
    householdUpdate () {
      this.getHousehold()
      this.householdInfoKey = Math.random()
    },
    problemUpdate () {
      this.getHousehold()
      this.activityInfoKey = Math.random()
    },
    activityUpdate () {
      this.getHousehold()
      this.problemInfoKey = Math.random()
    },
    deleteHousehold (back = true) {
      this.deletePopup = false
      deleteHousehold(this.id)
      if (back) {
        this.backToMenu()
      }
    },
    backToMenu () {
      this.$router.push('/')
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/popupForms';

.household-control-panel {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
  flex-direction: row-reverse;
  gap: 0.5rem;
  .back-btn {
    flex: 1;
  }
}

.household-view {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.card-holder {
  // margin: 1rem;
  // border: 1px #000000 solid;
  // flex-direction;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.card-container {
  flex: 1;
  outline: 1px solid variables.$main-color;
  border-radius: 10px 10px 0px 0px;
}

.card-title {
  background-color: variables.$main-color;
  color: variables.$text-color-main-contrast;
  width: 100%;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  text-justify: center;
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
}

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
