import { ApiUtils } from '@/utils/api-utils'

const addressApi = new ApiUtils(process.env.VUE_APP_ADDRESS_API_URL)

export interface AddressInfo {
  province: string,
  district: string,
  subdistrict: string,
  village: string,
  id: {
    province: string | number,
    district: string | number,
    subdistrict: string | number,
    village: string | number
  }
}

export interface SubdistrictInfo {
  subdistrictId: string | number,
  subdistrictName: string,
  villages: { [villageId: string | number]: AddressInfo }
}

export interface DistrictInfo {
  districtId: string | number,
  districtName: string,
  subdistricts: { [subdistrict: string | number]: SubdistrictInfo }
}

export interface ProvinceInfo {
  provinceId: string | number,
  provinceName: string,
  districts: { [district: string | number]: DistrictInfo }
}

interface SubdistrictResponseItem {
  'amphur_name': string,
  'province_name': string,
  'tumbol_name': string,
  'village_name': string,
  _id: {
    'province_ID': string | number,
    'amphur_ID': string | number,
    'tumbol_ID': string | number,
    'village_ID': string | number
  }
}

interface DistrictResponseItem {
  'amphur_name': string,
  'province_name': string,
  'tumbol_name': string,
  _id: {
    'province_ID': string | number,
    'amphur_ID': string | number,
    'tumbol_ID': string | number
  }
}

interface ProvinceResponseItem {
  'amphur_name': string,
  'province_name': string,
  _id: {
    'province_ID': string | number,
    'amphur_ID': string | number
  }
}

export async function fetchSubdistrictInfo (subdistrictId: string | number): Promise<SubdistrictInfo> {
  const subdistrictDetail: SubdistrictResponseItem[] = await (
    await addressApi.fetch(`/refadms?tam=${subdistrictId}`)
  ).json()
  const villages = subdistrictDetail.reduce((cum, e) => {
    cum[e._id.village_ID] = {
      province: e.province_name,
      district: e.amphur_name,
      subdistrict: e.tumbol_name,
      village: e.village_name,
      id: {
        province: e._id.province_ID,
        district: e._id.amphur_ID,
        subdistrict: e._id.tumbol_ID,
        village: e._id.village_ID
      }
    }
    return cum
  }, {} as { [villageId: string | number]: AddressInfo })
  return { subdistrictId, subdistrictName: subdistrictDetail[0].tumbol_name, villages }
}

export async function fetchDistrictInfo (districtId: string | number): Promise<DistrictInfo> {
  const districtDetail: DistrictResponseItem[] = await (
    await addressApi.fetch(`/refadms?amp=${districtId}`)
  ).json()
  const subdistrictsResponse = await Promise.all(districtDetail.map(async (subdistrict) => {
    return fetchSubdistrictInfo(subdistrict._id.tumbol_ID)
  }))
  const subdistricts = subdistrictsResponse.reduce((cum, subdistrict) => {
    cum[subdistrict.subdistrictId] = subdistrict
    return cum
  }, {} as { [subdistrict: string | number]: SubdistrictInfo })

  return { districtId, districtName: districtDetail[0].amphur_name, subdistricts }
}

export async function fetchProvinceInfo (provinceId: string | number) {
  console.log('provinceId', provinceId)
  console.log('API_URL', process.env.VUE_APP_ADDRESS_API_URL)

  const provinceDetail: ProvinceResponseItem[] = await (
    await addressApi.fetch(`/refadms?prov=${provinceId}`)
  ).json()
  console.log('provinceDetail', provinceDetail)
  const districtsResponse = await Promise.all(provinceDetail.map(async (district) => {
    return fetchDistrictInfo(district._id.amphur_ID)
  }))
  const districts = districtsResponse.reduce((cum, district) => {
    cum[district.districtId] = district
    return cum
  }, {} as { [district: string | number]: DistrictInfo })
  const provinceInfo: ProvinceInfo = { provinceId, provinceName: provinceDetail[0].province_name, districts }
  console.log('provinceInfo', provinceInfo)
  return provinceInfo
}

function aggVillage (provinceInfo: ProvinceInfo) {
  const villages: { [villageId: string | number]: AddressInfo } = {}
  for (const district of Object.values(provinceInfo.districts)) {
    for (const subdistrict of Object.values(district.subdistricts)) {
      for (const village of Object.values(subdistrict.villages)) {
        villages[village.id.village] = village
      }
    }
  }
  return villages
}

let provinceInfo: ProvinceInfo
let addressInfoMap: { [villageId: string | number]: AddressInfo }

export async function fetchAndStoreProvinceInfo (provinceId: string | number) {
  addressApi.getToken()
  const tempProvinceInfo = localStorage.getItem('provinceTemp')
  let _provinceInfo: ProvinceInfo
  if (tempProvinceInfo) {
    _provinceInfo = JSON.parse(tempProvinceInfo)
    if (_provinceInfo.provinceId.toString() !== provinceId.toString()) {
      _provinceInfo = await fetchProvinceInfo(provinceId)
      localStorage.setItem('provinceTemp', JSON.stringify(_provinceInfo))
    }
  } else if (!navigator.onLine) {
    return
  } else {
    _provinceInfo = await fetchProvinceInfo(provinceId)
    localStorage.setItem('provinceTemp', JSON.stringify(_provinceInfo))
  }
  provinceInfo = _provinceInfo
  addressInfoMap = aggVillage(_provinceInfo)
  console.log('provinceInfo', provinceInfo)
  console.log('addressInfoMap', addressInfoMap)
}

export function getProvinceInfo () {
  return provinceInfo
}

export function getAddressInfoMap () {
  return addressInfoMap
}

export function getAddressVillage (villageId: string | number) {
  return addressInfoMap[parseInt(villageId.toString())]
}

export function getProvinceList (): {id: string | number, name: string}[] {
  return [{ id: parseInt(provinceInfo.provinceId.toString()), name: provinceInfo.provinceName }]
}

export function getDistrictList (): {id: string | number, name: string}[] {
  return Object.values(provinceInfo.districts).map((e) => ({
    id: e.districtId,
    name: e.districtName
  }))
}

export function getSubdistrictList (districtId: string | number): {id: string | number, name: string}[] {
  return Object.values(provinceInfo.districts[districtId].subdistricts).map((e) => ({
    id: e.subdistrictId,
    name: e.subdistrictName
  }))
}

export function getVillageList (districtId: string | number, subdistrictId: string | number): {id: string | number, name: string}[] {
  const villages = Object.values(provinceInfo.districts[districtId].subdistricts[subdistrictId].villages).map((e) => ({
    id: e.id.village,
    name: e.village
  }))
  villages.sort((a, b) => {
    const aid = parseInt(a.id.toString())
    const bid = parseInt(b.id.toString())
    return aid > bid ? 1 : (aid < bid ? -1 : 0)
  })
  return villages
}
