<template>
  <div class="household-table-container">
    <div class="household-top-menu-right">
      <v-btn variant="elevated" color="primary" @click="newMenu = true">
        เพิ่มครัวเรือน
      </v-btn>
      <v-overlay class="household-add-form"  v-model="newMenu" :persistent="true" :no-click-animation="true">
        <NewHouseholdForm :closeWindow="closeWindow"/>
      </v-overlay>
    </div>
    <div v-if="households.length > 0" class="household-card-holder">
      <HouseholdCard v-for="household in households" :key="household.id" :household="household"/>
    </div>
    <div v-else class="household-card-holder no-card">
      <p>ไม่มีครัวเรือน</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NewHouseholdForm from '@/components/Form/NewHouseholdForm.vue'
import { Household, getHouseholds, HouseholdMemberRole } from '@/models/household'
import HouseholdCard from './HouseholdCard.vue'

export default defineComponent({
  name: 'HouseholdTable',
  components: {
    NewHouseholdForm,
    HouseholdCard
  },
  methods: {
    async fetchHousehold () {
      const households = await getHouseholds()
      console.log('households', households)
      if (households) {
        this.households = households
      }
    },
    closeWindow (isUpdate = false) {
      this.newMenu = false
      if (isUpdate) {
        this.fetchHousehold()
      }
    }
  },
  data () {
    this.fetchHousehold()
    return {
      HouseholdMemberRole,
      newMenu: false,
      households: [] as Household[]
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.household-add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.household-top-menu-right {
  grid-column: span 12;
  grid: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.household-table-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem
}

.household-card-holder {
  grid: 1;
  grid-column: span 12;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
  gap: 2rem;
  border-radius: 10px;
  max-width: 95vw;
}

.no-card {
  align-items: center;
  justify-content: center;
  height: calc(50vh - 20rem);
}

</style>
