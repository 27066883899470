<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.date }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <div class="date-picker-container">
          <VueDatePicker
            v-model="date"
            @update:model-value="dateVal"
            locale="th"
            :format-locale="th"
            format="dd/MM/y HH:mm"
            select-text="เลือก"
            cancel-text="ยกเลิก"
            :disabled="currDisabled"
          ></VueDatePicker>
        </div>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.staff }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div :key="staffKey" class="new-household-form-item">
        <v-text-field
          v-model="staff"
          :error-messages="staffErr"
          @update:modelValue="staffVal"
          @update:focused="(val) => {if (!val) staffVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.staff"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.organization }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div :key="organizationKey" class="new-household-form-item">
        <v-text-field
          v-model="organization"
          :error-messages="organizationErr"
          @update:modelValue="organizationVal"
          @update:focused="(val) => {if (!val) organizationVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.organization"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.coordinator }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="coordinator"
          :error-messages="coordinatorErr"
          @update:modelValue="coordinatorVal()"
          @update:focused="(val) => {if (!val) coordinatorVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.coordinator}`"
          :no-data-text="`ไม่พบ${titleMap.coordinator}`"
          :disabled="currDisabled"
          item-title="name"
          item-value="id"
          :items="memberList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.phoneNumber }}</p>&nbsp;
        &nbsp;
      </div>
      <div class="new-household-form-item">
        <v-text-field
          ref="phoneNumberRef"
          :key="phoneNumberKey"
          :model-value="phoneNumber"
          :error-messages="phoneNumberErr"
          @update:modelValue="phoneNumberChanged"
          @update:focused="(val) => {if (!val) phoneNumberVal()}"
          counter
          maxlength="10"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.phoneNumber"
          :disabled="currDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty, validateLength } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getUserInfo } from '@/plugins/keycloak'
import { HouseholdMember, ProblemRecord } from '@/models/household'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { th } from 'date-fns/locale'

export default defineComponent({
  name: 'ProblemRecordForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'disabled', 'record', 'members'],
  components: {
    VueDatePicker
  },
  setup () {
    const store = useStore()
    return {
      th,
      store
    }
  },
  data () {
    const record: ProblemRecord | undefined = this.record
    const memberList: { id: string, name: string }[] = this.members
      ? Object.entries(this.members as { [key: string]: HouseholdMember }).map(([k, e]) => ({
        id: k,
        name: `${e.personalInfo.prefix}${e.personalInfo.name} ${e.personalInfo.surname}`
      }))
      : []
    return {
      phoneNumberKey: Math.random(),
      staffKey: Math.random(),
      organizationKey: Math.random(),
      currDisabled: this.disabled,
      date: ref<Date | undefined>(record?.date ?? (new Date())),
      dateErr: ref<string | undefined>(),
      staff: ref<string | undefined>(record?.recordStaff.staff),
      staffErr: ref<string | undefined>(),
      organization: ref<string | undefined>(record?.recordStaff.organization),
      organizationErr: ref<string | undefined>(),
      coordinator: ref<string | undefined>(record && this.members && this.members[record.coordinator] ? record.coordinator : undefined),
      coordinatorErr: ref<string | undefined>(),
      phoneNumber: ref<string | undefined>(record?.phoneNumber),
      phoneNumberErr: ref<string | undefined>(),
      titleMap: {
        date: 'วันที่พบปัญหา',
        staff: 'ชื่อ-นามสกุลผู้บันทึกข้อมูล',
        organization: 'หน่วยงานผู้บันทึกข้อมูล',
        coordinator: 'ผู้ประสานงานครัวเรือน',
        phoneNumber: 'เบอร์โทรศัพท์'
      },
      memberList
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (d) {
        this.currDisabled = d
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    this.fetchUser()
  },
  methods: {
    async fetchUser () {
      const userInfo = await getUserInfo()
      if (userInfo?.position) {
        if (!this.staff) this.staff = userInfo.position
        if (!this.organization) this.organization = userInfo.position
        this.staffKey = Math.random()
        this.organizationKey = Math.random()
        this.validate()
      }
    },
    dateVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'date',
        value: this.date
      })
      this.dateErr = validateEmpty(this.date, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'date',
        value: { tab: this.tabName, title: this.titleMap.date, error: this.dateErr }
      })
    },
    staffVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'staff',
        value: this.staff
      })
      this.staffErr = validateEmpty(this.staff, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'staff',
        value: { tab: this.tabName, title: this.titleMap.staff, error: this.staffErr }
      })
    },
    organizationVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'organization',
        value: this.organization
      })
      this.organizationErr = validateEmpty(this.organization, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'organization',
        value: { tab: this.tabName, title: this.titleMap.organization, error: this.organizationErr }
      })
    },
    coordinatorVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'coordinator',
        value: this.coordinator
      })
    },
    phoneNumberVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        phoneNumber: this.phoneNumber,
        mode: 'value',
        tab: this.tab,
        key: 'phoneNumber',
        value: this.phoneNumber
      })
      const phoneNumberErr = validateLength(this.phoneNumber, undefined, 10, 10, 'กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก')
      this.store.dispatch('setFormValue', {
        id: this._id,
        phoneNumber: this.phoneNumber,
        mode: 'error',
        tab: this.tab,
        key: 'phoneNumber',
        value: { tab: this.tabName, title: this.titleMap.phoneNumber, error: phoneNumberErr }
      })
      this.phoneNumberErr = phoneNumberErr
    },
    validate () {
      this.dateVal()
      this.staffVal()
      this.organizationVal()
      this.coordinatorVal()
      this.phoneNumberVal()
    },
    phoneNumberChanged (value: string) {
      if (value && value !== '' && !/^\d+$/.test(value)) {
        this.phoneNumberKey = Math.random()
        this.$nextTick(() => {
          const phoneNumberRef = this.$refs.phoneNumberRef as any
          phoneNumberRef.focus()
        })
        return
      }
      this.phoneNumber = value
      this.phoneNumberVal()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/normalForm';

.new-household-form {
  min-height: 30rem;
}

</style>
