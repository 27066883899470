import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-129d0006"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-panel panel-desktop" }
const _hoisted_2 = { class: "member-panel panel-mobile" }
const _hoisted_3 = {
  key: 0,
  class: "member-list"
}
const _hoisted_4 = { class: "mpi-view-group-item-value" }
const _hoisted_5 = { class: "inner-text" }
const _hoisted_6 = { class: "mpi-view-group inner-text" }
const _hoisted_7 = { class: "mpi-view-group-item" }
const _hoisted_8 = { class: "mpi-view-group-item-value" }
const _hoisted_9 = { class: "mpi-view-group-item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_EditMpiForm = _resolveComponent("EditMpiForm")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("div", {
      key: _ctx.loading ? 1 : 0,
      class: "card-body"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          variant: "elevated",
          color: "primary",
          "prepend-icon": "mdi-pencil",
          onClick: _ctx.openEditMpi
        }, {
          default: _withCtx(() => [
            _createTextVNode("แก้ไข")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_btn, {
          variant: "text",
          color: "primary",
          icon: "mdi-pencil",
          density: "comfortable",
          onClick: _ctx.openEditMpi
        }, null, 8, ["onClick"])
      ]),
      (_ctx.mpiValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_v_expansion_panels, { multiple: "" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mpiTemplate, (group) => {
                  return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                    "bg-color": "background",
                    key: group.name
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { "expand-icon": "mdi-menu-down" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            (_ctx.mpiValue[group.name].reduce((cum, e, j) => (cum || e), false))
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 0,
                                  color: "error"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("mdi-close-box")
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 1,
                                  color: "success"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("mdi-checkbox-marked")
                                  ]),
                                  _: 1
                                }))
                          ]),
                          _createElementVNode("h4", _hoisted_5, _toDisplayString(group.name), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item, j) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "mpi-view-group-item-wrap",
                                key: item
                              }, [
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("div", _hoisted_8, [
                                    (_ctx.mpiValue[group.name][j])
                                      ? (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 0,
                                          color: "error"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-close-box")
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 1,
                                          color: "success"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-checkbox-marked")
                                          ]),
                                          _: 1
                                        }))
                                  ]),
                                  _createElementVNode("div", _hoisted_9, _toDisplayString(item), 1)
                                ])
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])),
    _createVNode(_component_v_overlay, {
      class: "add-form",
      modelValue: _ctx.editMpi,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editMpi) = $event)),
      persistent: true,
      "no-click-animation": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EditMpiForm, {
          id: _ctx.id,
          closeWindow: _ctx.closeEditMpi
        }, null, 8, ["id", "closeWindow"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}