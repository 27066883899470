import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a8c6880"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "new-household-form-container popup-width" }
const _hoisted_2 = { class: "popup-head" }
const _hoisted_3 = { class: "popup-head-close-btn-box" }
const _hoisted_4 = { class: "paragraph-text" }
const _hoisted_5 = { class: "new-household-form-buttons" }
const _hoisted_6 = {
  key: 0,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_btn, {
          variant: "text",
          icon: "mdi-close",
          size: "1.5rem",
          onClick: _ctx.noCallback
        }, null, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_v_divider),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.paragraph), 1),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_btn, {
        variant: "outlined",
        color: _ctx.noColor ?? undefined,
        onClick: _ctx.noCallback,
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.noText ?? 'ไม่ใช่'), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick", "disabled"]),
      _createVNode(_component_v_btn, {
        variant: "elevated",
        color: _ctx.yesColor ?? 'success',
        onClick: _ctx.yesCallbackFn,
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_v_progress_circular, {
                  indeterminate: "",
                  size: "20",
                  width: "2"
                })
              ]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.yesText ?? 'ใช่'), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick", "disabled"])
    ])
  ]))
}