import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b346b88c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HouseholdTable = _resolveComponent("HouseholdTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user)
      ? (_openBlock(), _createBlock(_component_HouseholdTable, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}