import { createStore } from 'vuex'

export interface Form {
  value: { [tab: string]: { [key: string]: any } };
  error: { [tab: string]: { [key: string]: { tab: string, title: string, error: string } } };
  callback: { [tab: string]: () => void | undefined };
}

export default createStore({
  state: {
    forms: {} as { [key: string]: Form }
  },
  getters: {
    form (state) {
      return (id: string) => state.forms[id]
    },
    formErrors (state) {
      return (id: string) => {
        if (!state.forms[id]) {
          return []
        }
        const errors = []
        for (const v of Object.values(state.forms[id].error)) {
          for (const e of Object.values(v)) {
            if (e.error) {
              errors.push(e)
            }
          }
        }
        return errors
      }
    },
    formCallback (state) {
      return (id: string, requiredTabs?: string[]) => {
        let requiredTabsMap: { [key: string]: boolean } = {}
        if (requiredTabs) {
          requiredTabsMap = requiredTabs.reduce((cum, e) => {
            cum[e] = true
            return cum
          }, {} as { [key: string]: boolean })
        }
        if (!state.forms[id]) return
        const res: { [tab: string]: unknown } = {}
        for (const [tab, callback] of Object.entries(state.forms[id].callback)) {
          if (callback) {
            res[tab] = callback()
            delete requiredTabsMap[tab]
          }
        }
        return { res, notCalled: Object.keys(requiredTabsMap) }
      }
    }
  },
  mutations: {
    setForm (state, args: {id: string, value: Form | undefined}) {
      const { id, value } = args
      if (!value) {
        delete state.forms[id]
        return
      }
      state.forms[id] = value
    }
  },
  actions: {
    deleteForm (context, id) {
      this.commit('setForm', { id, value: undefined })
    },
    resetForm (context, id) {
      this.commit('setForm', { id, value: { value: {}, error: {}, callback: {} } })
    },
    setForm (context, args: {id: string, form: Form | undefined}) {
      const { id, form } = args
      this.commit('setForm', { id, value: form })
    },
    setFormValue (context, { id, mode, tab, key, value }) {
      const form = this.getters.form(id)
      if (!form[mode][tab]) {
        form[mode][tab] = {}
      }
      form[mode][tab][key] = value
      this.commit('setForm', { id, value: form })
    },
    clearFormValues (context, { id, mode, tab }) {
      const form = this.getters.form(id)
      if (form[mode][tab]) {
        delete form[mode][tab]
      }
      this.commit('setForm', { id, value: form })
    },
    setFormCallback (context, { id, tab, callback }) {
      const form = this.getters.form(id)
      form.callback[tab] = callback
      this.commit('setForm', { id, value: form })
    }
  },
  modules: {
  }
})
