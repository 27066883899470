import { getAddressVillage } from '@/models/address'
import { Household, HouseholdMemberRole } from '@/models/household'
import mpiTemplate from '@/assets/template/mpi-template.json'

export const houseOwnStatusMap: { [key: string]: number } = {
  มี: 1,
  ไม่มี: 0
}

export const genderMap: { [key: string]: string } = {
  ชาย: 'ช',
  หญิง: 'ญ'
}

export interface AddHouseholdBodyAPI {
  'have_address_num': number,
  'house_type': string,
  'address_num': string,
  moo: number,
  'village_ID': number,
  'village_name': string,
  'tambol_ID': number,
  'tambol_name': string,
  'amphur_ID': number,
  'amphur_name': string,
  'province_ID': number,
  'province_name': string,
  NID: number,
  'prefix_name': string,
  name: string,
  surname: string,
  gender: string,
  birthdate: string,
  occupation: string,
  'house_status': number,
  latitude: number | null,
  longitude: number | null,
  'update_location_user': string,
  'update_location_date': string,
  'survey_date': string,
  'survey_user': string,
  'survey_operator': string,
  'survey_department': string,
  indicator1: number,
  indicator2: number,
  indicator3: number,
  indicator4: number,
  indicator5: number,
  indicator6: number,
  indicator7: number,
  indicator8: number,
  indicator9: number,
  indicator10: number,
  indicator11: number,
  indicator12: number,
  indicator13: number,
  indicator14: number,
  indicator15: number,
  indicator16: number,
  indicator17: number,
  indicator18: number,
  indicator19: number,
  indicator20: number,
  indicator21: number,
  indicator22: number,
  indicator23: number,
  indicator24: number,
  indicator25: number,
  indicator26: number,
  indicator27: number,
  indicator28: number,
  indicator29: number,
  indicator30: number,
  indicator31: number,
  'poor_EBMN_health': number,
  'poor_EBMN_living': number,
  'poor_EBMN_education': number,
  'poor_EBMN_income': number,
  'poor_EBMN_accessibility': number,
  'sum_ebmn': number,
  'SUM_MPI_FACTORS': number,
  'HOUSE_MEMBER_CNT': number,
  'sum_tpmap': number,
  'MOF_VAL_CNT': number,
  'MPI_score': number,
  'house_data_ID_62': null,
  'village_ID_62': null,
  'sum_important_ind': number
}

export interface HouseholdRaw {
  _id: string
  problems: {
    _id: string,
    'problem_name': string
  }[]
}

export function getAddHouseholdBodyAPI (household: Household): AddHouseholdBodyAPI | undefined {
  const address = household.address
  const recordStaff = household.recordStaff
  const members = household.members
  const mpi = household.mpi
  const addressVillage = getAddressVillage(household.address.villageId)
  const head = Object.values(household.members).find((m) => m.personalInfo.role === HouseholdMemberRole.HEAD)
  if (!head) return
  const headBirthDateRaw = head.personalInfo.birthDate
  const headBirthDate = `${headBirthDateRaw.year + 543}${(headBirthDateRaw.month + 1).toString().padStart(2, '0')}${headBirthDateRaw.date.toString().padStart(2, '0')}`
  const mofValCnt = Object.values(members).reduce((cum, member) => (cum + (member.welfare?.hasStateWelfareCard ? 1 : 0)), 0)
  const mpiValue: { name: string, items: boolean[] }[] = []
  let mpiHighlight: boolean[] | undefined
  let sumEbmn = 0
  let mpiScore = 0
  if (mpi) {
    for (const _mpi of mpiTemplate) {
      mpiValue.push({ name: _mpi.name, items: mpi.index[_mpi.name] })
    }
    mpiHighlight = mpiValue.map((group, i) => (
      group.items.reduce((cum, e, j) => (cum || ((mpiTemplate[i].score[j] > 0) && e)), false)
    ))
    sumEbmn = Object.values(mpi.index).reduce((cum, group) => (
      cum + group.reduce((cum1, e) => (cum1 + (e ? 1 : 0)), 0)
    ), 0)
    mpiScore = mpiValue.reduce((cum0, group, i) => (
      cum0 + group.items.reduce((cum, e, j) => (cum + (e ? mpiTemplate[i].score[j] : 0)), 0)
    ), 0)
  }

  return {
    have_address_num: houseOwnStatusMap[address.houseOwnStatus],
    house_type: address.residentType,
    address_num: address.houseNum,
    moo: (Math.floor(parseInt(address.villageId.toString()) / 100) % 100),
    village_ID: parseInt(addressVillage.id.village.toString()),
    village_name: addressVillage.village,
    tambol_ID: parseInt(addressVillage.id.subdistrict.toString()),
    tambol_name: addressVillage.subdistrict,
    amphur_ID: parseInt(addressVillage.id.district.toString()),
    amphur_name: addressVillage.district,
    province_ID: parseInt(addressVillage.id.province.toString()),
    province_name: addressVillage.province,
    NID: parseInt(head.personalInfo.id),
    prefix_name: head.personalInfo.prefix,
    name: head.personalInfo.name,
    surname: head.personalInfo.surname,
    gender: genderMap[head.personalInfo.gender],
    birthdate: headBirthDate,
    occupation: head.personalInfo.job,
    house_status: 1,
    latitude: address.position ? address.position[0] : null,
    longitude: address.position ? address.position[1] : null,
    update_location_user: '',
    update_location_date: '',
    survey_date: (household.objectState.createDate ?? new Date()).toISOString(),
    survey_user: recordStaff.username,
    survey_operator: recordStaff.staff,
    survey_department: recordStaff.organization,
    indicator1: mpi?.index['สุขภาพ'][0] ? 1 : 0,
    indicator2: mpi?.index['สุขภาพ'][1] ? 1 : 0,
    indicator3: mpi?.index['สุขภาพ'][2] ? 1 : 0,
    indicator4: mpi?.index['สุขภาพ'][3] ? 1 : 0,
    indicator5: mpi?.index['สุขภาพ'][4] ? 1 : 0,
    indicator6: mpi?.index['สุขภาพ'][5] ? 1 : 0,
    indicator7: mpi?.index['สุขภาพ'][6] ? 1 : 0,
    indicator8: mpi?.index['สภาพแวดล้อม'][0] ? 1 : 0,
    indicator9: mpi?.index['สภาพแวดล้อม'][1] ? 1 : 0,
    indicator10: mpi?.index['สภาพแวดล้อม'][2] ? 1 : 0,
    indicator11: mpi?.index['สภาพแวดล้อม'][3] ? 1 : 0,
    indicator12: mpi?.index['สภาพแวดล้อม'][4] ? 1 : 0,
    indicator13: mpi?.index['สภาพแวดล้อม'][5] ? 1 : 0,
    indicator14: mpi?.index['สภาพแวดล้อม'][6] ? 1 : 0,
    indicator15: mpi?.index['การศึกษา'][0] ? 1 : 0,
    indicator16: mpi?.index['การศึกษา'][1] ? 1 : 0,
    indicator17: mpi?.index['การศึกษา'][2] ? 1 : 0,
    indicator18: mpi?.index['การศึกษา'][3] ? 1 : 0,
    indicator19: mpi?.index['การศึกษา'][4] ? 1 : 0,
    indicator20: mpi?.index['การมีงานทำและรายได้'][0] ? 1 : 0,
    indicator21: mpi?.index['การมีงานทำและรายได้'][1] ? 1 : 0,
    indicator22: mpi?.index['การมีงานทำและรายได้'][2] ? 1 : 0,
    indicator23: mpi?.index['การมีงานทำและรายได้'][3] ? 1 : 0,
    indicator24: mpi?.index['ค่านิยม'][0] ? 1 : 0,
    indicator25: mpi?.index['ค่านิยม'][1] ? 1 : 0,
    indicator26: mpi?.index['ค่านิยม'][2] ? 1 : 0,
    indicator27: mpi?.index['ค่านิยม'][3] ? 1 : 0,
    indicator28: mpi?.index['ค่านิยม'][4] ? 1 : 0,
    indicator29: mpi?.index['ค่านิยม'][5] ? 1 : 0,
    indicator30: mpi?.index['ค่านิยม'][6] ? 1 : 0,
    indicator31: mpi?.index['ค่านิยม'][7] ? 1 : 0,
    poor_EBMN_health: mpiHighlight && mpiHighlight[0] ? 1 : 0,
    poor_EBMN_living: mpiHighlight && mpiHighlight[1] ? 1 : 0,
    poor_EBMN_education: mpiHighlight && mpiHighlight[2] ? 1 : 0,
    poor_EBMN_income: mpiHighlight && mpiHighlight[3] ? 1 : 0,
    poor_EBMN_accessibility: mpiHighlight && mpiHighlight[4] ? 1 : 0,
    sum_ebmn: sumEbmn,
    SUM_MPI_FACTORS: mpiHighlight ? mpiHighlight.reduce((cum, e) => (cum + (e ? 1 : 0)), 0) : 0,
    HOUSE_MEMBER_CNT: Object.keys(members).length,
    sum_tpmap: mpiScore > 0 ? mofValCnt : 0,
    MOF_VAL_CNT: mofValCnt,
    MPI_score: mpiScore,
    house_data_ID_62: null,
    village_ID_62: null,
    sum_important_ind: [2, 2, 1, 2, 1].reduce((cum, e, i) => cum + (mpiHighlight && mpiHighlight[i] ? e : 0), 0)
  }
}
