<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.typeMain }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="typeMain"
          :error-messages="typeMainErr"
          @update:modelValue="typeMainChanged()"
          @update:focused="(val) => {if (!val) typeMainVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.typeMain}`"
          :no-data-text="`ไม่พบ${titleMap.typeMain}`"
          :disabled="currDisabled"
          :items="(Object.values(MainActivityType) as string[])"
        />
      </div>
      <div v-if="typeMain === MainActivityType.TRAINING" class="new-household-form-item-title">
        <p>{{ titleMap.typeDetail.training.topic }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="typeMain === MainActivityType.TRAINING" class="new-household-form-item">
        <v-text-field
          v-model="typeDetailTrainingTopic"
          :error-messages="typeDetailTrainingTopicErr"
          @update:modelValue="typeDetailTrainingTopicVal()"
          @update:focused="(val) => {if (!val) typeDetailTrainingTopicVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.typeDetail.training.topic"
        />
      </div>
      <div v-if="typeMain === MainActivityType.TRAINING" class="new-household-form-item-title">
        <p>{{ titleMap.typeDetail.training.memberId }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="typeMain === MainActivityType.TRAINING" class="new-household-form-item">
        <v-select
          v-model="typeDetailTrainingMemberId"
          :error-messages="typeDetailTrainingMemberIdErr"
          @update:modelValue="typeDetailTrainingMemberIdVal()"
          @update:focused="(val) => {if (!val) typeDetailTrainingMemberIdVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.typeDetail.training.memberId}`"
          :no-data-text="`ไม่พบ${titleMap.typeDetail.training.memberId}`"
          :disabled="currDisabled"
          item-title="fullName"
          item-value="key"
          :items="memberList"
        />
      </div>
      <div v-if="typeMain === MainActivityType.TRAINING" class="new-household-form-item-title">
        <p>{{ titleMap.typeDetail.training.newIncome }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="typeMain === MainActivityType.TRAINING" class="new-household-form-item">
        <v-text-field
          ref="typeDetailTrainingNewIncomeRef"
          :key="typeDetailTrainingNewIncomeKey"
          :model-value="typeDetailTrainingNewIncome"
          :error-messages="typeDetailTrainingNewIncomeErr"
          @update:modelValue="(val: string) => typeDetailTrainingNewIncomeChanged(val)"
          @update:focused="(val) => {if (!val) typeDetailTrainingNewIncomeVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          type="number"
          :placeholder="titleMap.typeDetail.training.newIncome"
          suffix="บาท"
        />
      </div>
      <div v-if="typeMain === MainActivityType.AID" class="new-household-form-item-title">
        <p>{{ titleMap.typeDetail.aid.aidType }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="typeMain === MainActivityType.AID" class="new-household-form-item">
        <v-select
          v-model="typeDetailAidType"
          :error-messages="typeDetailAidTypeErr"
          @update:modelValue="typeDetailAidTypeChanged()"
          @update:focused="(val) => {if (!val) typeDetailAidTypeVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.typeDetail.aid.aidType}`"
          :no-data-text="`ไม่พบ${titleMap.typeDetail.aid.aidType}`"
          :disabled="currDisabled"
          :items="(Object.values(ActivityAidType) as string[])"
        />
      </div>
      <div
        v-if="typeMain === MainActivityType.AID && typeDetailAidType === ActivityAidType.THINGS"
        class="new-household-form-item-title"
      >
        <p>{{ titleMap.typeDetail.aid.aidDetailMap[typeDetailAidType] }}</p>&nbsp;
        &nbsp;
      </div>
      <div
        v-if="typeMain === MainActivityType.AID && typeDetailAidType === ActivityAidType.THINGS"
        class="new-household-form-item"
      >
        <div class="list-input">
          <div class="list-input-item list-input-item-add">
            <div class="list-input-item-title-full">
              <v-text-field
                :key="typeDetailAidDetailTitleKey"
                v-model="typeDetailAidDetailTitle"
                :error-messages="typeDetailAidDetailErr"
                @keydown.enter="addThing"
                color="primary"
                density="compact"
                variant="outlined"
                label="สิ่งของ"
              />
            </div>
            <div class="list-input-item-other-relief-btn">
              <v-btn variant="text" icon="mdi-plus-box" size="1.5rem" @click="addThing"/>
            </div>
          </div>
          <div class="list-input-item" v-if="!Array.isArray(typeDetailAidDetail) || typeDetailAidDetail.length === 0">
            <p class="list-no-item-text">ไม่มีรายการการสิ่งของอื่นๆ</p>
          </div>
          <div class="list-input-item-other-relief-items">
            <v-chip-group>
              <v-chip v-for="(item, i) in typeDetailAidDetail" :key="item" closable @click:close="deleteThing(i)">
                <p class="list-chip">{{ item }}</p>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </div>
      <div
        v-if="typeMain === MainActivityType.AID && typeDetailAidType && titleMap.typeDetail.aid.aidDetailMap[typeDetailAidType] === 'คำอธิบาย'"
        class="new-household-form-item-title"
      >
        <p>คำอธิบาย</p>&nbsp;
        &nbsp;
      </div>
      <div
        v-if="typeMain === MainActivityType.AID && typeDetailAidType && titleMap.typeDetail.aid.aidDetailMap[typeDetailAidType] === 'คำอธิบาย'"
        class="new-household-form-item"
      >
        <v-text-field
          v-model="typeDetailAidDetail"
          :error-messages="typeDetailAidDetailErr"
          @update:modelValue="typeDetailAidDetailVal()"
          @update:focused="(val) => {if (!val) typeDetailAidDetailVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          placeholder="คำอธิบาย"
        />
      </div>
      <div v-if="typeMain === MainActivityType.AID" class="new-household-form-item-title">
        <p>{{ titleMap.typeDetail.aid.memberId }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="typeMain === MainActivityType.AID" class="new-household-form-item">
        <v-select
          v-model="typeDetailAidMemberId"
          :error-messages="typeDetailAidMemberIdErr"
          @update:modelValue="typeDetailAidMemberIdVal()"
          @update:focused="(val) => {if (!val) typeDetailAidMemberIdVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.typeDetail.aid.memberId}`"
          :no-data-text="`ไม่พบ${titleMap.typeDetail.aid.memberId}`"
          :disabled="currDisabled"
          item-title="fullName"
          item-value="key"
          :items="memberList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.result }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="result"
          :error-messages="resultErr"
          @update:modelValue="resultVal()"
          @update:focused="(val) => {if (!val) resultVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.result}`"
          :no-data-text="`ไม่พบ${titleMap.result}`"
          :disabled="currDisabled"
          :items="(Object.values(ActivityResult) as string[])"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.problemsId }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-autocomplete
          multiple
          chips
          v-model="problemsId"
          @update:modelValue="problemsIdVal()"
          @update:focused="(val) => {if (!val) problemsIdVal()}"
          :error-messages="problemsIdErr"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.problemsId}`"
          :no-data-text="`ไม่พบ${titleMap.problemsId}`"
          :disabled="currDisabled"
          item-title="title"
          item-value="key"
          :items="problemList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.name }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          v-model="name"
          :error-messages="nameErr"
          @update:modelValue="nameVal()"
          @update:focused="(val) => {if (!val) nameVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.name"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.povertySolvingMenu }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="povertySolvingMenu"
          :error-messages="povertySolvingMenuErr"
          @update:modelValue="povertySolvingMenuVal()"
          @update:focused="(val) => {if (!val) povertySolvingMenuVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.povertySolvingMenu}`"
          :no-data-text="`ไม่พบ${titleMap.povertySolvingMenu}`"
          :disabled="currDisabled"
          :items="povertySolvingMenuList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.detail }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-textarea
          v-model="detail"
          :error-messages="detailErr"
          @update:modelValue="detailVal()"
          @update:focused="(val) => {if (!val) detailVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.detail"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.duration }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <div class="date-picker-container">
          <VueDatePicker
            range
            v-model="duration"
            @update:model-value="durationChanged"
            locale="th"
            :format-locale="th"
            format="dd/MM/y HH:mm"
            select-text="เลือก"
            cancel-text="ยกเลิก"
            :disabled="currDisabled"
          ></VueDatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { ActivityAidType, ActivityResult, ActivityType, ActivityTypeAidDetail, ActivityTypeTrainingDetail, HouseholdMember, MainActivityType, Problem, ProblemDetailHtypeMain, ProblemDetailHtypeReason, TemplateActivityTypeAidDetail, TemplateActivityTypeTrainingDetail } from '@/models/household'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { th } from 'date-fns/locale'

export default defineComponent({
  name: 'ActivityForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'activityInfo', 'members', 'problems', 'disabled'],
  components: {
    VueDatePicker
  },
  setup () {
    const store = useStore()
    return {
      th,
      MainActivityType,
      ActivityAidType,
      ActivityResult,
      store
    }
  },
  data () {
    const type = this.activityInfo?.type as ActivityType | undefined
    const result = this.activityInfo?.result as ActivityResult | undefined
    const problemsId = Object.keys((this.activityInfo?.problemsId ?? {}) as { [pid: string]: boolean })
    const name = this.activityInfo?.name as string | undefined
    const detail = this.activityInfo?.detail as string | undefined
    const duration = this.activityInfo?.duration as { start: Date, end: Date } | undefined

    let typeDetailTraining: ActivityTypeTrainingDetail | undefined
    if (type?.main === MainActivityType.TRAINING) {
      typeDetailTraining = type.detail as ActivityTypeTrainingDetail
    }

    let typeDetailAid: ActivityTypeAidDetail | undefined
    if (type?.main === MainActivityType.AID) {
      typeDetailAid = type.detail as ActivityTypeAidDetail
    }

    const aidDetailMap: { [key: string]: string } = {}
    aidDetailMap[ActivityAidType.THINGS] = 'สิ่งของ'
    aidDetailMap[ActivityAidType.SUBVENTION] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.UTILITIES] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.TRAINING] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.COORDINATE] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.OTHER] = 'คำอธิบาย'

    return {
      ProblemDetailHtypeMain,
      ProblemDetailHtypeReason,
      typeDetailTrainingNewIncomeKey: Math.random(),
      typeDetailAidDetailTitleKey: Math.random(),
      currDisabled: this.disabled as boolean | undefined,
      typeMain: ref<MainActivityType | undefined>(type?.main),
      typeMainErr: ref<string | undefined>(),
      typeDetailTrainingTopic: ref<string | undefined>(typeDetailTraining?.topic),
      typeDetailTrainingTopicErr: ref<string | undefined>(),
      typeDetailTrainingMemberId: ref<string | undefined>(typeDetailTraining?.memberId),
      typeDetailTrainingMemberIdErr: ref<string | undefined>(),
      typeDetailTrainingNewIncome: ref<string | undefined>(typeDetailTraining?.newIncome.toString()),
      typeDetailTrainingNewIncomeErr: ref<string | undefined>(),
      typeDetailAidDetailTitle: ref<string | undefined>(),
      typeDetailAidType: ref<ActivityAidType | undefined>(typeDetailAid?.type),
      typeDetailAidTypeErr: ref<string | undefined>(),
      typeDetailAidDetail: ref<string | string[] | undefined>(typeDetailAid?.detail),
      typeDetailAidDetailErr: ref<string | undefined>(),
      typeDetailAidMemberId: ref<string | undefined>(typeDetailAid?.memberId),
      typeDetailAidMemberIdErr: ref<string | undefined>(),
      result: ref<ActivityResult | undefined>(result),
      resultErr: ref<string | undefined>(),
      problemsId: ref<string[]>(problemsId),
      problemsIdErr: ref<string | undefined>(),
      name: ref<string | undefined>(name),
      nameErr: ref<string | undefined>(),
      povertySolvingMenu: ref<string | undefined>(), // TODO
      povertySolvingMenuErr: ref<string | undefined>(),
      detail: ref<string | undefined>(detail),
      detailErr: ref<string | undefined>(),
      duration: ref<[Date, Date] | undefined>(duration ? [duration.start, duration.end] : [new Date(), new Date()]),
      durationErr: ref<string | undefined>(),
      titleMap: {
        typeMain: 'ประเภทกิจกรรม',
        typeDetail: {
          training: { topic: 'หัวข้อที่ได้รับการฝึกอบรม', memberId: 'ผู้เข้ารับการฝึกอบรม', newIncome: 'รายได้หลังจากฝึกอาชีพ' },
          aid: {
            aidType: 'ประเภทการสงเคราะห์',
            aidDetail: {
              things: 'สิ่งของ',
              subvention: 'คำอธิบาย',
              training: 'คำอธิบาย',
              coordinate: 'คำอธิบาย',
              other: 'คำอธิบาย'
            },
            aidDetailMap,
            memberId: 'ผู้ได้รับการสงเคราะห์'
          }
        },
        result: 'ผลสัมฤทธิ์ของกิจกรรม',
        problemsId: 'เพื่อแก้ปัญหาที่บันทึก',
        name: 'ชื่อกิจกรรม',
        povertySolvingMenu: 'เมนูแก้จนที่เลือกใช้',
        detail: 'รายละเอียดกิจกรรม',
        duration: 'ระยะเวลาดำเนินกิจกรรม'
      },
      memberList: this.members
        ? Object.entries(this.members as { [key: string]: HouseholdMember }).map(([key, member]) => (
          { key, fullName: `${member.personalInfo.prefix}${member.personalInfo.name} ${member.personalInfo.surname}` }
        ))
        : undefined,
      problemList: this.problems
        ? Object.entries(this.problems as { [key: string]: Problem }).map(([key, problem]) => (
          { key, title: problem.problemDetail.title }
        ))
        : undefined,
      povertySolvingMenuList: [
        '1.1.1 เงินอุดหนุนเพื่อการเลี้ยงดูเด็กแรกเกิด',
        '1.1.2 เงินสงเคราะห์เด็กในครอบครัวยากจน',
        '2.1.1 โครงการจัดสรรเงินอุดหนุน แบบมีเงื่อนไขแก่นักเรียนยากจนพิเศษ',
        '2.1.2 การจัดสรรเงินอุดหนุนเพิ่มเติม ให้แก่นักเรียนทุนเสมอภาค',
        '3.1.1 ระบบหลักประกันสุขภาพถ้วนหน้า',
        '3.1.2 สิทธิประโยชน์ด้านสุขภาพของ แรงงานในระบบประกันสังคม',
        '4.1.1 สถานสงเคราะห์สาหรับคนไร้ที่พึ่ง',
        '4.1.2 สถานสงเคราะห์คนชรา',
        '5.1.1 สายด่วนการบริการภาครัฐ และบริการทางสังคม',
        '5.1.2 คลินิกแก้ปัญหาเฉพาะหน้า'
      ]
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (d) {
        this.currDisabled = d
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    if (this.activityInfo) {
      this.validate()
    }
  },
  methods: {
    updateValue (key: string, value: any, title: string, error?: string) {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key,
        value
      })
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key,
        value: { tab: this.tabName, title, error }
      })
    },
    typeMainVal () {
      this.typeMainErr = validateEmpty(this.typeMain, undefined)
      this.updateValue('typeMain', this.typeMain, this.titleMap.typeMain, this.typeMainErr)
    },
    typeDetailTrainingTopicVal (err = true) {
      this.typeDetailTrainingTopicErr = err ? validateEmpty(this.typeDetailTrainingTopic, undefined) : undefined
      this.updateValue(
        'typeDetailTrainingTopic',
        this.typeDetailTrainingTopic,
        this.titleMap.typeDetail.training.topic,
        this.typeDetailTrainingTopicErr
      )
    },
    typeDetailTrainingMemberIdVal (err = true) {
      this.typeDetailTrainingMemberIdErr = err ? validateEmpty(this.typeDetailTrainingMemberId, undefined) : undefined
      this.updateValue(
        'typeDetailTrainingMemberId',
        this.typeDetailTrainingMemberId,
        this.titleMap.typeDetail.training.memberId,
        this.typeDetailTrainingMemberIdErr
      )
    },
    typeDetailTrainingNewIncomeVal (err = true) {
      this.typeDetailTrainingNewIncomeErr = err ? validateEmpty(this.typeDetailTrainingNewIncome, undefined) : undefined
      this.updateValue(
        'typeDetailTrainingNewIncome',
        this.typeDetailTrainingNewIncome,
        this.titleMap.typeDetail.training.newIncome,
        this.typeDetailTrainingNewIncomeErr
      )
    },
    typeDetailAidTypeVal (err = true) {
      this.typeDetailAidTypeErr = err ? validateEmpty(this.typeDetailAidType, undefined) : undefined
      this.updateValue(
        'typeDetailAidType',
        this.typeDetailAidType,
        this.titleMap.typeDetail.aid.aidType,
        this.typeDetailAidTypeErr
      )
    },
    typeDetailAidDetailVal (err = true) {
      this.typeDetailAidDetailErr = undefined
      this.updateValue(
        'typeDetailAidDetail',
        this.typeDetailAidDetail,
        this.typeDetailAidType ? this.titleMap.typeDetail.aid.aidDetailMap[this.typeDetailAidType] ?? '' : '',
        this.typeDetailAidDetailErr
      )
    },
    typeDetailAidMemberIdVal (err = true) {
      this.typeDetailAidMemberIdErr = err ? validateEmpty(this.typeDetailAidMemberId, undefined) : undefined
      this.updateValue(
        'typeDetailAidMemberId',
        this.typeDetailAidMemberId,
        this.titleMap.typeDetail.aid.memberId,
        this.typeDetailAidMemberIdErr
      )
    },
    resultVal (err = true) {
      this.resultErr = err ? validateEmpty(this.result, undefined) : undefined
      this.updateValue(
        'result',
        this.result,
        this.titleMap.typeDetail.aid.memberId,
        this.resultErr
      )
    },
    problemsIdVal (err = true) {
      this.problemsIdErr = err ? validateEmpty(this.problemsId, undefined) : undefined
      this.updateValue(
        'problemsId',
        this.problemsId.reduce((cum, e) => {
          cum[e] = true
          return cum
        }, {} as { [pid: string]: boolean }),
        this.titleMap.typeDetail.aid.memberId,
        this.problemsIdErr
      )
    },
    nameVal (err = true) {
      this.nameErr = err ? validateEmpty(this.name, undefined) : undefined
      this.updateValue(
        'name',
        this.name,
        this.titleMap.name,
        this.nameErr
      )
    },
    povertySolvingMenuVal (err = true) {
      this.povertySolvingMenuErr = err ? validateEmpty(this.povertySolvingMenu, undefined) : undefined
      this.updateValue(
        'povertySolvingMenu',
        this.povertySolvingMenu,
        this.titleMap.name,
        this.povertySolvingMenuErr
      )
    },
    detailVal (err = true) {
      this.detailErr = err ? validateEmpty(this.detail, undefined) : undefined
      this.updateValue(
        'detail',
        this.detail,
        this.titleMap.name,
        this.detailErr
      )
    },
    durationVal (err = true) {
      this.durationErr = err ? validateEmpty(this.duration, undefined) : undefined
      this.updateValue(
        'duration',
        this.duration ? { start: this.duration[0], end: this.duration[1] } : undefined,
        this.titleMap.duration,
        this.durationErr
      )
    },
    addThing () {
      this.typeDetailAidDetailErr = validateEmpty(this.typeDetailAidDetailTitle, undefined)
      if (this.typeDetailAidDetailErr || !this.typeDetailAidDetailTitle) {
        return
      }
      if (!Array.isArray(this.typeDetailAidDetail)) {
        this.typeDetailAidDetail = []
      }
      this.typeDetailAidDetail.push(this.typeDetailAidDetailTitle)
      this.typeDetailAidDetailVal()
      this.$nextTick(() => {
        this.typeDetailAidDetailTitle = undefined
        this.typeDetailAidDetailTitleKey = Math.random()
      })
    },
    deleteThing (i: number) {
      if (!Array.isArray(this.typeDetailAidDetail)) return
      const typeDetailAidDetail = [...this.typeDetailAidDetail]
      typeDetailAidDetail.splice(i, 1)
      this.typeDetailAidDetail = typeDetailAidDetail
      this.typeDetailAidDetailVal()
    },
    validate () {
      this.store.dispatch('clearFormValues', {
        id: this._id,
        mode: 'error',
        tab: this.tab
      })
      this.typeMainVal()
      if (this.typeMain === MainActivityType.TRAINING) {
        this.typeDetailTrainingTopicVal()
        this.typeDetailTrainingMemberIdVal()
        this.typeDetailTrainingNewIncomeVal()
      } else if (this.typeMain === MainActivityType.AID) {
        this.typeDetailAidTypeVal()
        this.typeDetailAidDetailVal()
        this.typeDetailAidMemberIdVal()
      }
      this.resultVal()
      this.problemsIdVal()
      this.nameVal()
      this.detailVal()
      this.durationVal()
    },
    typeDetailTrainingNewIncomeChanged (value: string | undefined, err = true) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.typeDetailTrainingNewIncomeKey = Math.random()
        this.$nextTick(() => {
          const typeDetailTrainingNewIncomeRef = this.$refs.typeDetailTrainingNewIncomeRef as any
          typeDetailTrainingNewIncomeRef.focus()
        })
        return
      }
      this.typeDetailTrainingNewIncome = value
      this.typeDetailTrainingNewIncomeVal(err)
    },
    typeDetailAidTypeChanged (err = true) {
      this.typeDetailAidDetail = undefined
      this.typeDetailAidDetailTitle = undefined
      this.typeDetailAidDetailVal(false)
      this.typeDetailAidTypeVal(err)
    },
    typeMainChanged () {
      this.typeDetailTrainingTopic = undefined
      this.typeDetailTrainingMemberId = undefined
      this.typeDetailTrainingNewIncome = undefined
      this.typeDetailAidType = undefined
      this.typeDetailAidDetail = undefined
      this.typeDetailAidDetailTitle = undefined
      this.typeDetailAidMemberId = undefined
      if (this.typeMain === MainActivityType.TRAINING) {
        this.typeDetailTrainingTopicVal(false)
        this.typeDetailTrainingMemberIdVal(false)
        this.typeDetailTrainingNewIncomeChanged(undefined, false)
      } else if (this.typeMain === MainActivityType.AID) {
        this.typeDetailAidTypeChanged(false)
        this.typeDetailAidDetailVal(false)
        this.typeDetailAidMemberIdVal(false)
      }
      this.typeMainVal()
    },
    durationChanged () {
      if (!this.duration || !this.duration[0] || !this.duration[1]) {
        this.duration = undefined
      }
      this.durationVal()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

.list-input-item-title-full {
  flex: 1;
}

</style>
