<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="mpi-summary">
        <p>ค่าดัชนี 5 มิติ</p>
        <MpiChart class="mpi-chart-outter" :mpi-highlight="mpiValue.map((group, i) => (
          group.items.reduce((cum, e, j) => (cum || ((mpiTemplate[i].score[j] > 0) && e)), false)
        ))" />
      </div>
      <div class="mpi-form">
        <v-expansion-panels>
          <v-expansion-panel
            bg-color="background"
            v-for="(group, i) in mpiTemplate"
            :key="group.name"
          >
            <v-expansion-panel-title expand-icon="mdi-menu-down" color="primary">
              <h4>{{ group.name }}</h4>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div
                class="mpi-form-group inner-text"
              >
                <v-divider />
                <div
                  class="mpi-form-group-item-wrap"
                  v-for="(item, j) in group.items"
                  :key="item"
                  @click="mpiValue[i].items[j] = !mpiValue[i].items[j]"
                >
                  <div class="mpi-form-group-item">
                    <div class="mpi-form-group-item-title">
                      {{ item }}
                    </div>
                    <div class="mpi-form-group-item-value">
                      <v-checkbox
                        :key="mpiValue[i].items[j] ? 1 : 0"
                        color="error"
                        v-model="mpiValue[i].items[j]"
                        label="ไม่ผ่าน"
                        density="compact"
                        true-icon="mdi-close-box"
                      ></v-checkbox>
                    </div>
                  </div>
                  <v-divider />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { BeingStatus } from '@/models/household'
import MpiChart from '@/components/Chart/MpiChart.vue'
import mpiTemplate from '@/assets/template/mpi-template.json'

export default defineComponent({
  name: 'MpiForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'mpi'],
  components: {
    MpiChart
  },
  setup () {
    const store = useStore()
    return {
      BeingStatus,
      store
    }
  },
  data () {
    const mpiValue: { name: string, items: boolean[] }[] = []
    if (this.mpi) {
      for (const _mpi of mpiTemplate) {
        mpiValue.push({ name: _mpi.name, items: this.mpi[_mpi.name] })
      }
    } else {
      for (const _mpi of mpiTemplate) {
        mpiValue.push({ name: _mpi.name, items: Array(_mpi.items.length).fill(false) })
      }
    }
    return {
      mpiTemplate,
      mpiValue
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.storeMpi })
    this.storeMpi()
  },
  methods: {
    storeMpi () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'mpi',
        value: this.mpiValue.reduce((cum, e) => { cum[e.name] = e.items; return cum }, {} as { [group: string]: boolean[] })
      })
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

.mpi-summary {
  grid: 1;
  grid-column: span 12;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media only screen and (max-width: variables.$screen-xs) {
    flex-direction: column;
  }
}

.mpi-form {
  grid: 1;
  grid-column: span 12;
}

.mpi-form-group {
  display: flex;
  flex-direction: column;
  // margin-top: 1.5rem;
}

.mpi-form-group-item {
  display: flex;
}

.mpi-form-group-item-title {
  flex: 1;
  padding-top: 8px;
  margin-bottom: 1rem;
}

.mpi-form-group-item-value {
  // font-size: 0.25rem;
  // padding-top: 0.25rem;
}

.mpi-form-group-item {
  padding-top: 1.25rem;
}

.mpi-form-group-item-wrap {
  &:hover {
    cursor: pointer;
    background-color: variables.$text-color-main-contrast;
  }
}

.mpi-chart-outter {
  font-size: 1.25rem;
  @media only screen and (max-width: variables.$screen-xs) {
    font-size: 1rem;
  }
}

</style>
