<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.staff }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div :key="staffKey" class="new-household-form-item">
        <v-text-field
          v-model="staff"
          :error-messages="staffErr"
          @update:modelValue="staffVal"
          @update:focused="(val) => {if (!val) staffVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.staff"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.organization }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div :key="organizationKey" class="new-household-form-item">
        <v-text-field
          v-model="organization"
          :error-messages="organizationErr"
          @update:modelValue="organizationVal"
          @update:focused="(val) => {if (!val) organizationVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.organization"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { getUserInfo } from '@/plugins/keycloak'

export default defineComponent({
  name: 'HouseholdRecordStaff',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'disabled', 'recordStaff'],
  components: {
  },
  setup () {
    const store = useStore()
    return {
      store
    }
  },
  data () {
    return {
      staffKey: Math.random(),
      organizationKey: Math.random(),
      currDisabled: this.disabled,
      staff: ref<string | undefined>(this.recordStaff?.staff),
      staffErr: ref<string | undefined>(),
      organization: ref<string | undefined>(this.recordStaff?.organization),
      organizationErr: ref<string | undefined>(),
      titleMap: {
        staff: 'ชื่อ-นามสกุลผู้บันทึกข้อมูล',
        organization: 'หน่วยงานผู้บันทึกข้อมูล'
      }
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (d) {
        this.currDisabled = d
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    this.fetchUser()
  },
  methods: {
    async fetchUser () {
      const userInfo = await getUserInfo()
      if (userInfo?.position) {
        if (!this.staff) this.staff = userInfo.position
        if (!this.organization) this.organization = userInfo.position
        this.staffKey = Math.random()
        this.organizationKey = Math.random()
        this.validate()
      }
    },
    staffVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'staff',
        value: this.staff
      })
      this.staffErr = validateEmpty(this.staff, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'staff',
        value: { tab: this.tabName, title: this.titleMap.staff, error: this.staffErr }
      })
    },
    organizationVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'organization',
        value: this.organization
      })
      this.organizationErr = validateEmpty(this.organization, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'organization',
        value: { tab: this.tabName, title: this.titleMap.organization, error: this.organizationErr }
      })
    },
    validate () {
      this.staffVal()
      this.organizationVal()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/normalForm';

</style>
