<template>
  <div class="new-household-form-container">
    <div class="popup-head">
      <h3>เพิ่มครัวเรือน</h3>
      <div class="popup-head-close-btn-box">
        <v-btn variant="text" icon="mdi-close" size="1.5rem" @click="closeWindow(false)"></v-btn>
      </div>
    </div>
    <v-divider />
    <div class="tab-container">
      <v-tabs
        class="tab-md"
        v-model="tab"
        height="6rem"
        color="primary"
        centered
        stacked
      >
        <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="10rem">
          <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
          <p class="new-household-form-tab-title">
            {{ tabsMap[tab] }}
          </p>
        </v-tab>
      </v-tabs>
      <div class="tab-sm">
        <v-tabs
          v-model="tab"
          height="4rem"
          color="primary"
          centered
          stacked
        >
          <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="5rem">
            <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
          </v-tab>
        </v-tabs>
        <p class="new-household-form-tab-title">
          {{ tabsMap[tab] }}
        </p>
      </div>
    </div>
    <v-window v-model="tab" :touch="{left: () => {}, right: () => {}}">
      <v-window-item value="head">
        <HouseholdMemberForm :_id="tempId" tab="householdHead" tab-name="หัวหน้าครัวเรือน" />
      </v-window-item>
      <v-window-item value="household">
        <HouseholdForm :_id="tempId" tab="householdAddress" tab-name="ข้อมูลครัวเรือน" />
      </v-window-item>
      <v-window-item value="mpi">
        <MpiForm :_id="tempId" tab="mpi" tab-name="สำรวจคุณภาพชีวิต" />
      </v-window-item>
      <v-window-item value="staff">
        <HouseholdRecordStaff :_id="tempId" tab="staff" tab-name="ผู้บันทึก" />
      </v-window-item>
    </v-window>
    <div class="new-household-form-buttons">
      <v-btn v-if="tab !== tabs.at(0)" variant="outlined" color="secondary" @click="prevTab">
        กลับ
      </v-btn>
      <v-btn v-if="tab === tabs.at(-1)" variant="outlined" color="success" @click="submitOffline" :loading="loading">
        บันทึกแบบร่าง offline
      </v-btn>
      <v-btn v-else variant="outlined" color="success" @click="nextTab">
        ถัดไป
      </v-btn>
    </div>
    <v-dialog
      v-model="valErrorsPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้
        </v-card-title>
        <div class="errors-report">
          <v-card-text v-for="err in valErrors" :key="err.title">
            <p v-if="err.error">"{{ err.tab }}"/"{{ err.title }}": "<span class="err-msg">{{ err.error }}</span>"</p>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn color="primary" block @click="valErrorsPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="apiErrorPopup"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ apiError?.title }}
        </v-card-title>
        <v-card-text>
          <p>{{ apiError?.message }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="apiErrorPopup = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HouseholdForm from '@/components/Form/HouseholdForm.vue'
import HouseholdMemberForm from '@/components/Form/HouseholdMemberForm.vue'
import MpiForm from '@/components/Form/MpiForm.vue'
import HouseholdRecordStaff from '@/components/Form/HouseholdRecordStaff.vue'
import { useStore } from 'vuex'
import { randHex, saveHousehold, PersonalInfo, HouseholdAddress, Household, HouseholdMember, Mpi, RecordStaff, getLocalObjectState, getLocalObjectStateWithDataState, getDefaultDataState } from '@/models/household'
import { Form } from '@/store'
import { getUserInfo } from '@/plugins/keycloak'

export default defineComponent({
  name: 'NewHouseholdForm',
  props: ['closeWindow'],
  components: {
    HouseholdForm,
    HouseholdMemberForm,
    MpiForm,
    HouseholdRecordStaff
  },
  setup () {
    const store = useStore()
    return {
      tempId: randHex(32),
      store
    }
  },
  beforeMount () {
    this.store.dispatch('resetForm', this.tempId)
  },
  unmounted () {
    this.store.dispatch('deleteForm', this.tempId)
  },
  data () {
    return {
      tab: 'head',
      valErrorsPopup: false,
      valErrors: [] as { tab: string, title: string, error: string }[],
      apiErrorPopup: false,
      apiError: undefined as { title: string, message: string } | undefined,
      tabs: ['head', 'household', 'mpi', 'staff'],
      tabsMap: { head: 'หัวหน้าครัวเรือน', household: 'ข้อมูลครัวเรือน', mpi: 'สำรวจคุณภาพชีวิต', staff: 'ผู้บันทึก' } as { [key: string]: string },
      tabsIconMap: { head: 'mdi-account-tie', household: 'mdi-home', mpi: 'mdi-checkbox-marked-outline', staff: 'mdi-pencil' } as { [key: string]: string },
      loading: false,
      selectedPosition: undefined as [number, number] | undefined
    }
  },
  methods: {
    async submitOffline () {
      if (!this.validate()) return
      const user = await getUserInfo()
      if (!user) return
      const form: Form = this.store.getters.form(this.tempId)
      const householdHeadInfo = form.value.householdHead as PersonalInfo
      const householdHead: HouseholdMember = {
        objectState: getLocalObjectStateWithDataState(),
        personalInfo: householdHeadInfo
      }
      const householdAddress = {
        ...form.value.householdAddress,
        dataState: getDefaultDataState()
      } as HouseholdAddress
      const mpi: Mpi = {
        index: form.value.mpi.mpi as { [group: string]: boolean[] },
        dataState: getDefaultDataState()
      }
      const recordStaff = {
        ...form.value.staff,
        dataState: getDefaultDataState(),
        username: user.preferred_username
      } as RecordStaff
      console.log('recordStaff', recordStaff)
      const household: Household = {
        objectState: getLocalObjectState(),
        id: this.tempId,
        members: {},
        address: householdAddress,
        mpi,
        recordStaff
      }
      household.members[randHex(32)] = householdHead
      if (await saveHousehold(household)) {
        this.closeWindow(true)
      }
    },
    validate () {
      const { notCalled } = this.store.getters.formCallback(this.tempId, ['householdHead', 'householdAddress', 'mpi', 'staff'])
      const errors = this.store.getters.formErrors(this.tempId)
      for (const t of notCalled) {
        errors.push({ tab: this.tabsMap[t], title: '*', error: 'จำเป็นต้องกรอก' })
      }
      if (errors.length > 0) {
        this.valErrors = errors
        this.valErrorsPopup = true
        return false
      }
      return true
    },
    nextTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx + 1]
    },
    prevTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx - 1]
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/popupForms';

</style>
