<template>
  <div :key="loading ? 1 : 0" class="card-body">
    <div class="problem-panel panel-desktop">
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-book-plus" @click="openNewProblem">เพิ่มปัญหา/ความต้องการ</v-btn>
    </div>
    <div class="problem-panel panel-mobile">
      <v-btn variant="text" color="primary" icon="mdi-book-plus" density="comfortable" @click="openNewProblem"></v-btn>
    </div>
    <div v-if="household" class="problem-list">
      <ProblemCard
        v-for="[pid, problem] of Object.entries(problems)"
        :key="pid"
        :_id="id"
        :pid="pid"
        :problem="problem"
        :updateCallback="() => getHousehold(true)"
      />
      <div v-if="Object.keys(problems).length === 0" class="list-no-card">
        ไม่มีปัญหา/ความต้องการ
      </div>
    </div>
  </div>
  <v-overlay class="add-form"  v-model="newProblem" :persistent="true" :no-click-animation="true">
    <ProblemDetail :_id="id" :closeWindow="closeNewProblem" />
  </v-overlay>
</template>

<script lang="ts">
import { Household, Problem, getHousehold } from '@/models/household'
import { defineComponent } from 'vue'
import ProblemDetail from './ProblemDetail.vue'
import ProblemCard from './ProblemCard.vue'

export default defineComponent({
  name: 'ProblemInfo',
  props: ['id', 'updateCallback'],
  components: {
    ProblemDetail,
    ProblemCard
  },
  data () {
    return {
      newProblem: false,
      household: undefined as Household | undefined,
      problems: {} as { [key: string]: Problem },
      loading: true
    }
  },
  mounted () {
    this.getHousehold()
  },
  methods: {
    async getHousehold (isUpdate = false) {
      this.loading = true
      const household = await getHousehold(this.id)
      if (!household) {
        this.$router.push('/notfound')
      }
      if (isUpdate && this.updateCallback) {
        this.updateCallback()
      }
      this.household = household
      this.problems = this.household?.problems ?? {}
      this.loading = false
    },
    openNewProblem () {
      this.newProblem = true
    },
    closeNewProblem (isUpdate = false) {
      this.newProblem = false
      if (isUpdate) {
        this.getHousehold(true)
      }
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.problem-list {
  flex: 1;
  display: flex;
  overflow-x: auto;
  padding: 1.5rem;
  gap: 2rem;
}

.problem-panel {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}

.panel-desktop {
  @media only screen and (max-width: variables.$screen-xs) {
    display: none;
  }
}

.panel-mobile {
  display: none;
  @media only screen and (max-width: variables.$screen-xs) {
    display: flex;
    gap: 0.25rem;
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  min-height: 333px;
}

.list-no-card {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
