import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65a6b6e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-info" }
const _hoisted_4 = { class: "card-info-value card-width card-title" }
const _hoisted_5 = { class: "card-info-value card-width" }
const _hoisted_6 = { class: "card-info-value card-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ProblemDetail = _resolveComponent("ProblemDetail")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock("div", {
    class: "card",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openViewProblem && _ctx.openViewProblem(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "image-show",
            alt: "problem image",
            src: _ctx.getImageUrl(_ctx.image.image)
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            size: "3rem"
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-image")
            ]),
            _: 1
          }))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.problemDetail?.title), 1),
      _createElementVNode("div", _hoisted_5, " วันที่ : " + _toDisplayString(_ctx.record?.date.toLocaleDateString('th')), 1),
      _createElementVNode("div", _hoisted_6, " ผู้บันทึก : " + _toDisplayString(_ctx.record?.recordStaff.staff), 1)
    ]),
    _createVNode(_component_v_overlay, {
      class: "add-form",
      modelValue: _ctx.viewProblem,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.viewProblem) = $event)),
      persistent: true,
      "no-click-animation": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProblemDetail, {
          _id: _ctx._id,
          pid: _ctx.pid,
          problem: _ctx.problem,
          closeWindow: _ctx.closeViewProblem
        }, null, 8, ["_id", "pid", "problem", "closeWindow"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}