import { getAddressVillage } from '../address'
import { Disability, Household, HouseholdMember } from '../household'
import stateWelfaresTemplate from '@/assets/template/state-welfares-template.json'

export const genderMap: { [key: string]: string } = {
  ชาย: 'ช',
  หญิง: 'ญ'
}

export const beingStatusMap: { [key: string]: number } = {
  เสียชีวิต: 0,
  มีชีวิต: 1
}

export interface AddMemberBodyAPI {
  'house_id': string,
  NID: number,
  'prefix_name': string,
  name: string,
  surname: string,
  gender: string,
  occupation: string,
  education: string,
  religion: string,
  birthdate: string,
  mobile: string | null,
  relation: string,
  'chronic_patient': number,
  'self_reliance': number,
  'member_status': number,
  'main_right': string,
  'main_hospital': string,
  'income_year': number | null,
  'system_debt': number | null,
  'informal_debt': number | null,
  'savings_year': number | null,
  disabled: number,
  'disabled_registered': string,
  'disabled_allowance': number | null,
  DT1: number,
  DT2: number,
  DT3: number,
  DT4: number,
  DT5: number,
  DT6: number,
  DT7: number,
  'elderly_allowance': number,
  V_EXIST: number | null,
  'others_allowance': {
    'allowance_name': string,
    'allowance_amount': number,
    'allowance_by': string
  }[],
  'village_ID': number,
  'village_name': string,
  'tambol_ID': number,
  'tambol_name': string,
  'amphur_ID': number,
  'amphur_name': string,
  'province_ID': number,
  'province_name': string,
  'update_date': string,
  'user_id': string,
  'dopa_tpmap_status': number,
  welfares: [
    {
      [key: string]: [
        {
          'welfare_name': string,
          'total_welfare': number | null,
          'detail_welfare': string,
          'date_welfare': string
        }
      ]
    }
  ] | undefined
}

function getStateWelfaresTemplateIdx () {
  const stateWelfaresTemplateIdx: { [group: string]: { [welfare: string]: number } } = {}
  let cnt = 1
  for (const groupItem of stateWelfaresTemplate) {
    const temp: { [welfare: string]: number } = {}
    for (const e of groupItem.items) {
      temp[e] = cnt
      cnt++
    }
    stateWelfaresTemplateIdx[groupItem.group] = temp
  }
  return stateWelfaresTemplateIdx
}

const stateWelfaresTemplateIdx = getStateWelfaresTemplateIdx()

export function getAddMemberBodyAPI (householdId: string, household: Household, member: HouseholdMember, dopaTpmapStatus: boolean): AddMemberBodyAPI {
  const personalInfo = member.personalInfo
  const financial = member.financial
  const birthDateRaw = personalInfo.birthDate
  const birthDate = `${birthDateRaw.year + 543}${(birthDateRaw.month + 1).toString().padStart(2, '0')}${birthDateRaw.date.toString().padStart(2, '0')}`
  const disability = personalInfo.disability
  const disabilities = personalInfo.disability?.disabilities
  const welfare = member.welfare
  const addressVillage = getAddressVillage(household.address.villageId)
  console.log('stateWelfares', welfare?.stateWelfares)
  return {
    house_id: householdId,
    NID: parseInt(personalInfo.id),
    prefix_name: personalInfo.prefix,
    name: personalInfo.name,
    surname: personalInfo.surname,
    gender: genderMap[personalInfo.gender],
    occupation: personalInfo.job,
    education: personalInfo.education,
    religion: personalInfo.religion,
    birthdate: birthDate,
    mobile: personalInfo.phoneNumber ?? null,
    relation: personalInfo.role,
    chronic_patient: personalInfo.isChronicallyIll ? 1 : 0,
    self_reliance: (personalInfo.canSelfReliant === undefined) || personalInfo.canSelfReliant ? 1 : 0,
    member_status: personalInfo.beingStatus ? beingStatusMap[personalInfo.beingStatus] : 1,
    main_right: personalInfo.primaryMedicalPrivilege ?? '',
    main_hospital: personalInfo.primaryHospital ?? '',
    income_year: financial ? financial.annualIncome : 0,
    system_debt: financial ? financial.legalDept : null,
    informal_debt: financial ? financial.illegalDept : null,
    savings_year: financial ? financial.saving : null,
    disabled: disability ? 1 : 0,
    disabled_registered: disability?.disabilityCard ? 'Y' : (disability?.disabilityCard === false ? 'N' : ''),
    disabled_allowance: disability?.disabilityAllowance ?? null,
    DT1: disabilities?.includes(Disability.VisualDisability) ? 1 : 0,
    DT2: disabilities?.includes(Disability.InterpretationDisability) ? 1 : 0,
    DT3: disabilities?.includes(Disability.PhysicalDisability) ? 1 : 0,
    DT4: disabilities?.includes(Disability.MentalDisability) ? 1 : 0,
    DT5: disabilities?.includes(Disability.IntelligenceDisability) ? 1 : 0,
    DT6: disabilities?.includes(Disability.LearningDisability) ? 1 : 0,
    DT7: disabilities?.includes(Disability.AutismDisability) ? 1 : 0,
    elderly_allowance: 0,
    V_EXIST: welfare?.hasStateWelfareCard ? 1 : (welfare?.hasStateWelfareCard === false ? 0 : null),
    others_allowance: welfare?.otherRelief
      ? welfare?.otherRelief.map((e) => ({
        allowance_name: e.name,
        allowance_amount: e.amount,
        allowance_by: ''
      }))
      : [],
    village_ID: parseInt(addressVillage.id.village.toString()),
    village_name: addressVillage.village,
    tambol_ID: parseInt(addressVillage.id.subdistrict.toString()),
    tambol_name: addressVillage.subdistrict,
    amphur_ID: parseInt(addressVillage.id.district.toString()),
    amphur_name: addressVillage.district,
    province_ID: parseInt(addressVillage.id.province.toString()),
    province_name: addressVillage.province,
    update_date: (member.objectState.dataState.updateDate ?? new Date()).toISOString(),
    user_id: household.recordStaff.username,
    dopa_tpmap_status: dopaTpmapStatus ? 1 : 0,
    welfares: welfare?.stateWelfares && welfare.stateWelfares.length > 0
      ? [
          welfare.stateWelfares.reduce((cum, welfareItem) => {
            const idx = stateWelfaresTemplateIdx[welfareItem.group][welfareItem.item]
            cum[`welfare${idx}`] = [{
              welfare_name: `${idx}.${welfareItem.item}`,
              total_welfare: welfareItem.total ?? null,
              detail_welfare: welfareItem.detail ?? '',
              date_welfare: (welfareItem.date ?? new Date()).toISOString()
            }]
            return cum
          }, {} as {
            [key: string]: [{
                'welfare_name': string,
                'total_welfare': number | null,
                'detail_welfare': string,
                'date_welfare': string
            }]
          })
        ]
      : undefined
  }
}
