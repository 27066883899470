<template>
  <div class="card" @click="$router.push(`/household/${household.id}`)">
    <div class="card-header">
      <div class="household-avatar">
        <img :src="require(`@/assets/images/user.png`)" height="50">
      </div>
      <div>
        <div class="household-name household-text">
          {{
            (() => {
              const head = Object.values(currHousehold.members).find((m) => m.personalInfo.role === HouseholdMemberRole.HEAD)
              if (!head) return 'ไม่มีหัวหน้าครัวเรือน'
              return `${head.personalInfo.prefix}${head.personalInfo.name} ${head.personalInfo.surname}`
            })()
          }}
        </div>
        <div class="household-role">
          <MpiChart v-if="currHousehold?.mpi" class="mpi-chart-outter" :mpi-highlight="mpiTemplate.map((group, i) => {
            return (currHousehold?.mpi) ? currHousehold.mpi.index[group.name].reduce((cum, e, j) => (cum || ((mpiTemplate[i].score[j] > 0) && e)), false) : false
          })" />
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="household-short-info">
      <div class="household-short-info-item">
        <div class="household-info-title">ที่อยู่</div>
        <div class="household-info-value">
          {{ getAddressString(currHousehold.address) }}
        </div>
      </div>
      <div class="household-short-info-item">
        <div class="household-info-title">จำนวน MPI ที่ตก</div>
        <div class="household-info-value">
          {{mpiTemplate.map((group) => {
            return (currHousehold?.mpi) ? currHousehold.mpi.index[group.name].reduce((cum, e) => (cum || e), false) : false
          }).reduce((cum, e) => (cum + (e ? 1 : 0)), 0)}}
          ข้อ
        </div>
      </div>
      <div class="household-short-info-item">
        <div class="household-info-title">จำนวนผู้ถือบัตรสวัสดิการ</div>
        <div class="household-info-value">
          {{Object.values(currHousehold.members).reduce((cum, e) => cum + (e.welfare?.hasStateWelfareCard ? 1 : 0), 0)}}
          คน
        </div>
      </div>
      <div class="household-short-info-item">
        <div class="household-info-title">จำนวนสมาชิก</div>
        <div class="household-info-value">
          {{Object.keys(currHousehold.members).length}}
          คน
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Household, HouseholdAddress, HouseholdMemberRole } from '@/models/household'
import { defineComponent } from 'vue'
import { formatNumber } from '@/utils/number-utils'
import mpiTemplate from '@/assets/template/mpi-template.json'
import MpiChart from '@/components/Chart/MpiChart.vue'
import { getAddressVillage } from '@/models/address'

export default defineComponent({
  name: 'HouseholdCard',
  props: ['household'],
  components: {
    MpiChart
  },
  data () {
    return {
      getAddressVillage,
      mpiTemplate,
      HouseholdMemberRole,
      formatNumber,
      currHousehold: this.household as Household
    }
  },
  methods: {
    getAddressString (address: HouseholdAddress) {
      const villageAddress = getAddressVillage(parseInt(address.villageId.toString()))
      return `${address.houseNum} ${villageAddress.village} ${villageAddress.subdistrict} ${villageAddress.district}`
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.household-info-title {
  width: 9rem;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
  text-align: end;
  font-weight: bold;
  font-size: variables.$font-sm;
  @media only screen and (max-width: variables.$screen-md) {
    width: 7rem;
  }
}

.household-info-value {
  flex: 1;
  font-size: variables.$font-sm;
  display: flex;
  gap: 0.1rem;
  padding-left: 0.1rem;
}

.household-short-info-item {
  flex: 1;
  display: flex;
  gap: 0.5rem;
}

.household-short-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: calc(5vw + 16rem + 4rem);
  @media only screen and (max-width: variables.$screen-md) {
    max-width: calc(50vw - 12rem + 4rem);
  }
  @media only screen and (max-width: variables.$screen-sm) {
    max-width: calc(100vw - 12rem + 4rem);
  }
}

.household-text {
  width: calc(5vw + 16rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media only screen and (max-width: variables.$screen-md) {
    width: calc(50vw - 12rem);
  }
  @media only screen and (max-width: variables.$screen-sm) {
    width: calc(100vw - 12rem);
  }
}

.card-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.household-name {
  font-size: variables.$font-lg;
}

.household-role {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: variables.$font-md;
}

.household-avatar {
  display: flex;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

.mpi-chart-outter {
  font-size: 0.625rem;
  @media only screen and (max-width: variables.$screen-xs) {
    display: flex;
    justify-content: center;
  }
}

</style>
