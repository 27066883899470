<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.title }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          v-model="title"
          :error-messages="titleErr"
          @update:modelValue="titleVal()"
          @update:focused="(val) => {if (!val) titleVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.title"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.detail }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-textarea
          v-model="detail"
          :error-messages="detailErr"
          @update:modelValue="detailVal()"
          @update:focused="(val) => {if (!val) detailVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.detail"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.htypeMain }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="htypeMain"
          :error-messages="htypeMainErr"
          @update:modelValue="htypeMainChanged()"
          @update:focused="(val) => {if (!val) htypeMainVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.htypeMain}`"
          :no-data-text="`ไม่พบ${titleMap.htypeMain}`"
          :disabled="currDisabled"
          :items="(Object.values(ProblemDetailHtypeMain) as string[])"
        />
      </div>
      <div v-if="htypeMain === ProblemDetailHtypeMain.AID_REQUIRED" class="new-household-form-item-title">
        <p>{{ titleMap.htypeDescReasonAidRequired }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="htypeMain === ProblemDetailHtypeMain.AID_REQUIRED" class="new-household-form-item">
        <v-text-field
          v-model="htypeDescReason"
          :error-messages="htypeDescReasonErr"
          @update:modelValue="htypeDescReasonVal()"
          @update:focused="(val) => {if (!val) htypeDescReasonVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.htypeDescReasonAidRequired"
        />
      </div>
      <div v-if="htypeMain === ProblemDetailHtypeMain.AID_NOT_REQUIRED" class="new-household-form-item-title">
        <p>{{ titleMap.htypeDescReasonAidNotRequired }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="htypeMain === ProblemDetailHtypeMain.AID_NOT_REQUIRED" class="new-household-form-item">
        <v-select
          v-model="htypeDescReason"
          :error-messages="htypeDescReasonErr"
          @update:modelValue="htypeDescReasonVal()"
          @update:focused="(val) => {if (!val) htypeDescReasonVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.htypeDescReasonAidNotRequired}`"
          :no-data-text="`ไม่พบ${titleMap.htypeDescReasonAidNotRequired}`"
          :disabled="currDisabled"
          :items="(Object.values(ProblemDetailHtypeReason) as string[])"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.aidType }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="aidType"
          :error-messages="aidTypeErr"
          @update:modelValue="aidTypeChanged()"
          @update:focused="(val) => {if (!val) aidTypeVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.aidType}`"
          :no-data-text="`ไม่พบ${titleMap.aidType}`"
          :disabled="currDisabled"
          :items="(Object.values(ActivityAidType) as string[])"
        />
      </div>
      <div
        v-if="aidType === ActivityAidType.THINGS"
        class="new-household-form-item-title"
      >
        <p>{{ titleMap.aidDetailMap[aidType] }}</p>&nbsp;
        &nbsp;
      </div>
      <div
        v-if="aidType === ActivityAidType.THINGS"
        class="new-household-form-item"
      >
        <div class="list-input">
          <div class="list-input-item list-input-item-add">
            <div class="list-input-item-title-full">
              <v-text-field
                :key="aidDetailTitleKey"
                v-model="aidDetailTitle"
                :error-messages="aidDetailErr"
                @keydown.enter="addThing"
                color="primary"
                density="compact"
                variant="outlined"
                label="สิ่งของ"
              />
            </div>
            <div class="list-input-item-other-relief-btn">
              <v-btn variant="text" icon="mdi-plus-box" size="1.5rem" @click="addThing"/>
            </div>
          </div>
          <div class="list-input-item" v-if="!Array.isArray(aidDetail) || aidDetail.length === 0">
            <p class="list-no-item-text">ไม่มีรายการการสิ่งของอื่นๆ</p>
          </div>
          <div class="list-input-item-other-relief-items">
            <v-chip-group>
              <v-chip v-for="(item, i) in aidDetail" :key="item" closable @click:close="deleteThing(i)">
                <p class="list-chip">{{ item }}</p>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </div>
      <div
        v-if="aidType && titleMap.aidDetailMap[aidType] === 'คำอธิบาย'"
        class="new-household-form-item-title"
      >
        <p>คำอธิบาย</p>&nbsp;
        &nbsp;
      </div>
      <div
        v-if="aidType && titleMap.aidDetailMap[aidType] === 'คำอธิบาย'"
        class="new-household-form-item"
      >
        <v-text-field
          v-model="aidDetail"
          :error-messages="aidDetailErr"
          @update:modelValue="aidDetailVal()"
          @update:focused="(val) => {if (!val) aidDetailVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          placeholder="คำอธิบาย"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { MainProblemType, ProblemDetail, ProblemDetailHtypeMain, ProblemDetailHtypeReason, ActivityAidType } from '@/models/household'

export default defineComponent({
  name: 'ProblemDetailForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'problemDetail', 'disabled'],
  components: {
  },
  setup () {
    const store = useStore()
    return {
      MainProblemType,
      ActivityAidType,
      store
    }
  },
  data () {
    const problemDetail: ProblemDetail | undefined = this.problemDetail
    const aidDetailMap: { [key: string]: string } = {}
    aidDetailMap[ActivityAidType.THINGS] = 'สิ่งของ'
    aidDetailMap[ActivityAidType.SUBVENTION] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.UTILITIES] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.TRAINING] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.COORDINATE] = 'คำอธิบาย'
    aidDetailMap[ActivityAidType.OTHER] = 'คำอธิบาย'
    return {
      ProblemDetailHtypeMain,
      ProblemDetailHtypeReason,
      aidDetailTitleKey: Math.random(),
      currDisabled: this.disabled as boolean | undefined,
      title: ref<string | undefined>(problemDetail?.title),
      titleErr: ref<string | undefined>(),
      detail: ref<string | undefined>(problemDetail?.detail),
      detailErr: ref<string | undefined>(),
      htypeMain: ref<string | undefined>(problemDetail?.htype.main),
      htypeMainErr: ref<string | undefined>(),
      htypeDescReason: ref<string | undefined>(problemDetail?.htype.main === ProblemDetailHtypeMain.AID_REQUIRED ? problemDetail.htype.description : (problemDetail?.htype.main === ProblemDetailHtypeMain.AID_NOT_REQUIRED ? problemDetail.htype.reason : undefined)),
      htypeDescReasonErr: ref<string | undefined>(),
      aidDetailTitle: ref<string | undefined>(),
      aidType: ref<ActivityAidType | undefined>(problemDetail?.aidDetail.type),
      aidTypeErr: ref<string | undefined>(),
      aidDetail: ref<string | string[] | undefined>(problemDetail?.aidDetail.detail),
      aidDetailErr: ref<string | undefined>(),
      titleMap: {
        title: 'ปัญหา/ความต้องการ',
        detail: 'รายละเอียดปัญหา/ความต้องการ',
        htypeMain: 'ประเภทครัวเรือน',
        htypeDescReasonAidRequired: 'ส่งต่อให้หน่วยงาน',
        htypeDescReasonAidNotRequired: 'เพราะ',
        aidType: 'แนวทางการแก้ปัญหา/ความต้องการ',
        aidDetail: {
          things: 'สิ่งของ',
          subvention: 'คำอธิบาย',
          training: 'คำอธิบาย',
          coordinate: 'คำอธิบาย',
          other: 'คำอธิบาย'
        },
        aidDetailMap
      }
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (d) {
        this.currDisabled = d
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    if (this.problemDetail) {
      this.validate()
    }
  },
  methods: {
    updateValue (key: string, value: any, title: string, error?: string) {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key,
        value
      })
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key,
        value: { tab: this.tabName, title, error }
      })
    },
    titleVal () {
      this.titleErr = validateEmpty(this.title, undefined)
      this.updateValue(
        'title',
        this.title,
        this.titleMap.title,
        this.titleErr
      )
    },
    detailVal () {
      this.detailErr = validateEmpty(this.detail, undefined)
      this.updateValue(
        'detail',
        this.detail,
        this.titleMap.detail,
        this.detailErr
      )
    },
    htypeMainVal () {
      this.htypeMainErr = validateEmpty(this.htypeMain, undefined)
      this.updateValue(
        'htypeMain',
        this.htypeMain,
        this.titleMap.htypeMain,
        this.htypeMainErr
      )
    },
    htypeDescReasonVal (errCheck = true) {
      if (errCheck && (
        this.htypeMain === ProblemDetailHtypeMain.AID_REQUIRED || this.htypeMain === ProblemDetailHtypeMain.AID_NOT_REQUIRED
      )) {
        this.htypeDescReasonErr = validateEmpty(this.htypeDescReason, undefined)
      } else {
        this.htypeDescReasonErr = undefined
      }
      let title = ''
      if (this.htypeMain === ProblemDetailHtypeMain.AID_REQUIRED) {
        title = this.titleMap.htypeDescReasonAidRequired
      } else if (this.htypeMain === ProblemDetailHtypeMain.AID_NOT_REQUIRED) {
        title = this.titleMap.htypeDescReasonAidNotRequired
      }
      this.updateValue(
        'htypeDescReason',
        this.htypeDescReason,
        title,
        this.htypeDescReasonErr
      )
    },
    aidTypeVal (err = true) {
      this.aidTypeErr = err ? validateEmpty(this.aidType, undefined) : undefined
      this.updateValue(
        'aidType',
        this.aidType,
        this.titleMap.aidType,
        this.aidTypeErr
      )
    },
    aidDetailVal () {
      this.aidDetailErr = undefined
      this.updateValue(
        'aidDetail',
        this.aidDetail,
        this.aidType ? this.titleMap.aidDetailMap[this.aidType] ?? '' : '',
        this.aidDetailErr
      )
    },
    validate () {
      this.titleVal()
      this.detailVal()
      this.htypeMainVal()
      this.htypeDescReasonVal()
      this.aidTypeVal()
      this.aidDetailVal()
    },
    htypeMainChanged () {
      this.htypeDescReason = undefined
      this.htypeDescReasonVal(false)
      this.htypeMainVal()
    },
    aidTypeChanged (err = true) {
      this.aidDetail = undefined
      this.aidDetailVal()
      this.aidTypeVal(err)
    },
    addThing () {
      this.aidDetailErr = validateEmpty(this.aidDetailTitle, undefined)
      if (this.aidDetailErr || !this.aidDetailTitle) {
        return
      }
      if (!Array.isArray(this.aidDetail)) {
        this.aidDetail = []
      }
      this.aidDetail.push(this.aidDetailTitle)
      this.aidDetailVal()
      this.$nextTick(() => {
        this.aidDetailTitle = undefined
        this.aidDetailTitleKey = Math.random()
      })
    },
    deleteThing (i: number) {
      if (!Array.isArray(this.aidDetail)) return
      const aidDetail = [...this.aidDetail]
      aidDetail.splice(i, 1)
      this.aidDetail = aidDetail
      this.aidDetailVal()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

.list-input-item-title-full {
  flex: 1;
}

</style>
