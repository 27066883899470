export const toBase64 = (file: any) => new Promise<any>((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result?.toString())
  reader.onerror = reject
})

export function dataURItoBlob (dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export function getImageUrl (img: string) {
  return URL.createObjectURL(dataURItoBlob(img))
}

export async function downsizeImage (file: File, maxWidth = 512, maxHeight = 512) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e: any) => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Calculate new dimensions for the downsized image
        let newWidth = img.width
        let newHeight = img.height

        if (img.width > maxWidth) {
          newWidth = maxWidth
          newHeight = (img.height * maxWidth) / img.width
        }

        if (newHeight > maxHeight) {
          newHeight = maxHeight
          newWidth = (img.width * maxHeight) / img.height
        }

        canvas.width = newWidth
        canvas.height = newHeight

        // Draw the downsized image on the canvas
        ctx?.drawImage(img, 0, 0, newWidth, newHeight)

        // Convert the canvas to a data URL
        const downsizedImage = canvas.toDataURL('image/jpeg')

        // Set the downsized image as the preview
        resolve(downsizedImage)
      }
    }
    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}
