<template>
  <div class="new-household-form-container">
    <div class="popup-head">
      <h3 v-if="!editMode">ปัญหา/ความต้องการ</h3>
      <h3 v-else-if="currProblem">แก้ไขปัญหา/ความต้องการ</h3>
      <h3 v-else>เพิ่มปัญหา/ความต้องการ</h3>
      <div class="popup-head-close-btn-box">
        <v-btn v-if="currProblem && editMode" variant="text" icon="mdi-undo-variant" size="1.5rem" @click="editMode = false"></v-btn>
        <v-btn v-else variant="text" icon="mdi-close" size="1.5rem" @click="closeWindow(false);"></v-btn>
      </div>
    </div>
    <v-divider />
    <template v-if="editMode">
      <div class="tab-container">
        <v-tabs
          class="tab-md"
          v-model="tab"
          height="6rem"
          color="primary"
          centered
          stacked
        >
          <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="10rem">
            <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
            <p class="new-household-form-tab-title">
              {{ tabsMap[tab] }}
            </p>
          </v-tab>
        </v-tabs>
        <div class="tab-sm">
          <v-tabs
            v-model="tab"
            height="4rem"
            color="primary"
            centered
            stacked
          >
            <v-tab v-for="tab in tabs" :key="tab" :value="tab" width="5rem">
              <v-icon size="3rem">{{ tabsIconMap[tab] }}</v-icon>
            </v-tab>
          </v-tabs>
          <p class="new-household-form-tab-title">
            {{ tabsMap[tab] }}
          </p>
        </div>
      </div>
      <div :key="loading ? 1 : 0">
        <v-window v-model="tab" :touch="{left: () => {}, right: () => {}}">
          <v-window-item :value="tabs[0]">
            <ProblemTypeForm :_id="tempId" :tab="tabs[0]" :tab-name="tabsMap[tabs[0]]" :problem-type="currProblem?.problemType" />
          </v-window-item>
          <v-window-item :value="tabs[1]">
            <ProblemDetailForm :_id="tempId" :tab="tabs[1]" :tab-name="tabsMap[tabs[1]]" :problem-detail="currProblem?.problemDetail" />
          </v-window-item>
          <v-window-item :value="tabs[2]">
            <ChooseImageForm :_id="tempId" :tab="tabs[2]" :tab-name="tabsMap[tabs[2]]" :images="currProblem?.images" />
          </v-window-item>
          <v-window-item :value="tabs[3]">
            <ProblemRecordForm :_id="tempId" :tab="tabs[3]" :tab-name="tabsMap[tabs[3]]" :record="currProblem?.record" :members="household ? household.members : {}" />
          </v-window-item>
        </v-window>
      </div>
      <div class="new-household-form-buttons">
        <v-btn v-if="currProblem" variant="outlined" color="error" @click="deletePopup = true">
          ลบ
        </v-btn>
        <v-btn v-if="tab !== tabs.at(0)" variant="outlined" color="secondary" @click="prevTab">
          กลับ
        </v-btn>
        <v-btn v-if="tab === tabs.at(-1)" variant="outlined" color="success" @click="submitOffline" :loading="loading">
          บันทึกแบบร่าง offline
        </v-btn>
        <v-btn v-else variant="outlined" color="success" @click="nextTab">
          ถัดไป
        </v-btn>
      </div>
      <v-dialog
        v-model="valErrorsPopup"
        width="auto"
      >
        <v-card>
          <v-card-title>
            ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้
          </v-card-title>
          <div class="errors-report">
            <v-card-text v-for="err in valErrors" :key="err.title">
              <p v-if="err.error">"{{ err.tab }}"/"{{ err.title }}": "<span class="err-msg">{{ err.error }}</span>"</p>
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn color="primary" block @click="valErrorsPopup = false">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="apiErrorPopup"
        width="auto"
      >
        <v-card>
          <v-card-title>
            {{ apiError?.title }}
          </v-card-title>
          <v-card-text>
            <p>{{ apiError?.message }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="if (apiErrorCallback) {apiErrorCallback()} ; {apiErrorPopup = false}">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay class="add-form"  v-model="deletePopup" :no-click-animation="true">
        <YesNoPopup title="ลบสมาชิก" paragraph="ต้องการที่จะลบสมาชิกนี้หรือไม่ อาจมีบางกิจกรรมให้การช่วยเหลือที่เกี่ยวข้องถูกลบ" yes-color="error" :no-callback="() => {deletePopup = false}" :yes-callback="deleteOffline"/>
      </v-overlay>
    </template>
    <template v-else>
      <div class="new-household-form-wrapper">
        <div class="new-household-form">
          <div class="new-household-form-item-title">
            <p>วันที่</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.record.date.toLocaleString('th') ? currProblem?.record.date.toLocaleString('th') : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ปัญหา/ความต้องการ</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.problemDetail.title ? currProblem?.problemDetail.title : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>รายละเอียดปัญหา</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.problemDetail.detail ? currProblem?.problemDetail.detail : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ประเภทปัญหา/ความต้องการ</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.problemType.type ? currProblem?.problemType.type : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ตัวชี้วัด MPI (ที่สอดคล้องกับปัญหา)</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.problemType.description ? currProblem?.problemType.description : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ประเภทครัวเรือน</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.problemDetail.htype.main ? currProblem?.problemDetail.htype.main : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ผู้ประสานงานโครงการ</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.record.coordinator ? getMemberName(household?.members[currProblem.record.coordinator]) : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>เบอร์โทรศัพท์</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.record.phoneNumber ? currProblem?.record.phoneNumber : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>ผู้บันทึก</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.record.recordStaff.staff ? currProblem?.record.recordStaff.staff : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>หน่วยงาน</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            {{ !!currProblem?.record.recordStaff.organization ? currProblem?.record.recordStaff.organization : '-' }}
          </div>
          <div class="new-household-form-item-title">
            <p>รูปภาพ</p>&nbsp;&nbsp;
          </div>
          <div class="new-household-form-item">
            <ImageList v-if="!!currProblem?.images" :images="currProblem?.images" />
            <p v-else>-</p>
          </div>
        </div>
      </div>
      <div class="new-household-form-buttons">
        <v-btn variant="outlined" color="warning" @click="editMode = true">
          แก้ไข
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProblemTypeForm from '@/components/Form/ProblemTypeForm.vue'
import ProblemDetailForm from '@/components/Form/ProblemDetailForm.vue'
import ChooseImageForm from '@/components/Form/ChooseImageForm.vue'
import ProblemRecordForm from '@/components/Form/ProblemRecordForm.vue'
import ImageList from '@/components/Chart/ImageList.vue'
import YesNoPopup from '@/components/Popup/YesNoPopup.vue'
import { useStore } from 'vuex'
import { randHex, getHousehold, Household, Problem, ProblemType, ProblemDetail, ProblemRecord, ProblemDetailHtypeMain, ProblemDetailHtypeReason, RecordStaff, saveHousehold, ImageRecord, getDefaultDataState, getLocalObjectStateWithDataState, ActivityAidType, HouseholdMember } from '@/models/household'
import { Form } from '@/store'
import { getUserInfo } from '@/plugins/keycloak'

export default defineComponent({
  name: 'ProblemDetail',
  props: ['closeWindow', '_id', 'pid'],
  components: {
    YesNoPopup,
    ProblemTypeForm,
    ProblemDetailForm,
    ChooseImageForm,
    ProblemRecordForm,
    ImageList
  },
  setup () {
    const store = useStore()
    return {
      tempId: randHex(32),
      store
    }
  },
  data () {
    return {
      editMode: !this.pid,
      tab: 'personalInfo',
      valErrorsPopup: false,
      deletePopup: false,
      valErrors: [] as { tab: string, title: string, error: string }[],
      apiErrorPopup: false,
      apiErrorCallback: undefined as undefined | (() => void),
      apiError: undefined as { title: string, message: string } | undefined,
      tabs: ['problemType', 'problemDetail', 'images', 'record'],
      tabsMap: { problemType: 'ประเภทปัญหา', problemDetail: 'รายละเอียดปัญหา', images: 'อัพโหลดรูปภาพ', record: 'บันทึก' } as { [key: string]: string },
      tabsIconMap: { problemType: 'mdi-alert-rhombus', problemDetail: 'mdi-text', images: 'mdi-image', record: 'mdi-book-account' } as { [key: string]: string },
      loading: true,
      household: undefined as Household | undefined,
      currProblem: undefined as Problem | undefined
    }
  },
  beforeMount () {
    this.store.dispatch('resetForm', this.tempId)
  },
  mounted () {
    if (this._id) {
      this.fetchMember()
    } else {
      this.loading = false
    }
  },
  unmounted () {
    this.store.dispatch('deleteForm', this.tempId)
  },
  methods: {
    async fetchMember () {
      this.loading = true
      const household = await getHousehold(this._id)
      if (household) {
        this.household = household
        if (this.pid && this.household.problems) {
          if (this.household.problems[this.pid]) {
            this.currProblem = this.household.problems[this.pid]
            console.log('this.currProblem', this.currProblem)
          }
        }
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async submitOffline () {
      if (!this.validate()) return
      const user = await getUserInfo()
      if (!user) return

      const form: Form = this.store.getters.form(this.tempId)

      const problemType = form.value.problemType as ProblemType

      const tempProblemDetail = form.value.problemDetail as { title: string, detail: string, htypeMain: ProblemDetailHtypeMain, htypeDescReason?: string, aidType: ActivityAidType, aidDetail?: string | string[] } | undefined
      const images = form.value.images?.images as { [key: string]: ImageRecord } | undefined
      const tempRecord = form.value.record as { date: Date, staff: string, organization: string, coordinator: string, phoneNumber?: string }

      let problemDetail: ProblemDetail | undefined
      if (tempProblemDetail) {
        problemDetail = {
          title: tempProblemDetail.title,
          detail: tempProblemDetail.detail,
          htype: {
            main: tempProblemDetail.htypeMain
          },
          aidDetail: {
            type: tempProblemDetail.aidType,
            detail: tempProblemDetail.aidDetail
          }
        }
        if (tempProblemDetail.htypeMain === ProblemDetailHtypeMain.AID_REQUIRED) {
          problemDetail.htype.description = tempProblemDetail.htypeDescReason
        } else if (tempProblemDetail.htypeMain === ProblemDetailHtypeMain.AID_NOT_REQUIRED) {
          problemDetail.htype.reason = tempProblemDetail.htypeDescReason as ProblemDetailHtypeReason
        }
      }

      let record: ProblemRecord | undefined
      if (tempRecord) {
        record = {
          date: tempRecord.date,
          recordStaff: { staff: tempRecord.staff, organization: tempRecord.organization, username: user.preferred_username } as RecordStaff,
          coordinator: tempRecord.coordinator,
          phoneNumber: tempRecord.phoneNumber
        }
      }

      let problem: Problem
      if (this.currProblem) {
        problem = this.currProblem
        problem.objectState.dataState = getDefaultDataState()
      } else {
        problem = {
          problemType: problemType,
          problemDetail: problemDetail,
          record: record,
          objectState: getLocalObjectStateWithDataState()
        } as Problem
      }
      if (problemType) {
        problem.problemType = problemType
      }
      if (problemDetail) {
        problem.problemDetail = problemDetail
      }
      if (images) {
        problem.images = images
      }
      if (record) {
        problem.record = record
      }
      const household = await getHousehold(this._id)
      console.log('problem', problem)
      if (household) {
        if (!household.problems) {
          household.problems = {}
        }
        household.problems[this.pid ?? this.tempId] = problem
        if (await saveHousehold(household)) {
          this.closeWindow(true)
        }
      } else {
        console.log('cannot save', this._id)
        this.closeWindow(true)
      }
    },
    async deleteOffline () {
      this.deletePopup = false
      const household = await getHousehold(this._id)
      if (household && this.tempId && household.problems && household.problems[this.pid]) {
        if (household.activities) {
          for (const activityId of Object.keys(household.activities)) {
            if (household.activities &&
                household.activities[activityId]) {
              if (Object.keys(household.activities[activityId].problemsId).length <= 1) {
                delete household.activities[activityId]
              } else {
                delete household.activities[activityId].problemsId[this.pid]
              }
            }
          }
        }
        delete household.problems[this.pid]
        await saveHousehold(household)
      } else {
        console.log('cannot delete', this._id, household, this.pid)
      }
      this.closeWindow(true)
    },
    validate () {
      const { notCalled } = this.store.getters.formCallback(this.tempId, ['problemType', 'problemDetail', 'record'])
      const errors = this.store.getters.formErrors(this.tempId)
      if (!this.currProblem) {
        for (const t of notCalled) {
          errors.push({ tab: this.tabsMap[t], title: '*', error: 'จำเป็นต้องกรอก' })
        }
      }
      if (errors.length > 0) {
        this.valErrors = errors
        this.valErrorsPopup = true
        return false
      }
      return true
    },
    nextTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx + 1]
    },
    prevTab () {
      const idx = this.tabs.findIndex((e) => e === this.tab)
      this.tab = this.tabs[idx - 1]
    },
    getMemberName (member: HouseholdMember | undefined) {
      if (!member) return '-'
      return `${member.personalInfo.prefix}${member.personalInfo.name} ${member.personalInfo.surname}`
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/popupForms';
@use '@/styles/Form/normalForm';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-buttons {
  flex: 1;
}

.new-household-form-item-title {
  margin-bottom: unset !important;
}

.new-household-form-item {
  margin-bottom: unset !important;
}

.new-household-form {
  gap: 1rem;
}

</style>
