<template>
  <div class="new-household-form-container">
    <div class="popup-head">
      <h3>อัพโหลดรูปภาพ</h3>
      <div class="popup-head-close-btn-box">
        <v-btn variant="text" icon="mdi-close" size="1.5rem" @click="closeWindow(false)"></v-btn>
      </div>
    </div>
    <v-divider />
    <ChooseImageForm :close-window="closeWindow" tab="image" tab-name="รูปภาพ" :_id="tempId" :images="household?.images" />

    <div class="new-household-form-buttons">
      <v-btn variant="outlined" color="success" @click="submitOffline" :loading="loading">
        บันทึกแบบร่าง offline
      </v-btn>
      <v-dialog
        v-model="valErrorsPopup"
        width="auto"
      >
        <v-card>
          <v-card-title>
            ข้อมูลไม่ถูกต้อง โปรดแก้ไขข้อผิดพลาดต่อไปนี้
          </v-card-title>
          <div class="errors-report">
            <v-card-text v-for="err in valErrors" :key="err.title">
              <p v-if="err.error">"{{ err.tab }}"/"{{ err.title }}": "<span class="err-msg">{{ err.error }}</span>"</p>
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn color="primary" block @click="valErrorsPopup = false">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="apiErrorPopup"
        width="auto"
      >
        <v-card>
          <v-card-title>
            {{ apiError?.title }}
          </v-card-title>
          <v-card-text>
            <p>{{ apiError?.message }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="apiErrorPopup = false">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ChooseImageForm from '@/components/Form/ChooseImageForm.vue'
import { useStore } from 'vuex'
import { randHex, getHousehold, Household, saveHousehold } from '@/models/household'
import { Form } from '@/store'

export default defineComponent({
  name: 'ChooseImage',
  props: ['closeWindow', 'id'],
  components: {
    ChooseImageForm
  },
  setup () {
    const store = useStore()
    return {
      tempId: randHex(32),
      store
    }
  },
  data () {
    return {
      valErrorsPopup: false,
      valErrors: [] as { tab: string, title: string, error: string }[],
      apiErrorPopup: false,
      apiError: undefined as { title: string, message: string } | undefined,
      loading: false,
      household: undefined as Household | undefined
    }
  },
  beforeMount () {
    this.store.dispatch('resetForm', this.tempId)
  },
  unmounted () {
    this.store.dispatch('deleteForm', this.tempId)
  },
  mounted () {
    this.getHouseholdImages()
  },
  methods: {
    async getHouseholdImages () {
      const household = await getHousehold(this.id)
      if (!household) {
        this.$router.push('/notfound')
      } else {
        this.household = household
      }
    },
    async submitOffline () {
      if (!this.household) return
      if (!this.validate()) return
      const form: Form = this.store.getters.form(this.tempId)
      if (form.value.image?.images) {
        this.household.images = form.value.image.images
      }
      if (await saveHousehold(this.household)) {
        if (this.closeWindow) {
          this.closeWindow(true)
        }
      }
    },
    validate () {
      this.store.getters.formCallback(this.tempId)
      const errors = this.store.getters.formErrors(this.tempId)
      if (errors.length > 0) {
        this.valErrors = errors
        this.valErrorsPopup = true
        return false
      }
      return true
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/popupForms';

</style>
