import { App } from 'vue'
import Keycloak, { KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js'
import { sleep } from '@/utils/sleep'
import { fetchAndStoreProvinceInfo, getAddressInfoMap, getProvinceInfo } from '@/models/address'

const config = {
  realm: 'logbook-dev',
  clientId: 'logbook-web-dev',
  url: 'https://kankrao.tpmap.in.th/auth/'
}

export const _keycloak = new Keycloak(config)

export async function getUserInfo (): Promise<KeycloakTokenParsed | undefined> {
  if (navigator.onLine) {
    while (!_keycloak.tokenParsed || !getProvinceInfo() || !getAddressInfoMap()) {
      await sleep(100)
    }
    return _keycloak.tokenParsed
  }
  const userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    return JSON.parse(userInfo)
  }
}

export async function login () {
  if (!_keycloak.authenticated) {
    await initKeycloak()
  } else {
    return _keycloak.login()
  }
}

export async function logout () {
  localStorage.removeItem('userInfo')
  return _keycloak.logout()
}

let _initOptions: KeycloakInitOptions

export async function initKeycloak () {
  try {
    if (await _keycloak.init(_initOptions)) {
      console.log(_keycloak.authenticated)
      console.log(_keycloak.token)
      localStorage.setItem('userInfo', JSON.stringify(_keycloak.tokenParsed))
      if (_keycloak.tokenParsed) {
        await fetchAndStoreProvinceInfo(_keycloak.tokenParsed.accessible_area_code)
      }
    } else {
      console.log('Please Re-login')
      login()
    }
  } catch (error) {
    console.log(`keycloak init error ${error}`, JSON.stringify(error))
  }
}

async function loadProvince () {
  const user = await getUserInfo()
  if (user) {
    await fetchAndStoreProvinceInfo(user.accessible_area_code)
  }
}

export default {
  install: (app: App, initOptions: KeycloakInitOptions) => {
    console.log('installing keycloak, online:', navigator.onLine)
    _initOptions = initOptions
    app.config.globalProperties.$keycloak = _keycloak

    if (navigator.onLine) {
      initKeycloak()
    } else if (!localStorage.getItem('userInfo')) {
      console.log('Unauthorized')
      app.config.globalProperties.$router.push('/unauthorized')
    } else {
      loadProvince()
    }
  }
}
