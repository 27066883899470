import { Activity, ActivityAidType, ActivityTypeAidDetail, ActivityTypeTrainingDetail, Household, MainActivityType } from '../household'

export interface AddActivityBodyApiImages {
  'img_activity_url': string,
  'img_activitythumbnail_url': string,
  'img_activity_detail': string | undefined
}

export interface AddActivityBodyAPI {
  'house_id': string,
  'village_id': number,
  'activity_name': string
  'activity_detail': string,
  operator: string,
  department: string,
  'start_date': string,
  'end_date': string,
  'user_id': string,
  'activity_type_name': string,
  'resolve_indicators': string[],
  'resolve_indicators_text': string,
  'resolve_problemID': string,
  'resolve_problemName': string,
  'head_training': string | undefined,
  'training_name': string | undefined,
  'income_training': number | undefined,
  aid: string | undefined,
  'aid_detail': string | undefined,
  items: { 'item_name': string }[],
  'activity_achievement': string,
  'updated_date': string,
  'arr_img_activity': AddActivityBodyApiImages[]
}

export function getAddActivityBodyAPI (householdId: string, household: Household, activity: Activity, imgFolder: string): AddActivityBodyAPI {
  const recordStaff = activity.recordStaff
  const type = activity.type
  const objectState = activity.objectState
  const startDate = activity.duration.start
  const startDateStr = `${startDate.getFullYear().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')} ${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`
  const endDate = activity.duration.end
  const endDateStr = `${endDate.getFullYear().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getDate().toString().padStart(2, '0')} ${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`
  const updateDate = objectState.dataState.updateDate ?? new Date()
  const updateDateStr = `${updateDate.getFullYear().toString().padStart(2, '0')}/${(updateDate.getMonth() + 1).toString().padStart(2, '0')}/${updateDate.getDate().toString().padStart(2, '0')} ${updateDate.getHours().toString().padStart(2, '0')}:${updateDate.getMinutes().toString().padStart(2, '0')}`
  let trainingName: string | undefined
  if ([MainActivityType.TRAINING, MainActivityType.AID].includes(type.main)) {
    const member = type.main === MainActivityType.TRAINING
      ? household.members[(type.detail as ActivityTypeTrainingDetail).memberId]
      : household.members[(type.detail as ActivityTypeAidDetail).memberId]
    const personalInfo = member.personalInfo
    if (member) {
      trainingName = `${personalInfo.prefix}${personalInfo.name} ${personalInfo.surname}`
    }
  }
  return {
    house_id: householdId,
    village_id: parseInt(household.address.villageId.toString()),
    activity_name: activity.name,
    activity_detail: activity.detail,
    operator: recordStaff.staff,
    department: recordStaff.organization,
    start_date: startDateStr,
    end_date: endDateStr,
    user_id: recordStaff.username,
    activity_type_name: type.main,
    resolve_indicators: [],
    resolve_indicators_text: '',
    resolve_problemID: Object.keys(activity.problemsId).map((e) => household.problems ? household.problems[e].realId : undefined).join(','),
    resolve_problemName: Object.keys(activity.problemsId).map(
      (e) => household.problems ? household.problems[e].problemDetail.title : ''
    ).join(','),
    head_training: type.main === MainActivityType.TRAINING ? (type.detail as ActivityTypeTrainingDetail).topic : undefined,
    training_name: trainingName,
    income_training: type.main === MainActivityType.TRAINING ? (type.detail as ActivityTypeTrainingDetail).newIncome : undefined,
    aid: type.main === MainActivityType.AID ? (type.detail as ActivityTypeAidDetail).type : undefined,
    aid_detail: (
      type.main === MainActivityType.AID &&
      [
        ActivityAidType.SUBVENTION, ActivityAidType.UTILITIES,
        ActivityAidType.TRAINING, ActivityAidType.COORDINATE,
        ActivityAidType.OTHER
      ].includes((type.detail as ActivityTypeAidDetail).type)
    )
      ? (type.detail as ActivityTypeAidDetail).detail as string
      : undefined,
    items: type.main === MainActivityType.AID && (type.detail as ActivityTypeAidDetail).type === ActivityAidType.THINGS
      ? ((type.detail as ActivityTypeAidDetail).detail as string[]).map(e => ({ item_name: e }))
      : [],
    activity_achievement: activity.result,
    updated_date: updateDateStr,
    arr_img_activity: Object.entries(activity.images ?? {}).map(([key, img]) => ({
      img_activity_url: `/problems/${imgFolder}/${key}.${img.extension}`,
      img_activitythumbnail_url: `/problems/${imgFolder}/${key}.${img.extension}`,
      img_activity_detail: img.description ?? ''
    }))
  }
}
