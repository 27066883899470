import { ActivityAidType, Household, HouseholdMemberRole, MainProblemType, Problem, ProblemDetailHtypeMain } from '../household'
import mpiTemplate from '@/assets/template/mpi-template.json'

const problemDetailHtypeMainMap: { [key: string]: number } = {}
problemDetailHtypeMainMap[ProblemDetailHtypeMain.CAN_DEVELOP] = 1
problemDetailHtypeMainMap[ProblemDetailHtypeMain.AID_REQUIRED] = 2
problemDetailHtypeMainMap[ProblemDetailHtypeMain.AID_NOT_REQUIRED] = 3

export interface AddProblemBodyApiImages {
  'img_problem_url': string,
  'img_problemthumbnail_url': string,
  'img_description': string | undefined
}

export interface AddProblemBodyApi {
  'house_id': string,
  'village_id': number,
  'problem_name': string,
  'problem_detail': string,
  operator: string,
  department: string | undefined,
  'problem_date': string,
  'user_id': string,
  'problem_type_name': string,
  'problem_type_other': string | undefined,
  'house_type_code': number,
  'house_type_name': string,
  'house_type_other': string | undefined,
  'solution_name': string,
  'solution_detail': string | undefined,
  items: { 'item_name': string }[],
  'contact_nid': number,
  'contact_name': string,
  'contact_mobile': string | undefined,
  hashtags: string[],
  'house_ebmn': number,
  'house_source': string,
  indicator: number,
  'updated_date': string,
  'arr_img_problem': AddProblemBodyApiImages[]
}

export function getAddProblemBodyApi (householdId: string, household: Household, problem: Problem, imgFolder: string): AddProblemBodyApi {
  const problemDetail = problem.problemDetail
  const problemType = problem.problemType
  const record = problem.record
  const objectState = problem.objectState
  const problemDate = problem.record.date
  const problemDateStr = `${problemDate.getFullYear().toString().padStart(2, '0')}/${(problemDate.getMonth() + 1).toString().padStart(2, '0')}/${problemDate.getDate().toString().padStart(2, '0')} ${problemDate.getHours().toString().padStart(2, '0')}:${problemDate.getMinutes().toString().padStart(2, '0')}`
  const updateDate = objectState.dataState.updateDate ?? new Date()
  const updateDateStr = `${updateDate.getFullYear().toString().padStart(2, '0')}/${(updateDate.getMonth() + 1).toString().padStart(2, '0')}/${updateDate.getDate().toString().padStart(2, '0')} ${updateDate.getHours().toString().padStart(2, '0')}:${updateDate.getMinutes().toString().padStart(2, '0')}`
  const aidDetail = problemDetail.aidDetail
  const head = Object.values(household.members).find((m) => m.personalInfo.role === HouseholdMemberRole.HEAD)
  const coordinatorMember = household.members[record.coordinator] ? household.members[record.coordinator] : head
  const coordinatorMemberName = coordinatorMember ? `${coordinatorMember.personalInfo.prefix}${coordinatorMember.personalInfo.name} ${coordinatorMember.personalInfo.surname}` : ''

  const mpi = household.mpi
  const mpiValue: { name: string, items: boolean[] }[] = []
  let sumEbmn = 0
  if (mpi) {
    for (const _mpi of mpiTemplate) {
      mpiValue.push({ name: _mpi.name, items: mpi.index[_mpi.name] })
    }
    sumEbmn = Object.values(mpi.index).reduce((cum, group) => (
      cum + group.reduce((cum1, e) => (cum1 + (e ? 1 : 0)), 0)
    ), 0)
  }

  return {
    house_id: householdId,
    village_id: parseInt(household.address.villageId.toString()),
    problem_name: problemDetail.title,
    problem_detail: problemDetail.detail,
    operator: record.recordStaff.staff,
    department: record.recordStaff.organization,
    problem_date: problemDateStr,
    user_id: record.recordStaff.username,
    problem_type_name: problemType.type,
    problem_type_other: problemType.type === MainProblemType.OTHER && problemType.problemMpiId === 32 ? problemType.description : undefined,
    house_type_code: problemDetailHtypeMainMap[problemDetail.htype.main],
    house_type_name: problemDetail.htype.main,
    house_type_other: problemDetail.htype.main === ProblemDetailHtypeMain.AID_REQUIRED
      ? problemDetail.htype.description
      : (
          problemDetail.htype.main === ProblemDetailHtypeMain.AID_NOT_REQUIRED
            ? problemDetail.htype.reason
            : undefined
        ),
    solution_name: problemDetail.aidDetail.type,
    solution_detail: [
      ActivityAidType.SUBVENTION, ActivityAidType.TRAINING,
      ActivityAidType.COORDINATE, ActivityAidType.OTHER
    ].includes(problemDetail.aidDetail.type)
      ? (problemDetail.aidDetail.detail as string)
      : undefined,
    items: aidDetail.type === ActivityAidType.THINGS
      ? (
          aidDetail.detail
            ? (aidDetail.detail as string[]).map(e => ({ item_name: e }))
            : []
        )
      : [],
    contact_nid: coordinatorMember ? parseInt(coordinatorMember.personalInfo.id) : 0,
    contact_name: coordinatorMemberName,
    contact_mobile: record.phoneNumber,
    hashtags: [],
    house_ebmn: sumEbmn,
    house_source: 'bmn',
    indicator: problemType.problemMpiId,
    updated_date: updateDateStr,
    arr_img_problem: Object.entries(problem.images ?? {}).map(([key, img]) => ({
      img_problem_url: `/problems/${imgFolder}/${key}.${img.extension}`,
      img_problemthumbnail_url: `/problems/${imgFolder}/${key}.${img.extension}`,
      img_description: img.description ?? ''
    }))
  }
}
