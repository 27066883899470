<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.type }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="type"
          :error-messages="typeErr"
          @update:modelValue="typeChanged()"
          @update:focused="(val) => {if (!val) typeVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.type}`"
          :no-data-text="`ไม่พบ${titleMap.type}`"
          :disabled="currDisabled"
          :items="(Object.values(MainProblemType) as string[])"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.problemMpiId }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="problemMpiId"
          :error-messages="problemMpiIdErr"
          @update:modelValue="problemMpiIdChanged()"
          @update:focused="(val) => {if (!val) problemMpiIdVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.problemMpiId}`"
          :no-data-text="`โปรดเลือกประเภทปัญหา/ความต้องการก่อน`"
          :disabled="currDisabled"
          item-title="problemMpi"
          item-value="problemMpiId"
          :items="type ? problemMpiMap[type] : []"
        />
      </div>
      <div v-if="problemMpiId == 32" class="new-household-form-item-title">
        <p>{{ titleMap.description }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="problemMpiId == 32" class="new-household-form-item">
        <v-text-field
          v-model="description"
          :error-messages="descriptionErr"
          @update:modelValue="descriptionVal()"
          @update:focused="(val) => {if (!val) descriptionVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.description"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { MainProblemType, ProblemType } from '@/models/household'
import problemMpiTemplate from '@/assets/template/problem-mpi-template.json'

export default defineComponent({
  name: 'ProblemTypeForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'problemType', 'disabled'],
  components: {
  },
  setup () {
    const store = useStore()
    return {
      MainProblemType,
      store
    }
  },
  data () {
    const problemType: ProblemType | undefined = this.problemType
    const problemMpiMap: { [group: string]: { problemMpiId: number, problemMpi: string }[] } = {}
    const descriptionMap: { [group: string]: { [key: number]: string } } = {}
    for (const { group, items, id } of problemMpiTemplate) {
      const mapItems: { problemMpiId: number, problemMpi: string }[] = items.map((item, i) => ({
        problemMpiId: id[i],
        problemMpi: `ตัวชี้วัดที่ ${id[i]}: ${item}`
      }))
      problemMpiMap[group] = mapItems
      const mapDesc: { [key: number]: string } = {}
      items.forEach((item, i) => {
        mapDesc[id[i]] = `ตัวชี้วัดที่ ${id[i]}: ${item}`
      })
      descriptionMap[group] = mapDesc
    }
    return {
      problemMpiMap,
      descriptionMap,
      currDisabled: this.disabled as boolean | undefined,
      type: ref<string | undefined>(problemType?.type),
      typeErr: ref<string | undefined>(),
      problemMpiId: ref<number | undefined>(problemType?.problemMpiId),
      problemMpiIdErr: ref<string | undefined>(),
      description: ref<string | undefined>(problemType?.description),
      descriptionErr: ref<string | undefined>(),
      titleMap: {
        type: 'ประเภทปัญหา/ความต้องการ',
        problemMpiId: 'เลือกตัวชี้วัด MPI (ที่สอดคล้องกับปัญหา)',
        description: 'อื่นๆ'
      }
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (d) {
        this.currDisabled = d
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    if (this.problemType) {
      this.validate()
    }
  },
  methods: {
    typeVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'type',
        value: this.type
      })
      this.typeErr = validateEmpty(this.type, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'type',
        value: { tab: this.tabName, title: this.titleMap.type, error: this.typeErr }
      })
    },
    problemMpiIdVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'problemMpiId',
        value: this.problemMpiId
      })
      this.problemMpiIdErr = validateEmpty(this.problemMpiId, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'problemMpiId',
        value: { tab: this.tabName, title: this.titleMap.problemMpiId, error: this.problemMpiIdErr }
      })
    },
    descriptionVal (errCheck = true) {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'description',
        value: this.description
      })
      if (errCheck && this.problemMpiId === 0) {
        this.descriptionErr = validateEmpty(this.description, undefined)
      } else {
        this.descriptionErr = undefined
      }
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'description',
        value: { tab: this.tabName, title: this.titleMap.description, error: this.descriptionErr }
      })
    },
    validate () {
      this.typeVal()
      this.problemMpiIdVal()
      this.descriptionVal()
    },
    typeChanged () {
      this.problemMpiId = undefined
      if (this.type && this.problemMpiId && this.problemMpiId !== 32) {
        this.description = this.descriptionMap[this.type][this.problemMpiId]
      } else {
        this.description = undefined
      }
      this.typeVal()
      this.descriptionVal(false)
    },
    problemMpiIdChanged () {
      this.description = undefined
      if (this.type && this.problemMpiId && this.problemMpiId !== 32) {
        this.description = this.descriptionMap[this.type][this.problemMpiId]
      } else {
        this.description = undefined
      }
      this.problemMpiIdVal()
      this.descriptionVal(false)
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/Form/normalForm';

</style>
