<template>
  <NavBar />
  <router-view />
  <ErrorPopup />
</template>
<script>
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar'
import ErrorPopup from '@/components/ErrorPopup'

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    ErrorPopup
  }
})
</script>
<style lang="scss">
@use '@/styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai');

#app {
  font-family: "Noto Sans Thai" !important;
  color: variables.$text-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: variables.$app-bg-color;
}

</style>
