<template>
  <div :key="loading ? 1 : 0" class="card-body">
    <div class="member-panel panel-desktop">
      <div>มีสมาชิกทั้งหมด {{ household?.members ? Object.keys(household.members).length : 0 }} คน&nbsp;&nbsp;</div>
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-account-edit" @click="openEditMembersRole">เปลี่ยนความสัมพันธ์ในครัวเรือน</v-btn>
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-account-plus" @click="openNewMember">เพิ่มสมาชิก</v-btn>
    </div>
    <div class="member-panel panel-mobile">
      <div>มีสมาชิกทั้งหมด {{ household?.members ? Object.keys(household.members).length : 0 }} คน&nbsp;&nbsp;</div>
      <v-btn variant="text" color="primary" icon="mdi-account-edit" density="comfortable" @click="openEditMembersRole"></v-btn>
      <v-btn variant="text" color="primary" icon="mdi-account-plus" density="comfortable" @click="openNewMember"></v-btn>
    </div>
    <div v-if="household" class="member-list">
      <MemberCard
        v-for="[mid, member] in Object.entries(household?.members).sort(([mid, member]) => (member.personalInfo.role === HouseholdMemberRole.HEAD ? -1 : 0))"
        :key="mid"
        :_id="id"
        :mid="mid"
        :member="member"
        :update-callback="() => getHousehold(true)"
      />
      <div v-if="Object.keys(household?.members).length === 0" class="list-no-card">
        ไม่มีสมาชิกครัวเรือน
      </div>
    </div>
  </div>
  <v-overlay class="add-form"  v-model="newMember" :persistent="true" :no-click-animation="true">
    <MemberDetail :_id="id" :closeWindow="closeNewMember" />
  </v-overlay>
  <v-overlay class="add-form"  v-model="editMembersRole" :persistent="true" :no-click-animation="true">
    <ChangeMembersRole :_id="id" :closeWindow="closeEditMembersRole" />
  </v-overlay>
</template>

<script lang="ts">
import { Household, HouseholdMemberRole, getHousehold } from '@/models/household'
import { defineComponent } from 'vue'
import MemberCard from './MemberCard.vue'
import MemberDetail from './MemberDetail.vue'
import ChangeMembersRole from './ChangeMembersRole.vue'

export default defineComponent({
  name: 'MemberInfo',
  props: ['id', 'updateCallback'],
  components: {
    MemberCard,
    MemberDetail,
    ChangeMembersRole
  },
  data () {
    return {
      HouseholdMemberRole,
      newMember: false,
      editMembersRole: false,
      editHouseholdForm: false,
      household: undefined as Household | undefined,
      loading: true
    }
  },
  mounted () {
    this.getHousehold()
  },
  methods: {
    async getHousehold (isUpdate = false) {
      this.loading = true
      const household = await getHousehold(this.id)
      if (!household) {
        this.$router.push('/notfound')
      }
      if (isUpdate && this.updateCallback) {
        this.updateCallback()
      }
      this.household = household
      this.loading = false
    },
    closeEditHouseholdForm () {
      this.editHouseholdForm = false
    },
    openEditHouseholdForm () {
      this.editHouseholdForm = true
    },
    openNewMember () {
      this.newMember = true
    },
    closeNewMember (isUpdate = false) {
      this.newMember = false
      if (isUpdate) {
        this.getHousehold(true)
      }
    },
    openEditMembersRole () {
      this.editMembersRole = true
    },
    closeEditMembersRole (isUpdate = false) {
      this.editMembersRole = false
      if (isUpdate) {
        this.getHousehold(true)
      }
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-list {
  flex: 1;
  display: flex;
  overflow-x: auto;
  padding: 1.5rem;
  gap: 2rem;
}

.member-panel {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}

.panel-desktop {
  @media only screen and (max-width: variables.$screen-sm) {
    display: none;
  }
}

.panel-mobile {
  display: none;
  @media only screen and (max-width: variables.$screen-sm) {
    display: flex;
    gap: 0.25rem;
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  min-height: 300px;
}

.list-no-card {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
