<template>
  <div class="new-household-form-wrapper">
    <div class="new-household-form">
      <div class="new-household-form-item-title">
        <p>{{ titleMap.id }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          ref="idRef"
          :key="idKey"
          :model-value="id"
          :error-messages="idErr"
          @update:modelValue="idChanged"
          @update:focused="(val) => {if (!val) idVal()}"
          counter
          maxlength="13"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.id"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.prefix }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="prefix"
          :error-messages="prefixErr"
          @update:modelValue="prefixVal"
          @update:focused="(val) => {if (!val) prefixVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.prefix}`"
          :no-data-text="`ไม่พบ${titleMap.prefix}`"
          :disabled="currDisabled"
          :items="prefixList"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.name }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          v-model="name"
          :error-messages="nameErr"
          @update:modelValue="nameVal"
          @update:focused="(val) => {if (!val) nameVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.name"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.surname }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-text-field
          v-model="surname"
          :error-messages="surnameErr"
          @update:modelValue="surnameVal"
          @update:focused="(val) => {if (!val) surnameVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :disabled="currDisabled"
          :placeholder="titleMap.surname"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.gender }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-radio-group
          v-model="gender"
          :error-messages="genderErr"
          @update:modelValue="genderVal"
          color="primary"
          :disabled="currDisabled"
          inline
        >
          <v-radio
            label="ชาย"
            value="ชาย"
          ></v-radio>
          <v-radio
            label="หญิง"
            value="หญิง"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.birthDate }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <div class="date-picker-container">
          <VueDatePicker
            v-model="birthDate"
            @update:model-value="birthDateVal"
            :enable-time-picker="false"
            locale="th"
            :format-locale="th"
            format="dd/MM/y"
            select-text="เลือก"
            cancel-text="ยกเลิก"
            :disabled="currDisabled"
          ></VueDatePicker>
        </div>
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.job }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="job"
          :error-messages="jobErr"
          @update:modelValue="jobVal"
          @update:focused="(val) => {if (!val) jobVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.job}`"
          :no-data-text="`ไม่พบ${titleMap.job}`"
          :items="jobs"
          :disabled="currDisabled"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.education }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="education"
          :error-messages="educationErr"
          @update:modelValue="educationVal"
          @update:focused="(val) => {if (!val) educationVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.education}`"
          :no-data-text="`ไม่พบ${titleMap.education}`"
          :items="educations"
          :disabled="currDisabled"
        />
      </div>
      <div class="new-household-form-item-title">
        <p>{{ titleMap.religion }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div class="new-household-form-item">
        <v-select
          v-model="religion"
          :error-messages="religionErr"
          @update:modelValue="religionVal"
          @update:focused="(val) => {if (!val) religionVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.religion}`"
          :no-data-text="`ไม่พบ${titleMap.religion}`"
          :items="religions"
          :disabled="currDisabled"
        />
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.role }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-select
          v-model="role"
          :error-messages="roleErr"
          @update:modelValue="roleVal"
          @update:focused="(val) => {if (!val) roleVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.role}`"
          :no-data-text="`ไม่พบ${titleMap.role}`"
          :items="roleList"
          :disabled="currDisabled || role === HouseholdMemberRole.HEAD"
        />
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.phoneNumber }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-text-field
          ref="phoneNumberRef"
          :key="phoneNumberKey"
          :model-value="phoneNumber"
          :error-messages="phoneNumberErr"
          @update:modelValue="phoneNumberChanged"
          @update:focused="(val) => {if (!val) phoneNumberVal()}"
          counter
          maxlength="10"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.phoneNumber"
          :disabled="currDisabled"
        />
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.isDisabled }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-radio-group
          v-model="isDisabled"
          :error-messages="isDisabledErr"
          @update:modelValue="isDisabledChanged()"
          color="primary"
          inline
          :disabled="currDisabled"
        >
          <v-radio
            label="เป็น"
            :value="true"
          ></v-radio>
          <v-radio
            label="ไม่เป็น"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="full && isDisabled" class="new-household-form-item-title">
        <p>{{ titleMap.disabilities }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full && isDisabled" class="new-household-form-item auto-width">
        <v-select
          v-model="disabilities"
          :items="Object.values(Disability)"
          @update:model-value="disabilitiesVal"
          density="compact"
          variant="outlined"
          color="primary"
          chips
          multiple
          placeholder="เลือกความพิการ"
          :disabled="currDisabled"
        />
      </div>
      <div v-if="full && isDisabled" class="new-household-form-item-title">
        <p>{{ titleMap.disabilityCard }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full && isDisabled" class="new-household-form-item">
        <v-radio-group
          v-model="disabilityCard"
          :error-messages="disabilityCardErr"
          @update:modelValue="disabilityCardChanged()"
          color="primary"
          inline
          :disabled="currDisabled"
        >
          <v-radio
            label="มี"
            :value="true"
          ></v-radio>
          <v-radio
            label="ไม่มี"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="full && isDisabled && disabilityCard" class="new-household-form-item-title">
        <p>{{ titleMap.disabilityAllowance }}</p>&nbsp;
        <p class="required-title">*</p>
      </div>
      <div v-if="full && isDisabled && disabilityCard" class="new-household-form-item">
        <v-text-field
          ref="disabilityAllowanceRef"
          :key="disabilityAllowanceKey"
          :model-value="disabilityAllowance"
          :error-messages="disabilityAllowanceErr"
          @update:modelValue="disabilityAllowanceChanged"
          @update:focused="(val) => {if (!val) disabilityAllowanceVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          type="number"
          :placeholder="titleMap.disabilityAllowance"
          suffix="บาท"
        />
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.isChronicallyIll }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-radio-group
          v-model="isChronicallyIll"
          :error-messages="isChronicallyIllErr"
          @update:modelValue="isChronicallyIllVal"
          color="primary"
          inline
          :disabled="currDisabled"
        >
          <v-radio
            label="เป็น"
            :value="true"
          ></v-radio>
          <v-radio
            label="ไม่เป็น"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.canSelfReliant }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-radio-group
          v-model="canSelfReliant"
          :error-messages="canSelfReliantErr"
          @update:modelValue="canSelfReliantVal"
          color="primary"
          inline
          :disabled="currDisabled"
        >
          <v-radio
            label="ได้"
            :value="true"
          ></v-radio>
          <v-radio
            label="ไม่ได้"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.beingStatus }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-radio-group
          v-model="beingStatus"
          :error-messages="beingStatusErr"
          @update:modelValue="beingStatusVal"
          color="primary"
          inline
          :disabled="currDisabled"
        >
          <v-radio
            v-for="val in Object.values(BeingStatus)"
            :key="val"
            :label="val"
            :value="val"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.primaryMedicalPrivilege }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-select
          v-model="primaryMedicalPrivilege"
          :error-messages="primaryMedicalPrivilegeErr"
          @update:modelValue="primaryMedicalPrivilegeVal"
          @update:focused="(val) => {if (!val) primaryMedicalPrivilegeVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="`เลือก${titleMap.primaryMedicalPrivilege}`"
          :no-data-text="`ไม่พบ${titleMap.primaryMedicalPrivilege}`"
          :items="primaryMedicalPrivilegeList"
          :disabled="currDisabled"
        />
      </div>
      <div v-if="full" class="new-household-form-item-title">
        <p>{{ titleMap.primaryHospital }}</p>&nbsp;
        &nbsp;
      </div>
      <div v-if="full" class="new-household-form-item">
        <v-text-field
          v-model="primaryHospital"
          :error-messages="primaryHospitalErr"
          @update:modelValue="primaryHospitalVal"
          @update:focused="(val) => {if (!val) primaryHospitalVal()}"
          color="primary"
          density="compact"
          variant="outlined"
          :placeholder="titleMap.primaryHospital"
          :disabled="currDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateEmpty, validateIdCheckSum, validateLength } from '@/utils/validate-form'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { BeingStatus, Disability, HouseholdMemberRole, PersonalInfo } from '@/models/household'
import { parseStoreBirthDate } from '@/utils/date-utils'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { th } from 'date-fns/locale'

export default defineComponent({
  name: 'HouseholdMemberForm',
  props: ['closeWindow', 'tab', 'tabName', '_id', 'full', 'personalInfo', 'disabled'],
  components: {
    VueDatePicker
  },
  setup () {
    const store = useStore()
    return {
      th,
      Disability,
      HouseholdMemberRole,
      BeingStatus,
      store
    }
  },
  data () {
    const roleList = Object.values(HouseholdMemberRole).slice(1)
    const personalInfo: PersonalInfo | undefined = this.personalInfo
    return {
      disabilityAllowanceKey: Math.random(),
      currDisabled: this.disabled,
      phoneNumberKey: Math.random(),
      idKey: Math.random(),
      id: ref<string | undefined>(personalInfo?.id),
      idErr: ref<string | undefined>(),
      prefix: ref<string | undefined>(personalInfo?.prefix),
      prefixErr: ref<string | undefined>(),
      name: ref<string | undefined>(personalInfo?.name),
      nameErr: ref<string | undefined>(),
      surname: ref<string | undefined>(personalInfo?.surname),
      surnameErr: ref<string | undefined>(),
      gender: ref<string | undefined>(personalInfo?.gender),
      genderErr: ref<string | undefined>(),
      birthDate: ref<Date | undefined>(parseStoreBirthDate(personalInfo?.birthDate)),
      birthDateErr: ref<string | undefined>(),
      job: ref<string | undefined>(personalInfo?.job),
      jobErr: ref<string | undefined>(),
      education: ref<string | undefined>(personalInfo?.education),
      educationErr: ref<string | undefined>(),
      religion: ref<string | undefined>(personalInfo?.religion),
      religionErr: ref<string | undefined>(),
      role: ref<HouseholdMemberRole | undefined>(personalInfo ? personalInfo.role : (this.full ? undefined : HouseholdMemberRole.HEAD)),
      roleErr: ref<string | undefined>(),
      phoneNumber: ref<string | undefined>(personalInfo?.phoneNumber),
      phoneNumberErr: ref<string | undefined>(),
      isDisabled: ref<boolean>(!!personalInfo?.disability),
      isDisabledErr: ref<string | undefined>(),
      disabilities: ref<Disability[]>(personalInfo?.disability?.disabilities ?? []),
      disabilitiesErr: ref<string | undefined>(),
      disabilityCard: ref<boolean>(!!personalInfo?.disability?.disabilityCard),
      disabilityCardErr: ref<string | undefined>(),
      disabilityAllowance: ref<string | undefined>(personalInfo?.disability?.disabilityAllowance?.toString()),
      disabilityAllowanceErr: ref<string | undefined>(),
      isChronicallyIll: ref<boolean>(personalInfo?.isChronicallyIll !== undefined ? personalInfo.isChronicallyIll : false),
      isChronicallyIllErr: ref<string | undefined>(),
      canSelfReliant: ref<boolean>(personalInfo?.canSelfReliant !== undefined ? personalInfo.canSelfReliant : true),
      canSelfReliantErr: ref<string | undefined>(),
      beingStatus: ref<BeingStatus>(personalInfo?.beingStatus ?? BeingStatus.LIVING),
      beingStatusErr: ref<string | undefined>(),
      primaryMedicalPrivilege: ref<string | undefined>(personalInfo?.primaryMedicalPrivilege),
      primaryMedicalPrivilegeErr: ref<string | undefined>(),
      primaryHospital: ref<string | undefined>(personalInfo?.primaryHospital),
      primaryHospitalErr: ref<string | undefined>(),
      titleMap: {
        id: 'เลขบัตรประจำตัวประชาชน',
        prefix: 'คำนำหน้าชื่อ',
        name: 'ชื่อ',
        surname: 'นามสกุล',
        gender: 'เพศ',
        birthDate: 'วันเกิด',
        job: 'อาชีพ',
        education: 'ระดับการศึกษาสูงสุด',
        religion: 'ศาสนา',
        role: 'ความสัมพันธ์กับเจ้าบ้าน',
        phoneNumber: 'เบอร์โทรศัพท์',
        isDisabled: 'เป็นผู้พิการ',
        disabilities: 'ความพิการ 7 ประเภท',
        disabilityCard: 'สถานะการได้รับบัตรผู้พิการ',
        disabilityAllowance: 'จำนวนเงินที่ได้รับเบี้ยผู้พิการ',
        isChronicallyIll: 'เป็นผู้ป่วยเรื้อรัง',
        canSelfReliant: 'พึ่งพาตนเองได้',
        beingStatus: 'สถานะการมีชีวิตอยู่',
        primaryMedicalPrivilege: 'สิทธิการรักษาพยาบาลหลัก',
        primaryHospital: 'โรงพยาบาลที่ใช้บริการเป็นประจำ'
      },
      prefixList: ['เด็กชาย', 'เด็กหญิง', 'นาย', 'นาง', 'นางสาว', 'อื่นๆ'],
      jobs: [
        'รับจ้างทั่วไป',
        'เกษตร-ทำไร่',
        'ค้าขาย',
        'อาชีพอื่นๆ',
        'ธุรกิจส่วนตัว',
        'เกษตร-ทำสวน',
        'พนักงานรัฐวิสาหกิจ',
        'กำลังศึกษา',
        'ไม่มีอาชีพ',
        'เกษตร-ทำนา',
        'พนักงานรับราชการ',
        'พนักงานบริษัท',
        'เกษตร-ปศุสัตว์'
      ],
      educations: [
        'ไม่เคยศึกษา',
        'อนุบาล/ศูนย์เด็กเล็ก',
        'ต่ำกว่าชั้นประถมฯ (ป.4, ป.7, ป.6)',
        'ประถมฯ (ป.4, ป.7, ป.6)',
        'ม.ต้น (มศ.1-3 หรือ ม.1-3)',
        'ม.ปลาย (มศ.4-5 หรือ ม.4-6 หรือ ปวช.)',
        'ป.ตรี หรือเทียบเท่า',
        'อนุปริญญา หรือเทียบเท่า หรือ ปวส.',
        'สูงกว่าปริญญาตรี'
      ],
      religions: [
        'คริสต์',
        'อิสลาม',
        'พราหมณ์-ฮินดู',
        'พุทธ',
        'ซิกข์',
        'ไม่มีศาสนา/ไม่ระบุ',
        'อื่นๆ'
      ],
      primaryMedicalPrivilegeList: [
        undefined,
        'ประกันสุขภาพเอกชน',
        'สิทธิข้าราชการ',
        'สิทธิประกันสังคม',
        'สิทธิหลักประกันสุขภาพ',
        'สถานีอนามัย',
        'โรงพยาบาลของรัฐบาล',
        'โรงพยาบาลเอกชน',
        'มีสิทธิ UC',
        'ลงทะเบียนตามมติคณะกรรมการหลักประกันสุขภาพแห่งชาติ',
        'คลินิก',
        'มีสิทธิอื่น'
      ],
      roleList
    }
  },
  watch: {
    disabled: {
      immediate: true,
      handler (d) {
        this.currDisabled = d
      }
    }
  },
  mounted () {
    this.store.dispatch('setFormCallback', { id: this._id, tab: this.tab, callback: this.validate })
    if (this.personalInfo) {
      this.validate()
    } else {
      if (this.role) {
        this.roleVal()
      }
      this.isDisabledVal()
      this.disabilitiesVal()
      this.disabilityCardVal()
      this.isChronicallyIllVal()
      this.canSelfReliantVal()
      this.beingStatusVal()
    }
  },
  methods: {
    idVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'id',
        value: this.id
      })
      let IdErr = validateLength(this.id, undefined, 13, 13, 'กรุณากรอกให้ครบ 13 หลัก')
      IdErr = validateEmpty(this.id, IdErr)
      IdErr = validateIdCheckSum(this.id, IdErr)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'id',
        value: { tab: this.tabName, title: this.titleMap.id, error: IdErr }
      })
      this.idErr = IdErr
    },
    prefixVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'prefix',
        value: this.prefix
      })
      this.prefixErr = validateEmpty(this.prefix, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'prefix',
        value: { tab: this.tabName, title: this.titleMap.prefix, error: this.prefixErr }
      })
    },
    nameVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'name',
        value: this.name
      })
      this.nameErr = validateEmpty(this.name, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'name',
        value: { tab: this.tabName, title: this.titleMap.name, error: this.nameErr }
      })
    },
    surnameVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'surname',
        value: this.surname
      })
      this.surnameErr = validateEmpty(this.surname, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'surname',
        value: { tab: this.tabName, title: this.titleMap.surname, error: this.surnameErr }
      })
    },
    genderVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'gender',
        value: this.gender
      })
      this.genderErr = validateEmpty(this.gender, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'gender',
        value: { tab: this.tabName, title: this.titleMap.gender, error: this.genderErr }
      })
    },
    birthDateVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'birthDate',
        value: this.birthDate ? { year: this.birthDate.getFullYear(), month: this.birthDate.getMonth(), date: this.birthDate.getDate() } : undefined
      })
      this.birthDateErr = validateEmpty(this.birthDate, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'birthDate',
        value: { tab: this.tabName, title: this.titleMap.birthDate, error: this.birthDateErr }
      })
    },
    jobVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'job',
        value: this.job
      })
      this.jobErr = validateEmpty(this.job, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'job',
        value: { tab: this.tabName, title: this.titleMap.job, error: this.jobErr }
      })
    },
    educationVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'education',
        value: this.education
      })
      this.educationErr = validateEmpty(this.education, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'education',
        value: { tab: this.tabName, title: this.titleMap.education, error: this.educationErr }
      })
    },
    religionVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'religion',
        value: this.religion
      })
      this.religionErr = validateEmpty(this.religion, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'religion',
        value: { tab: this.tabName, title: this.titleMap.religion, error: this.religionErr }
      })
    },
    roleVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'role',
        value: this.role
      })
      this.roleErr = validateEmpty(this.role, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'role',
        value: { tab: this.tabName, title: this.titleMap.role, error: this.roleErr }
      })
    },
    phoneNumberVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        phoneNumber: this.phoneNumber,
        mode: 'value',
        tab: this.tab,
        key: 'phoneNumber',
        value: this.phoneNumber
      })
      const phoneNumberErr = validateLength(this.phoneNumber, undefined, 10, 10, 'กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก')
      this.store.dispatch('setFormValue', {
        id: this._id,
        phoneNumber: this.phoneNumber,
        mode: 'error',
        tab: this.tab,
        key: 'phoneNumber',
        value: { tab: this.tabName, title: this.titleMap.phoneNumber, error: phoneNumberErr }
      })
      this.phoneNumberErr = phoneNumberErr
    },
    isDisabledVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'isDisabled',
        value: this.isDisabled
      })
    },
    disabilitiesVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'disabilities',
        value: this.disabilities
      })
    },
    disabilityCardVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'disabilityCard',
        value: this.disabilityCard
      })
    },
    disabilityAllowanceVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'disabilityAllowance',
        value: this.disabilityAllowance
      })
      if (this.isDisabled && this.disabilityCard) {
        this.disabilityAllowanceErr = validateEmpty(this.disabilityAllowance, undefined)
      } else {
        this.disabilityAllowanceErr = undefined
      }
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'disabilityAllowance',
        value: { tab: this.tabName, title: this.titleMap.disabilityAllowance, error: this.disabilityAllowanceErr }
      })
    },
    isChronicallyIllVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'isChronicallyIll',
        value: this.isChronicallyIll
      })
      this.isChronicallyIllErr = validateEmpty(this.isChronicallyIll, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'isChronicallyIll',
        value: { tab: this.tabName, title: this.titleMap.isChronicallyIll, error: this.isChronicallyIllErr }
      })
    },
    canSelfReliantVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'canSelfReliant',
        value: this.canSelfReliant
      })
      this.canSelfReliantErr = validateEmpty(this.canSelfReliant, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'canSelfReliant',
        value: { tab: this.tabName, title: this.titleMap.canSelfReliant, error: this.canSelfReliantErr }
      })
    },
    beingStatusVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'beingStatus',
        value: this.beingStatus
      })
      this.beingStatusErr = validateEmpty(this.beingStatus, undefined)
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'error',
        tab: this.tab,
        key: 'beingStatus',
        value: { tab: this.tabName, title: this.titleMap.beingStatus, error: this.beingStatusErr }
      })
    },
    primaryMedicalPrivilegeVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'primaryMedicalPrivilege',
        value: this.primaryMedicalPrivilege
      })
    },
    primaryHospitalVal () {
      this.store.dispatch('setFormValue', {
        id: this._id,
        mode: 'value',
        tab: this.tab,
        key: 'primaryHospital',
        value: this.primaryHospital
      })
    },
    validate () {
      this.idVal()
      this.prefixVal()
      this.nameVal()
      this.surnameVal()
      this.genderVal()
      this.birthDateVal()
      this.jobVal()
      this.educationVal()
      this.religionVal()
      this.roleVal()
      this.isDisabledVal()
      this.disabilitiesVal()
      this.disabilityCardVal()
      this.disabilityAllowanceVal()
      this.isChronicallyIllVal()
      this.canSelfReliantVal()
      this.beingStatusVal()
      if (this.full) {
        this.phoneNumberVal()
        this.primaryMedicalPrivilegeVal()
        this.primaryHospitalVal()
      }
    },
    idChanged (value: string) {
      if (value && value !== '' && !/^\d+$/.test(value)) {
        this.idKey = Math.random()
        this.$nextTick(() => {
          const idRef = this.$refs.idRef as any
          idRef.focus()
        })
        return
      }
      this.id = value
      this.idVal()
    },
    phoneNumberChanged (value: string) {
      if (value && value !== '' && !/^\d+$/.test(value)) {
        this.phoneNumberKey = Math.random()
        this.$nextTick(() => {
          const phoneNumberRef = this.$refs.phoneNumberRef as any
          phoneNumberRef.focus()
        })
        return
      }
      this.phoneNumber = value
      this.phoneNumberVal()
    },
    isDisabledChanged () {
      this.isDisabledVal()
      if (!this.isDisabled) {
        this.disabilityAllowanceVal()
      }
    },
    disabilityCardChanged () {
      this.disabilityCardVal()
      if (!this.disabilityCard) {
        this.disabilityAllowanceVal()
      }
    },
    disabilityAllowanceChanged (value: string) {
      if (value && value !== '' && !/^((\d+(\.\d{0,2}){0,1})|(\.\d{0,2}))$/.test(value)) {
        this.disabilityAllowanceKey = Math.random()
        this.$nextTick(() => {
          const disabilityAllowanceRef = this.$refs.disabilityAllowanceRef as any
          disabilityAllowanceRef.focus()
        })
        return
      }
      this.disabilityAllowance = value
      this.disabilityAllowanceVal()
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/Form/normalForm';

.date-picker-container {
  &.dp__action_select {
    color: white !important;
  }
  & button, [type=button], [type=reset], [type=submit], [role=button] {
    cursor: pointer;
    color: white !important;
  }
}

</style>
