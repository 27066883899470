import { Ref } from 'vue'

export function clearError (errMsg: Ref<string | null>) {
  errMsg.value = null
}

export function validateEmpty (value: any, errMsg: string | undefined): string | undefined {
  if ((value !== false && value !== 0) && (!value || value === '' || (Array.isArray(value) && value.length === 0))) {
    return 'จำเป็นต้องกรอก'
  }
  return errMsg
}

export function validateLength (value: string | never[] | undefined, errMsg: string | undefined, minLength: number, maxLength: number, msg: string): string | undefined {
  if (
    (
      value &&
      value !== ''
    ) && (
      (minLength !== undefined && value.length < minLength) ||
      (maxLength !== undefined && value.length > maxLength)
    )) {
    return msg
  }
  return errMsg
}

export function validateIdCheckSum (value: string | never[] | undefined, errMsg: string | undefined): string | undefined {
  if (value !== undefined && value.length === 13) {
    const _value: string = value.toString()
    const checksum = (11 - (_value.slice(0, 12).split('').reduce((prev, e, i) => prev + parseInt(e) * (13 - i), 0) % 11)) % 10
    if (checksum !== parseInt(_value[12])) {
      return 'เลขบัตรประชาชนไม่ถูกต้อง'
    }
  }
  return errMsg
}
