<template>
  <div class="card" @click="openViewProblem">
    <div class="image-wrapper">
      <img v-if="image" class="image-show" alt="problem image" :src="getImageUrl(image.image)">
      <v-icon v-else size="3rem">mdi-image</v-icon>
    </div>
    <div class="card-info">
      <div class="card-info-value card-width card-title">{{ problemDetail?.title }}</div>
      <div class="card-info-value card-width">
        วันที่ :
        {{ record?.date.toLocaleDateString('th') }}
      </div>
      <div class="card-info-value card-width">
        ผู้บันทึก :
        {{ record?.recordStaff.staff }}
      </div>
    </div>
    <v-overlay class="add-form" v-model="viewProblem" :persistent="true" :no-click-animation="true">
      <ProblemDetail :_id="_id" :pid="pid" :problem="problem" :closeWindow="closeViewProblem" />
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Problem } from '@/models/household'
import { getImageUrl } from '@/utils/file-utils'
import { defineComponent } from 'vue'
import ProblemDetail from './ProblemDetail.vue'

export default defineComponent({
  name: 'ProblemCard',
  props: ['_id', 'pid', 'problem', 'updateCallback'],
  components: {
    ProblemDetail
  },
  data () {
    const problem: Problem | undefined = this.problem
    return {
      getImageUrl,
      viewProblem: false,
      problemType: problem?.problemType,
      problemDetail: problem?.problemDetail,
      image: problem?.images && problem?.images && Object.values(problem?.images).length > 0 ? Object.values(problem?.images)[0] : undefined,
      record: problem?.record
    }
  },
  methods: {
    openViewProblem () {
      this.viewProblem = true
    },
    closeViewProblem (isUpdate = false) {
      this.viewProblem = false
      if (isUpdate && this.updateCallback) {
        this.updateCallback()
      }
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.image-show {
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100%;
}

.image-wrapper {
  box-shadow: inset 0px 2px 10px #7777773b;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-justify: center;
  width: 100%;
  height: 8rem;
}

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

.card-width {
  width: 14rem;
  @media only screen and (max-width: variables.$screen-xs) {
    width: 10rem;
  }
}

.card-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 0.25rem;
}

.card-info-value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-size: variables.$font-xs;
}

.card-title {
  font-size: variables.$font-lg;
  font-weight: bold;
}

.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
