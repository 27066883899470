<template>
  <div v-if="isUserFetching" class="user-menu-loading-screen">
    <img alt="TPMAP Logbook Logo" src="../../assets/images/logo/logo-big77.png" height="64">
    <v-progress-circular
      indeterminate
      color="primary"
      size="48"
    />
  </div>
  <div v-else class="navbar-container">
    <div class="logo-container">
      <router-link to="/" class="logo-img-link">
        <img class="tpmap-logo" alt="TPMAP Logbook Logo" src="../../assets/images/logo/logo-big77.png">
      </router-link>
      <p class="tpmap-title">Thai People Map and Analytics Platform</p>
    </div>
    <div class="menu-container">
      <UserMenu />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import UserMenu from './UserMenu.vue'
import { getUserInfo } from '@/plugins/keycloak'

export default defineComponent({
  name: 'NavBar',
  components: {
    UserMenu
  },
  setup () {
    const isUserFetching = ref(true)

    async function setUserInfo () {
      await getUserInfo()
      isUserFetching.value = false
    }

    setUserInfo()

    return {
      isUserFetching
    }
  }
})
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.user-menu-loading-screen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: variables.$bg-color;
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.navbar-container {
  position: sticky;
  z-index: 5;
  top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: variables.$bg-color;
  box-shadow: 0px 2px 20px #7777773b;
  @media only screen and (max-width: variables.$screen-sm) {
    .tpmap-title {
      display: none;
    }
  }
}

.logo-container {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1.8rem;
}

.logo-img-link {
  padding-top: 0.25rem;
}

.menu-container {
  // flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tpmap-logo {
  height: 1.5rem;
}

.tpmap-title {
  font-size: variables.$font-sm;
  color: variables.$main-color;
}

</style>
